import { environment } from "../../environments/environment";

import { Invite } from "../models/invite";

import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";

const apiUrl = `${environment.backend}/v1/invites`;

@Injectable({
    providedIn: "root",
})
export class InviteService {
    constructor(private http: HttpClient, private authService: AuthService) {}

    async getInviteById(id: string): Promise<Invite> {
        return this.http
            .get<Invite>(`${apiUrl}/${id}`)
            .toPromise()
            .catch((err) => {
                console.error(`Couldn't get appointment with id: ${id}`, err);
                return null;
            });
    }

    async acceptInviteById(id: string) {
        return this.http
            .post(`${apiUrl}/${id}/accept`, null, await this.httpOptions)
            .toPromise()
            .catch((err) => {
                console.error(
                    `Couldn't accept appointment with id: ${id}`,
                    err
                );
                return null;
            });
    }

    get httpOptions() {
        return new Promise(async (resolve) => {
            const userToken = await this.authService.getUserToken();
            resolve({
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${userToken}`,
                }),
            });
        });
    }
}
