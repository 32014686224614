import { Component, AfterViewInit } from "@angular/core";
import { DicateService, Transcription } from "../../services/dictate.service";
import { JoyrideService } from "ngx-joyride";
import { AuthService } from "src/app/services/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { DialogComponent } from "../dialog/dialog.component";
import { PreferencesService } from "src/app/services/preferences.service";
import { MailoutService } from "src/app/services/mailout.service";

declare var WebAudioRecorder: any;

@Component({
    selector: "app-dictate",
    templateUrl: "./dictate.component.html",
    styleUrls: ["./dictate.component.scss"],
})
export class DictateComponent implements AfterViewInit {
    rec: any;
    mediaStream: MediaStream;

    oldText: string = "";
    newText: string = "";

    fieldText: string = "";

    hasUpdatedCorpus: boolean = false;
    hasBeenEdited: boolean = false;
    hasSentMailout: boolean = false;

    sendingMailout: boolean = false;

    preferences = null;

    get allText(): string {
        let text: string = "";
        let workingOldText: string = this.oldText;
        let workingNewText: string = this.newText;

        workingOldText = workingOldText.replace(/[\t ]+$/gi, "");
        workingNewText = workingNewText.replace(/^[\t ]+/, "");

        if (workingNewText && workingOldText) {
            let padding: string = workingOldText.match(/\s$/) ? "" : " ";
            text = workingOldText + padding + workingNewText;
        } else if (workingOldText) {
            text = workingOldText;
        } else if (workingNewText) {
            text = workingNewText;
        } else {
            return "";
        }
        if (
            workingNewText &&
            !workingNewText.match(/[\s\.\,\?\!(\n|<br>)]$/gi)
        ) {
            text = text + ".";
        }
        return text;
    }

    set allText(value) {
        this.oldText = value;
        this.newText = "";
    }

    pumpFieldText() {
        this.fieldText = this.allText;
    }

    saveFieldText() {
        console.log(this.fieldText);
        this.allText = this.fieldText;
    }

    constructor(
        private dictateService: DicateService,
        private readonly joyrideService: JoyrideService,
        private dialog: MatDialog,
        private preferencesService: PreferencesService,
        private mailoutService: MailoutService
    ) {
        this.preferencesService.preferences.subscribe(
            (preferences) => (this.preferences = preferences)
        );
    }

    ngAfterViewInit() {
        if (!localStorage.getItem("hasToured")) {
            this.joyrideService.startTour({
                steps: [
                    "introStep",
                    "firstStep",
                    "secondStep",
                    "thirdStep",
                    "fourthStep",
                    "outroStep",
                ],
                showPrevButton: false,
                showCounter: true,
            });
            localStorage.setItem("hasToured", "true");
        }
    }

    get isRecording() {
        return this.dictateService.transcribing;
    }

    clearText(): void {
        if (this.isRecording) this.stopRecording();
        this.oldText = "";
        this.newText = "";
        this.fieldText = "";
        this.resetFlags();
    }

    resetFlags(): void {
        this.hasUpdatedCorpus = false;
        this.hasBeenEdited = false;
    }

    async startRecording() {
        this.saveFieldText();

        if (this.allText === "") {
            this.resetFlags();
        }

        await this.dictateService.initTranscriptionContext();

        this.dictateService
            .startTranscription()
            .asObservable()
            .subscribe((transcription: Transcription) => {
                this.processText(transcription);
            });
    }

    processText(transcription: Transcription) {
        console.log(transcription);
        this.newText = this.applyHardFixes(transcription.text);
        if (transcription.isFinal) {
            this.markFinal();
        }
        this.pumpFieldText();
        console.log(this.newText);
        console.log(this.allText);
    }

    markFinal() {
        this.oldText = this.allText;
        this.newText = "";
        console.log(`Final: ${this.allText}`);
    }

    stopRecording() {
        this.dictateService.stopTranscription();
        this.markFinal();
    }

    applyHardFixes(text: string): string {
        let workingText: string = text;
        this.hardFixes.forEach(
            (fix) => (workingText = workingText.replace(fix.from, fix.to))
        );
        workingText = this.toSentenceCase(workingText);
        return workingText;
    }

    hardFixes = [
        { from: /\s?full stop[\s\.?!]*/gi, to: ". " },
        { from: /\s?period[\s\.?!]*/gi, to: ". " },
        { from: /\s?comma[\s\.?!]*/gi, to: ", " },
        { from: /\s?exclamation mark[\s\.?!]*/gi, to: "! " },
        { from: /\s?exclamation point[\s\.?!]*/gi, to: "! " },
        { from: /\s?question mark[\s\.?]*/gi, to: "? " },
        { from: /\s?open brackets?\s?/gi, to: " (" },
        { from: /\s?closed? brackets?\s?/gi, to: ") " },
        { from: /\s?new\s?line[\s,]*([\s\.?!]*)?/gi, to: "$1\n" },
        { from: /\s?new paragraph[\s,]*([\.?!]*)?/gi, to: "$1\n\n" },
        { from: /\s?dash[\s\.?!,]*/gi, to: " - "},
        { from: /\s?hyphen[\s\.?!,]*/gi, to: " - "},
        { from: /(\w)(\n)/gi, to: "$1.$2" },
        { from: /\n[\t ]+/gi, to: "\n" },
        { from: /[\t ]+/gi, to: " " },
        // { from: /^bullet point[\s,]*([\s\.\?\!]*)?/gi, to: "<li>"},
        // { from: /\s?bullet point[\s,]*([\s\.\?\!]*)?/gi, to: "$1<br><li>"},
        // { from: /\s?PowerPoint[\s,]*([\s\.\?\!]*)?/gi, to: "$1<br><li>"},
    ];

    toSentenceCase(text: string) {
        let workingText: string = text;
        workingText = workingText.replace(
            /((?:[\.\!\?]|<br>|\n)+\s?)+([a-z])/gi,
            (m, $1, $2) => {
                console.log(m);
                console.log($1);
                console.log($2);
                return $1 + $2.toUpperCase();
            }
        );
        workingText = workingText.replace(/^([a-z])/gi, (m, $1) =>
            $1.toUpperCase()
        );
        return workingText;
    }

    onCopy() {
        if (
            !this.hasBeenEdited &&
            this.fieldText &&
            this.fieldText.length > 0
        ) {
            if (
                !this.hasBeenEdited &&
                !localStorage.getItem("warnedAboutEditing")
            ) {
                this.promptEditBeforeCopy();
                localStorage.setItem("warnedAboutEditing", "true");
                return false;
            }
            this.dictateService.updateCorpus(this.fieldText);
            this.hasUpdatedCorpus = true;
            console.debug("Corpus updated");
        }
    }

    openHelp() {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: {
                heading: "Dictation Tips",
                message:
                    "<p>Dictate as close to your microphone as is comfortable - high volumes will allow Hepian to learn faster and transcribe a lot more accurately.</p><p>Use normal medical words - dictate in a way that comes naturally to your medical practice.</p><p>Particularly if you’re using Hepian on a laptop - try and use a pair of headphones whilst you dictate.</p><p>Grammar and formatting is handled automatically by Hepian but you can also add the punctuation you need by speaking it e.g. ‘full stop’.</p><p>Use the command words ‘new paragraph’ and ‘new line’ rather than ‘new sentence’ when possible.</p>",
            },
        });
    }

    onKeyUp(event: KeyboardEvent): void {
        let charCode = String.fromCharCode(event.which).toLowerCase();

        if ((event.ctrlKey || event.metaKey) && charCode === "c") {
            this.onCopy();
        }
    }

    promptEditBeforeCopy(): void {
        const dialogRef = this.dialog.open(DialogComponent, {
            data: {
                heading: "Help us Learn!",
                message:
                    "<p>Please edit and correct the text <i>before</i> you copy it, so our AI can learn from it's mistakes.</p>",
            },
        });
    }

    editing(event: Event): void {
        this.hasBeenEdited = true;
    }

    async mailout(): Promise<void> {
        if (
            this.fieldText &&
            this.fieldText.length > 0
        ) {
            this.sendingMailout = true;
            await this.mailoutService
                .sendMailout(this.fieldText)
                .then(() => {
                    this.sendingMailout = false;
                    this.hasSentMailout = true;
                    const dialogRef = this.dialog.open(DialogComponent, {
                        data: {
                            heading: "Dictation Sent",
                            message:
                                "<p>We've sent your dictation to your secretary!</p>",
                        },
                    });
                })
                .catch(() => {
                    this.sendingMailout = false;
                    const dialogRef = this.dialog.open(DialogComponent, {
                        data: {
                            heading: "Oh No!",
                            message:
                                "<p>We ran into a problem sending your dictation.</p>",
                        },
                    });
                });
            if (!this.hasUpdatedCorpus) {
                this.dictateService.updateCorpus(this.fieldText);
                this.hasUpdatedCorpus = true;
                console.debug("Corpus updated");
            }
        }
    }
}
