import { Injectable } from "@angular/core";
import { PreferencesService } from "./preferences.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";

const apiUrl = `${environment.backend}/v1/mailouts`;

@Injectable({
    providedIn: "root",
})
export class MailoutService {
    preferences = null;

    constructor(
        private http: HttpClient,
        private authService: AuthService,
        private preferencesService: PreferencesService
    ) {
        this.preferencesService.preferences.subscribe(
            (preferences) => (this.preferences = preferences)
        );
    }

    async sendMailout(body: string) {
        let mailoutInfo = {
            to: this.preferences.dictation.mailout_email,
            from: this.authService.getUserName(),
            body: body,
        };
        console.log(mailoutInfo);
        return this.http
            .post(apiUrl, mailoutInfo, await this.httpOptions)
            .toPromise();
    }

    get httpOptions() {
        return new Promise(async (resolve) => {
            const userToken = await this.authService.getUserToken();
            resolve({
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${userToken}`,
                }),
            });
        });
    }
}
