export const specialtyVocab = {
    allergy: [
        "adrenaline",
        "epinephrine",
        "allergen",
        "allergen immunotherapy",
        "allergic conjunctivitis",
        "allergic crease",
        "allergic reaction",
        "allergic rhinitis",
        "allergic shiners",
        "allergy patch test",
        "anaphylaxis",
        "angioedema",
        "antibodies",
        "antihistamines",
        "atopic dermatitis",
        "beta blockers",
        "bronchodilator",
        "bronchitis",
        "bronchospasm",
        "cilia",
        "colic",
        "contact dermatitis",
        "dander",
        "desensitisation",
        "dust mite",
        "eczema",
        "eosinophils",
        "epicutaneous test",
        "food sensitivity",
        "glands",
        "hay fever",
        "histamine",
        "hives",
        "immune system",
        "immunotherapy",
        "immunoglobulin",
        "inflammation",
        "intradermal skin test",
        "latex",
        "mast cells",
        "middle ear",
        "mould or fungus",
        "mucus",
        "non-steroidal anti-inflammatory drugs",
        "NSAIDs",
        "occupational allergens",
        "oesophageal reflux",
        "oral allergy syndrome",
        "otitis media",
        "serum total ige test",
        "sinusitis",
        "skin prick test",
        "specific IgE test",
        "steroids",
        "symptom diary",
        "urticaria",
        "wheal",
        "chlorphenamine maleate",
        "clemastine",
        "cyproheptadine hydrochloride",
        "promethazine hydrochloride",
        "acrivastine",
        "cetirizine hydrochloride",
        "loratadine",
    ],
    audiovestibular: [
        "audiogram",
        "audiologist",
        "audiovestibular specialist",
        "auditory brainstem implant",
        "ABI",
        "aetiological",
        "benign",
        "bilateral",
        "bone-anchored hearing aid",
        "BAHA",
        "brainstem",
        "british acoustic neuroma association",
        "centimeter",
        "cerebellopontine angle",
        "cerebellum",
        "cerebrospinal fluid",
        "CSF",
        "cochlear implants",
        "cochlear nerve",
        "computerized tomography",
        "CT scan",
        "cranial nerves",
        "CROS Hearing Aid",
        "cyberknife",
        "CK",
        "double vision",
        "ear, nose and throat physician",
        "electronystagmogram",
        "ENT",
        "ENG",
        "fractionated stereotactic radiation",
        "gadolinium",
        "gamma knife",
        "hydrocephalus",
        "internal auditory canal",
        "intracranial pressure",
        "LINAC",
        "magnetic resonance imaging",
        "MRA",
        "middle fossa",
        "millimeter",
        "neurofibromatosis type ii",
        "neurological",
        "neurosurgeon",
        "neurotologist",
        "optokinetic",
        "otolaryngologist",
        "otologist",
        "parathyroid adenoma",
        "peacock radiation",
        "pons",
        "porus",
        "posterior fossa",
        "proton radiation",
        "radiotherapy",
        "RT",
        "retrosigmoid",
        "suboccipital",
        "sensorineural hearing loss",
        "SHL",
        "shunt",
        "stereotactic radiosurgery",
        "sub-totally",
        "sudden sensorineural hearing loss",
        "SSHL",
        "suppressor gene",
        "translabyrinthine",
        "trigeminal nerve",
        "unilateral",
        "unilateral sporadic tumours",
        "vertigo",
        "vestibular",
        "vestibular schwannoma",
        "ABR testing",
        "video frenzels goggles",
        "posturography",
        "tympanometry",
        "acoustic reflex measurement",
        "rotational tests",
        "bithermal caloric",
        "anticholinergics",
        "benzodiazepines",
    ],
    cardiology: [
        "abdomen",
        "abdominal aorta",
        "ablation",
        "ACE inhibitor",
        "acetylcholine",
        "acquired heart disease",
        "alveoli",
        "amiodarone",
        "aneurysm",
        "angina pectoris",
        "angina",
        "angiography",
        "angioplasty",
        "Angiotensin II receptor blocker",
        "annulus",
        "antiarrhythmics",
        "anticoagulant",
        "antihypertensive",
        "antiplatelet therapy",
        "aorta",
        "aortic valve",
        "aphasia",
        "arrhythmia",
        "arrhythmogenic right ventricular dysplasia",
        "ARVD",
        "arteriography",
        "arterioles",
        "arterities",
        "arteriosclerosis",
        "artificial heart",
        "ascending aorta",
        "aspirin",
        "atherectomy",
        "atherosclerosis",
        "atrial flutter",
        "atrial septal defect",
        "atrial tachycardia",
        "atrioventricular block",
        "atrioventricular node",
        "autologous",
        "autoregulation",
        "bacterial endocarditis",
        "balloon catheter",
        "balloon valvuloplasty",
        "beta-blocker",
        "biopsy",
        "blalock-taussig procedure",
        "blood clot",
        "blue babies",
        "body mass index",
        "BMI",
        "bradycardia",
        "bridge to transplant",
        "bruit",
        "bundle branch block",
        "calcium channel blocker",
        "capillaries",
        "cardiac amyloidosis",
        "cardiac cachexia",
        "cardiac catheterization",
        "cardiac enzymes",
        "cardiac output",
        "cardiologist",
        "cardiomegaly",
        "cardiomyopathy",
        "cardiopulmonary bypass",
        "cardiopulmonary resuscitation",
        "cardiovascular",
        "cardiovascular disease",
        "cardioversion",
        "carotid artery",
        "cerebral embolism",
        "cerebral hemorrhage",
        "cerebral thrombosis",
        "cerebrovascular",
        "cerebrovascular accident",
        "cerebrovascular occlusion",
        "cholesterol",
        "cineangiography",
        "circulatory system",
        "claudication",
        "collateral circulation",
        "commissurotomy",
        "computed tomography",
        "CAT Scan",
        "conduction system",
        "congenital",
        "congenital heart defects",
        "congestive heart failure",
        "coronary arteries",
        "coronary artery anomaly",
        "coronary artery bypass",
        "coronary artery disease",
        "CAA",
        "CAB",
        "CAD",
        "coronary heart disease",
        "coronary occlusion",
        "coronary thrombosis",
        "cryoablation",
        "cyanosis",
        "cyanotic heart disease",
        "deep vein thrombosis",
        "defibrillator",
        "diabetes",
        "diastolic blood pressure",
        "digitalis",
        "dissecting aneurysm",
        "diuretic",
        "doppler ultrasound",
        "dysarthria",
        "dyspnea",
        "echocardiography",
        "edema",
        "ejection fraction",
        "electrocardiogram",
        "ECG",
        "EKG",
        "electroencephalogram",
        "electrophysiological study",
        "EEG",
        "EPS",
        "embolus",
        "endarterectomy",
        "endocardium",
        "endothelium",
        "endocarditis",
        "epicardium",
        "event monitor",
        "exercise stress test",
        "familial hypercholesterolemia",
        "fatty acids",
        "fibrillation",
        "first-degree heart block",
        "fusiform aneurysm",
        "gated blood pool scan",
        "genetic testing",
        "guidewire",
        "heart assist device",
        "heart-lung machine",
        "heart murmur",
        "hematocrit",
        "hemochromatosis",
        "heredity",
        "high density lipoprotein",
        "HDL",
        "holter monitor",
        "homocysteine",
        "hypertension",
        "hypertrophic obstructive cardiomyopathy",
        "HOCM",
        "hypertrophy",
        "hyperventilation",
        "hypoglycemia",
        "hypokinesia",
        "hypotension",
        "hypoxia",
        "idiopathic",
        "immunosuppressants",
        "impedance plethysmography",
        "incompetent valve",
        "infarction",
        "infective endocarditis",
        "inferior vena cava",
        "inotropes",
        "internal mammary artery",
        "intravascular echocardiography",
        "introducer sheath",
        "ischemia",
        "ischemic heart disease",
        "ischemic stroke",
        "jugular veins",
        "left ventricular assist device",
        "LVAD",
        "lesion",
        "lipid",
        "lipoprotein",
        "low density lipoprotein",
        "LDL",
        "lumen",
        "magnetic resonance imaging",
        "maze surgery",
        "mitral stenosis",
        "mitral valve",
        "mitral valve prolapse",
        "mitral valve regurgitation",
        "monounsaturated fats",
        "mortality",
        "murmur",
        "myocardial infarction",
        "myocardial ischemia",
        "myocarditis",
        "myocardium",
        "myxomatous degeneration",
        "nitroglycerin",
        "noninvasive procedures",
        "NSTEMI",
        "occluded artery",
        "open heart surgery",
        "palpitation",
        "pancreatitis",
        "paroxysmal supraventricular tachycardia",
        "PSVT",
        "patent ductus arteriosus",
        "patent foramen ovale",
        "percutaneous coronary intervention",
        "percutaneous transluminal coronary angioplasty",
        "PCI",
        "PTCA",
        "pericarditis",
        "pericardiocentesis",
        "pericardium",
        "plaque",
        "platelets",
        "polyunsaturated fat",
        "positron emission tomography",
        "postural orthostatic tachycardia syndrome",
        "premature ventricular contraction",
        "PET",
        "POTS",
        "PVC",
        "prevalence",
        "pulmonary embolism",
        "pulmonary vein",
        "radial artery access",
        "radionuclide imaging",
        "radionuclide studies",
        "radionuclide ventriculography",
        "regurgitation",
        "restenosis",
        "revascularization",
        "rheumatic fever",
        "rheumatic heart disease",
        "right ventricular assist device",
        "RVAD",
        "Rubella",
        "saccular aneurysm",
        "sarcoidosis",
        "saturated fat",
        "second-degree heart block",
        "septal defect",
        "septum",
        "sheath",
        "shunt",
        "sick sinus syndrome",
        "silent ischemia",
        "sinus node",
        "SA",
        "sphygmomanometer",
        "stem cells",
        "STEMI",
        "stenosis",
        "stethoscope",
        "Stokes-Adams disease",
        "streptococcal infection",
        "STREP",
        "streptokinase",
        "sternum",
        "subarachnoid hemorrhage",
        "subclavian arteries",
        "superior vena cava",
        "supraventricular tachycardia",
        "SVT",
        "syncope",
        "systolic blood pressure",
        "tachycardia",
        "tachypnea",
        "tamponade",
        "Thallium-201 stress test",
        "third-degree heart block",
        "thrombolysis",
        "thrombosis",
        "thrombolytic therapy",
        "thrombus",
        "thyroid",
        "tissue plasminogen activator",
        "tPA",
        "trans fat",
        "transcatheter aortic valve implantation",
        "TAVI",
        "transcatheter intervention",
        "transesophageal echocardiography",
        "transient ischemic attack",
        "TIA",
        "transplantation",
        "tricuspid valve",
        "triglyceride",
        "ultrasound",
        "valve replacement",
        "valvuloplasty",
        "varicose vein",
        "vascular",
        "vasodilators",
        "vasopressors",
        "ventricle",
        "ventricular assist device",
        "VAD",
        "ventricular fibrillation",
        "ventricular tachycardia",
        "Wolff-Parkinson-White syndrome",
        "X-ray",
        "benazepril",
        "captopril",
        "ace inhibitors",
        "ARBs",
        "enalapril",
        "fosinopril",
        "lisinopril",
        "moexipril",
        "perindopril",
        "quinapril",
        "digoxin",
        "aldosterone antagonist",
        "angiotensin receptor blockers",
        "disopyramide",
        "flecainide",
        "sotalol",
        "tranexamic acid",
        "clopidogrel",
        "apixaban",
        "edoxaban",
        "enoxaparin",
        "dabigatran",
        "warfarin",
        "carvedilol",
        "propranolol",
        "atenolol",
        "amlodipine",
        "diltiazem",
        "nifedipine",
        "indapamide",
        "chest pain",
        "dyspnoea",
        "breathlessness",
        "oedema",
        "paroxysmal nocturnal",
        "orthopnoea",
        "ectopy",
        "jugular venous pressure",
        "apex",
        "heaves",
        "thrills",
        "collapsing pulse",
        "radio-radial",
        "radio-femoral",
        "peripheral oedema",
        "echocardiogram",
        "exercise tolerance testing",
        "ETT",
        "cardiac MRI",
        "stress perfusion MRI",
        "CT coronary angiogram",
        "holter",
        "ziopatch",
        "coronary angiogram",
        "radial access",
        "femoral access",
        "5 French",
        "6 French",
        "7 French",
        "left main stem",
        "left anterior descending artery",
        "circumflex",
        "diagonal",
        "obtuse marginal",
        "right coronary artery",
        "coronary intervention",
        "guide wire",
        "compliant balloon",
        "non-compliant balloon",
        "drug eluting stent",
        "expansion",
        "apposition",
        "dissection",
        "perforation",
        "covered stent",
        "IVUS",
        "OCT",
        "iFR",
        "FFR",
        "stenting",
        "POBA ",
        "intravascular ultrasound",
        "optical coherence tomography",
        "nstantaneous wave-free ratio",
        "fractional flow reserve",
        "plain old balloon angioplasty",
        "CABG",
        "LIMA",
        "left internal mammary artery",
        "vein graft",
        "sternotomy",
        "off pump",
        "on pump",
        "minimal access",
    ],
    genetics: [
        "abnormality",
        "amplification",
        "Angelman syndrome",
        "anomaly",
        "autosomal dominant inheritance",
        "autosomal recessive inheritance",
        "autosome",
        "biochemical genetic testing",
        "birth defect",
        "carrier testing",
        "centromere",
        "chromosome",
        "chromosomal microarray analysis",
        "codon",
        "congenital",
        "congenital anomaly",
        "congenital varicella syndrome",
        "Cri du Chat syndrome",
        "cystic fibrosis",
        "cytogenetics",
        "de novo",
        "deletion",
        "diagnostic testing",
        "direct DNA studies",
        "DNA",
        "deoxyribonucleic acid",
        "Down syndrome",
        "duplication",
        "enzyme replacement therapy",
        "ERT",
        "extended banding chromosome study",
        "fetal alcohol syndrome",
        "fetal hydantoin syndrome",
        "first-degree relative",
        "fluorescence in situ hybridization",
        "FISH",
        "folic acid",
        "fragile-X syndrome",
        "galactosemia",
        "gene",
        "gene therapy",
        "genetic",
        "genetic counseling",
        "hemophilia A",
        "human genome project",
        "hypothyroidism",
        "inborn error of metabolism",
        "incontinentia pigmenti",
        "indirect DNA studies",
        "inheritance",
        "inversion",
        "isolated",
        "karyotype",
        "Leber's Hereditary Optic Neuropathy",
        "LHON",
        "Marfan syndrome",
        "markers",
        "meiosis",
        "Mendel",
        "metabolism",
        "mitochondrial inheritance",
        "mitosis",
        "monosomy",
        "mosaicism",
        "multifactorial",
        "mutations",
        "newborn screening",
        "nondisjunction",
        "open neural tube defects",
        "ONTDs",
        "p-arm",
        "pallister killian",
        "pedigree",
        "phenylketonuria",
        "prader-willi syndrome",
        "preconception visit",
        "predictive genetic testing",
        "preimplantation studies",
        "prenatal diagnosis",
        "presymptomatic genetic testing",
        "q-arm",
        "red-green color blindness",
        "ring chromosome",
        "second-degree relative",
        "sex chromosomes",
        "sibling",
        "sickle cell anemia",
        "syndrome",
        "Tay Sachs disease",
        "teratogen",
        "third-degree relative",
        "threshold",
        "translocation",
        "trinucleotide repeats",
        "trisomy",
        "trisomy 13",
        "trisomy 18",
        "trisomy 21",
        "Turner syndrome",
        "uniparental disomy",
        "varicella",
        "vitamin therapy",
        "VZIG",
        "X-linked inheritance",
    ],
    neurophysiology: [
        "absence",
        "activation procedure",
        "active sleep",
        "activity",
        "activité moyenne",
        "after-discharge",
        "aliasing",
        "alpha band",
        "alpha rhythm",
        "alpha variant rhythms",
        "alpha wave",
        "amplitude",
        "EEG",
        "analog-to-digital conversion",
        "anterior dysrhythmia",
        "application",
        "arrhythmic activity",
        "arousal",
        "array",
        "artifact",
        "asymmetry",
        "asynchrony",
        "attenuation",
        "auditory evoked potential",
        "AEP",
        "augmentation",
        "average reference electrode",
        "background activity",
        "background slowing",
        "bandwidth",
        "basal electrode",
        "baseline",
        "benign epileptiform discharges of childhood",
        "benign epileptiform transient of sleep",
        "BETS",
        "beta band",
        "beta rhythm or activity",
        "bilateral",
        "bilateral independent periodic discharges",
        "BIPDs",
        "bilateral independent periodic lateralized epileptiform discharges",
        "BIPLEDs",
        "bin width",
        "biological calibration",
        "biphasic wave",
        "bipolar derivation",
        "bipolar montage",
        "brainstem auditory evoked potential",
        "breach rhythm",
        "buffer amplifier",
        "build-up",
        "burst",
        "burst suppression",
        "calibration",
        "cap",
        "channel",
        "circumferential bipolar montage",
        "clipping",
        "closely spaced electrodes",
        "common average reference",
        "Common EEG input test",
        "common mode rejection",
        "common mode signal",
        "common reference electrode",
        "common reference montage",
        "complex",
        "contingent negative variation",
        "CNV",
        "continuous EEG",
        "continuous slow activity",
        "continuous spikes and waves during sleep",
        "CSWS",
        "coronal bipolar montage",
        "cortical electrode",
        "cortical electroencephalogram",
        "cortical electroencephalography",
        "cycles per second",
        "deep sleep",
        "delta band",
        "delta brush",
        "delta wave",
        "depth electrode",
        "depth electroencephalogram",
        "depth electroencephalography",
        "derivation",
        "desynchronization",
        "diffuse",
        "differential amplifier",
        "differential signal",
        "Digital EEG",
        "diphasic wave",
        "dipole",
        "direct coupled amplifier",
        "direct current amplifier",
        "discharge",
        "disk electrode",
        "disorganization",
        "display gain",
        "distortion",
        "duration",
        "electrical status epilepticus during sleep",
        "electrocerebral inactivity",
        "electrocorticogram",
        "electrocorticography",
        "electrode",
        "electrodecrement",
        "electrode impedance",
        "electrode resistance",
        "electroencephalogram",
        "electroencephalograph",
        "electroencephalographic",
        "electroencephalography",
        "encoche frontale",
        "epicortical electrode",
        "epidural electrode",
        "epileptiform pattern",
        "epoch",
        "equipotential",
        "event-related potential",
        "evoked magnetic field",
        "evoked potential",
        "EP",
        "exploring electrode",
        "extracerebral potential",
        "extreme delta brush",
        "far-field potential",
        "fast activity",
        "fast alpha variant rhythm",
        "fast ripples",
        "fast wave",
        "focal",
        "focus",
        "foramen ovale electrode",
        "frequency",
        "frequency response",
        "frequency response curve",
        "frequency spectrum",
        "frontal intermittent rhythmic delta activity",
        "fronto-central theta",
        "voltage gain",
        "gamma band",
        "gamma rhythm or activity",
        "generalization",
        "generalized",
        "generalized paroxysmal fast activity",
        "generalized periodic discharges",
        "generalized periodic epileptiform discharges",
        "graphoelement",
        "ground connection",
        "ground projection",
        "hertz",
        "high frequency filter",
        "HFO's",
        "high frequency oscillations",
        "high frequency response",
        "high pass filter",
        "hypersynchrony",
        "hyperventilation",
        "hypnagogic hypersynchrony",
        "hypsarrhythmia",
        "ictal EEG pattern",
        "impedance meter",
        "inactivity, record of electrocerebral",
        "incidence",
        "independent",
        "infraslow activity",
        "in-phase signals",
        "input circuit",
        "input impedance",
        "input terminal 1",
        "input terminal 2",
        "input voltage",
        "inter-electrode distance",
        "interhemispheric derivation",
        "intermittent photic stimulation",
        "intermittent slow activity",
        "intracerebral depth electroencephalogram",
        "intracerebral electrode",
        "isoelectric",
        "isopotential",
        "K complex",
        "Lambda wave",
        "laplacian montage",
        "lateralized",
        "lateralized periodic discharges",
        "linkage",
        "longitudinal bipolar montage",
        "low frequency filter",
        "low frequency response",
        "low pass filter",
        "low voltage EEG",
        "low voltage fast activity",
        "magnetoencephalography",
        "MEG",
        "mismatch negativity",
        "monorhythmic delta activity",
        "montage",
        "morphology",
        "motor evoked potential",
        "Mu rhythm",
        "multifocal",
        "multiple spike-and-slow-wave complex",
        "multiple spike complex",
        "multiregional",
        "nasopharyngeal electrode",
        "needle electrode",
        "non-cephalic reference",
        "non-REM sleep",
        "notch filter",
        "nyquist theorem",
        "occipital intermittent rhythmic delta activity",
        "OIRDA",
        "ohmmeter",
        "ordinate period",
        "out-of-phase signals",
        "output voltage",
        "overbreathing",
        "overload",
        "P3",
        "pad electrode",
        "paper speed",
        "paroxysm",
        "paroxysmal fast",
        "periodic",
        "periodic discharges",
        "periodic lateralized epileptiform discharges",
        "PLEDs",
        "phase reversal",
        "photic driving",
        "photic evoked potential",
        "photic stimulation",
        "photic stimulator",
        "photomyogenic response",
        "photoparoxysmal response",
        "PPR",
        "polarity convention",
        "polarity   ",
        "polygraphic recording",
        "polymorphic activity",
        "polyphasic wave",
        "polysomnography",
        "polyspike-and-slow-wave complex",
        "polyspike complex",
        "positive occipital sharp transient of sleep",
        "POSTS",
        "positive rolandic sharp waves",
        "PRSW",
        "PBR",
        "posterior basic rhythm",
        "posterior dominant rhythm",
        "posterior slow waves of youth",
        "potential",
        "potential field",
        "power spectrum",
        "premature temporal theta",
        "prevalence",
        "propagation",
        "psychomotor variant",
        "quantitative EEG",
        "quasiperiodic",
        "quiet sleep",
        "reactivity",
        "record of electrocerebral inactivity",
        "reference electrode",
        "referential derivation",
        "referential montage",
        "reformatting",
        "REM",
        "REM atonia",
        "REM sleep",
        "RC coupled amplifier",
        "resolution",
        "rhythmic",
        "rhythmic temporal theta",
        "rhythmic temporal theta burst of drowsiness",
        "rhythm of alpha frequency",
        "ripples",
        "rolandic spikes",
        "sampling rate",
        "sawtooth waves",
        "scalp electrode",
        "scalp electroencephalogram",
        "scalp electroencephalography",
        "secondary bilateral synchrony",
        "seizure pattern",
        "sensitivity",
        "sharp wave",
        "sharp-and-slow-wave complex",
        "simultaneous",
        "sine wave",
        "sinusoidal",
        "sleep onset",
        "sleep spindle",
        "slow alpha variant rhythms",
        "slow-fused transient",
        "small sharp spikes",
        "somatosensory evoked potential",
        "special electrode",
        "sphenoidal electrode",
        "spike-and-slow-wave complex",
        "spindle",
        "standard electrode",
        "standard electrode placement",
        "status epilepticus",
        "stereotactic electroencephalogram",
        "stereotactic  electroencephalography",
        "sternospinal reference",
        "stimulus-induced rhythmic discharge",
        "stimulus-induced periodic discharge",
        "stimulus-induced ictal discharge",
        "subclinical rhythmic discharges",
        "subdural electrode",
        "suppression",
        "symmetry",
        "synchrony",
        "temporal intermittent rhythmic delta activity",
        "temporal slow activity",
        "ten-ten system",
        "ten-twenty system",
        "Theta",
        "Three Hz spike-and-slow-wave complex",
        "time constant",
        "topography",
        "Tracé alternant",
        "Tracé continu",
        "Tracé discontinu",
        "transient",
        "transverse bipolar montage",
        "triangular bipolar montage",
        "Triphasic wave",
        "unilateral",
        "vertex sharp wave",
        "V wave",
        "visual evoked potential",
        "volume conduction",
        "waveform",
        "wicket spikes",
        "wicket waves",
    ],
    pharmacology: [
        "absorption rate constant",
        "accumulation ratio",
        "accuracy",
        "validity",
        "reliability",
        "sensitivity",
        "amplification",
        "precision",
        "comparability",
        "economy",
        "activity",
        "intrinsic",
        "addiction",
        "affinity",
        "agonist",
        "partial agonist",
        "full agonist",
        "inverse agonist",
        "allergic response",
        "analgesic",
        "anesthetic",
        "antagonism",
        "chemical",
        "physiological",
        "pharmacological",
        "area under the curve",
        "AUC",
        "availability",
        "bioassay",
        "biological assay",
        "bioavailability",
        "biopharmaceutics",
        "biotransformation",
        "biotranslocation",
        "blind experiment",
        "ceiling",
        "chemotherapy",
        "clearance",
        "renal clearance",
        "non renal clearance",
        "clinical therapeutic index",
        "compartments",
        "compliance",
        "cross-over experiment",
        "cross-tolerance",
        "CT index",
        "dependence",
        "disintegration time",
        "dissolution time",
        "distribution",
        "dosage form",
        "dose",
        "dose-duration curve",
        "dose-effect curve",
        "drug dependence",
        "effective",
        "efficacy",
        "elimination rate constant",
        "equipotent",
        "chemical equivalents",
        "biological equivalents",
        "clinical equivalents",
        "experiment",
        "first-order kinetics",
        "first pass effect",
        "food and drug administration",
        "generic drugs",
        "habituation",
        "half-life",
        "Harrison act",
        "hazard",
        "hypersensitivity",
        "hypnotic",
        "idiosyncratic response",
        "infusion kinetics",
        "intrinsic efficacy",
        "intrinsic activity",
        "latent period or latency",
        "loading dose",
        "maintenance dose",
        "meidan effective dose",
        "metameter",
        "multiple dose regimens",
        "narcotic",
        "national formulary",
        "negative control drug",
        "negative control procedure",
        "parameter",
        "pharmacodynamics",
        "pharmacogenetics",
        "pharmacokinetics",
        "pharmacology",
        "placebo",
        "positive control drug",
        "potency",
        "potentiation",
        "priming dose",
        "prodrug",
        "receptors",
        "reference standard",
        "selectivity",
        "side effects",
        "spare receptors",
        "specificity",
        "standard drug",
        "standardized safety margin",
        "supersensitivity",
        "synergy",
        "tachyphylaxis",
        "therapeutic index",
        "therapeutics",
        "curative therapy",
        "palliative therapy",
        "symptomatic therapy",
        "specific therapy",
        "supportive therapy",
        "substitutive or replacement therapy",
        "restorative therapy",
        "threshold dose",
        "time-concentration curve",
        "tolerance",
        "toxic effects",
        "toxicology",
        "two-state model",
        "volume of distribution",
        "zero-order kinetics",
    ],
    dermatology: [
        "abscess",
        "acute",
        "adipose tissue",
        "alopecia",
        "anaesthesia",
        "annular",
        "appendageal",
        "apocrine gland",
        "arciform",
        "asymmetrical",
        "atopy",
        "atrophy",
        "axilla",
        "basement membrane",
        "benign",
        "bilateral",
        "biopsy",
        "Blaschko's lines",
        "bulla",
        "carbuncle",
        "cellulitis",
        "chronic",
        "cold sore",
        "collagen",
        "connective tissue",
        "crust",
        "cryotherapy",
        "curettage",
        "curette",
        "cyst",
        "dermatome",
        "dermatosis",
        "dermis",
        "desquamation",
        "discoid",
        "distal",
        "distribution",
        "dorsal",
        "drug eruption",
        "dystrophy",
        "ecchymosis",
        "eccrine gland",
        "electrodessication",
        "epidermis",
        "erythema",
        "excoriation",
        "extremities",
        "fissure",
        "flare",
        "flexural",
        "follicular",
        "fungating",
        "generalised",
        "genodermatoses",
        "granulation tissue",
        "hirsutism",
        "hyperhidrosis",
        "hyperpigmentation",
        "hypertrichosis",
        "hypertrophic",
        "hypopigmentation",
        "idiopathic",
        "immunocompromised",
        "immunosuppressed",
        "indurated",
        "inflammation",
        "intertrigo",
        "keloid",
        "keratinocytes",
        "squamous cells",
        "koebnerisation",
        "lesion",
        "lichenification",
        "linear",
        "localised",
        "maceration",
        "macule",
        "malignant",
        "melanin",
        "melanocytes",
        "morphology",
        "natal cleft",
        "necrosis",
        "neonate",
        "neuropathic",
        "nodule",
        "palmar",
        "papule",
        "paraesthesia",
        "paronychia",
        "patch test",
        "pedunculated",
        "perianal",
        "perineal",
        "perioral",
        "periorbital",
        "photodermatosis",
        "plantar",
        "plaque",
        "polymorphous",
        "proximal",
        "pruritus",
        "purpura",
        "pustule",
        "recalcitrant",
        "reticular",
        "scale",
        "sebaceous gland",
        "seborrhoeic",
        "sessile",
        "sweat gland",
        "symmetrical",
        "subcutaneous",
        "telangiectasia",
        "tender",
        "ulceration",
        "umbilical",
        "unilateral",
        "verrucous",
        "vesicle",
        "wheal and flare",
        "Wood's lamp examination",
        "Mohs surgery",
        "xerosis",
        "zoonosis",
        "tetracycline",
        "adapoline",
        "hydroquinone",
        "minoxidil",
        "penicilin",
        "cephalosporins",
        "cavilon",
        "conotrane",
        "drapolene",
        "dermol 500",
        "epimax oatmeal",
        "epimax isomol",
        "epimax",
        "imuderm",
        "flexitol",
        "neomycin cream",
        "clotrimazole",
        "metronidazole",
        "mupirocin",
        "silver sulphadiazine",
        "fusidic acid",
        "econazole nitrate",
        "amorolfine",
        "undecenoic",
        "nystatin",
        "malathion",
        "aciclovir",
        "hydrocortisone",
        "betamethasone valerate",
        "betamethasone",
        "clobetasol proprionate",
        "pimecrolimus",
    ],
    endocrinology: [
        "A1C",
        "acidosis",
        "adult-onset diabetes",
        "albuminuria",
        "alpha cell",
        "amino acids",
        "antibodies",
        "atherosclerosis",
        "autoimmune disease",
        "autonomic neuropathy",
        "background retinopathy",
        "basal rate",
        "beta cell",
        "blood glucose",
        "blood pressure",
        "blood urea nitrogen",
        "bunion",
        "callus",
        "carbohydrate",
        "cardiologist",
        "certified diabetes educator",
        "cholesterol",
        "continuous glucose monitor",
        "Dawn phenomenon",
        "dehydration",
        "delta cell",
        "dextrose",
        "diabetic ketoacidosis",
        "dietitian",
        "emergency medical identification",
        "endocrinologist",
        "fasting plasma glucose test",
        "fructose",
        "gangrene",
        "gastroparesis",
        "gestational diabetes mellitus",
        "glaucoma",
        "glucagon",
        "glucose",
        "glycosylated hemoglobin test",
        "home blood glucose monitoring",
        "hyperglycemia",
        "hypertension",
        "hypoglycemia",
        "injection site rotation",
        "injection sites",
        "insulin",
        "insulin dependent diabetes",
        "insulin pump",
        "insulin reaction",
        "insulin receptors",
        "insulin resistance",
        "insulin shock",
        "juvenile diabetes",
        "ketoacidosis",
        "ketones",
        "lancet",
        "laser treatment",
        "late-onset diabetes",
        "metabolic syndrome",
        "nephropathy",
        "neuropathy",
        "non-insulin dependent diabetes",
        "ophthalmologist",
        "optometrist",
        "oral medications",
        "pancreas",
        "periodontal disease",
        "peripheral neuropathy",
        "peripheral vascular disease",
        "podiatrist",
        "polydipsia",
        "polyphagia",
        "polyunsaturated fat",
        "polyuria",
        "pre-mixed insulin",
        "renal threshold",
        "retina",
        "retinopathy",
        "saccharin",
        "somogyi effect",
        "triglyceride",
        "Type 1 diabetes",
        "Type 2 diabetes",
        "ulcer",
        "unit of insulin",
        "urinary tract infection",
        "UTI",
        "vaginitis",
        "vascular",
        "vein",
        "vitrectomy",
        "yeast infection",
        "metformin",
        "antiandrogens",
        "antidiabetics",
    ],
    gastroenterology: [
        "abdomen",
        "achalasia",
        "acute",
        "acquired",
        "aerophagia",
        "afferent nerves",
        "aganglionosis",
        "ambulatory care",
        "amino acids",
        "anal fissure",
        "anastomosis",
        "antispasmodics",
        "autonomic nervous system",
        "barium",
        "biliary tract",
        "biomedical model",
        "biopsy",
        "biopsychosocial model",
        "borborygmi",
        "brain-gut axis",
        "capsule endoscopy",
        "coeliac",
        "chronic",
        "clinical significance",
        "cohort study",
        "colectomy",
        "colitis",
        "colonoscopy",
        "colostomy",
        "congenital",
        "contrast radiology",
        "constipation",
        "cortisol",
        "Crohn's disease",
        "cytokines",
        "dehydration",
        "diaphragm",
        "dilatation",
        "distention",
        "diverticulitis",
        "diverticula",
        "double blinding",
        "duodenum",
        "dysphagia",
        "effectiveness",
        "efficacy",
        "efferent nerves",
        "electrolytes",
        "endoscope",
        "endoscopy",
        "enteral nutrition",
        "enteritis",
        "enterocolitis",
        "enteroscopy",
        "enteric nervous system",
        "eosinophilic gastroenteritis",
        "epidemiology",
        "epithelium",
        "ERCP",
        "esophagitis",
        "esophagus",
        "EGD",
        "etiology",
        "evidence-based",
        "failure to thrive",
        "familial",
        "fecalith",
        "fistula",
        "functional disorder",
        "gastritis",
        "gastroenteritis",
        "gastroenterologist",
        "gastroenterology",
        "gastrointestinal tract",
        "gastroparesis",
        "gastroscopy",
        "gastrostomy",
        "GERD",
        "glial cell",
        "gluten intolerance",
        "helicobacter pylori",
        "hemorrhoids",
        "hepatic",
        "hereditary",
        "heritability",
        "hiatal hernia",
        "hyperalgesia",
        "hypervigilance",
        "ileostomy",
        "ileum",
        "IBD",
        "ingestion",
        "inherited",
        "interstitial cystitis",
        "innervated",
        "intervention",
        "intestinal mucosa",
        "intestinal pseudo-obstruction",
        "investigational",
        "ischemic colitis",
        "jejunostomy",
        "laboratory study",
        "laboratory test",
        "lactose",
        "laparoscopy",
        "laxative",
        "lymphocyte",
        "manometry",
        "mast cell",
        "mediators",
        "meta-analyses",
        "morbidity",
        "motility",
        "myogenic",
        "nasogastric tube",
        "neuropeptide",
        "neurotransmitter",
        "OCD",
        "P-value",
        "pain threshold",
        "palpation",
        "parenteral nutrition",
        "pathogenesis",
        "pathology",
        "pathophysiology",
        "peptic ulcer",
        "perineum",
        "postprandial",
        "prevalence",
        "primary lactase deficiency",
        "proceedings",
        "progressive muscle relaxation",
        "prokinetic",
        "prospective study",
        "protease",
        "PPI",
        "proton pump inhibitor",
        "radiation proctitis",
        "random allocation",
        "randomized controlled trial",
        "refractory",
        "retrospective study",
        "reuptake",
        "satiety",
        "scintigraphy",
        "sigmoid colon",
        "sigmoidoscopy",
        "somatization",
        "sphincter",
        "spinal cord",
        "squamous cell",
        "syndrome",
        "systemic",
        "tertiary care",
        "translational science",
        "ulcerative colitis",
        "ultrasound",
        "upper endoscopy",
        "upper GI series",
        "valsalva maneuver",
        "villi",
        "visceral hypersensitivity",
        "barium swallow",
        "barium enema",
        "upper gastrointestinal series",
        "pancreas scan",
        "PEG",
        "proctectomy",
        "antacids",
        "omeprazole",
        "lansoprazole",
        "esomeprazole",
        "pantoprazole",
        "abeprazole",
        "ranitidine",
        "mebeverine",
        "loperamide",
        "mesalazine",
        "infliximab",
        "OCD",
        "ERCP",
    ],
    internal: [
        "ecchymosis",
        "DVT",
        "bipolar disorder",
        "suicidal ideation",
        "erythema",
        "CKD",
        "Rovsing's Sign",
        "gastroesophageal reflux disease",
        "CAD",
        "CHF",
        "Bell's Palsy",
        "synscope",
        "MI",
        "cachectic",
        "nystagmus",
        "abscess",
        "nephrolithiasis",
        "hyperlipidemia",
        "TIA",
        "tonsillectomy",
        "pyelonephritis",
        "pneumothorax",
        "CVA",
        "varicose veins",
        "diabetes",
        "jaundice",
        "mastectomy",
        "ausculation",
        "A-fib",
        "dyspnea",
        "dysmenorrhea",
        "reduction",
        "hysterectomy",
        "hemastemesis",
        "rhinorrhea",
        "Dix-Hallpike test",
        "cholecystectomy",
        "Romberg test",
        "epistaxis",
        "appendectomy",
        "vertigo",
        "COPD",
        "catheter",
        "foley",
        "pneumonia",
        "PEG tube",
        "dysphagia",
        "anemia",
        "UTI",
        "pronator drift",
        "edema",
        "hypothyroidism",
        "CABG",
        "scleral icterus",
        "schizophrenia",
        "palpitations",
        "migraines",
        "preipheral neuropathy",
        "hypertension",
        "dysuria",
    ],
    genitourinary: [
        "analgesic",
        "biopsy",
        "bladder",
        "circumcision",
        "computed tomography scan",
        "cryptorchidism",
        "cystoscopy",
        "voiding cystogram",
        "dialysis",
        "electroencephalogram",
        "enuresis",
        "epispadias",
        "exstrophy of the bladder",
        "glomerulonephritis",
        "glomerulosclerosis",
        "hematuria",
        "hemolytic uremic syndrome",
        "horseshoe kidney",
        "hydronephrosis",
        "inguinal hernia",
        "EEG",
        "kidney stone",
        "kidney transplantation",
        "magnetic resonance imaging",
        "megaureter",
        "MRI",
        "nephrectomy",
        "nephrology",
        "nephrotic syndrome",
        "neurogenic bladder",
        "orchiopexy",
        "overflow incontinence",
        "paraphimosis",
        "phimosis",
        "polycystic kidney disease",
        "posterior urethral valves",
        "priapism",
        "proteinuria",
        "prune belly syndrome",
        "renal angiography",
        "renal ultrasound",
        "sphincter muscles",
        "testicular torsion",
        "ureterocele",
        "ureteroscope",
        "ureters",
        "urethra",
        "urethritis",
        "urinalysis",
        "urinary incontinence",
        "urinary tract infection",
        "urogenital",
        "vesicoureteral reflux",
        "VUR",
        "fluconazole",
        "estriol",
        "metronidazole",
        "norethisterone",
        "ulipristal acetate",
        "tamsulosin",
        "tetracycline",
        "oxybutynin",
        "tolterodine",
        "sildenafil",
    ],
    geriatric: [
        "allele",
        "apoptosis",
        "antagonistic pleiotropy",
        "autophagy",
        "biogerontology",
        "caloric restriction",
        "cellular senescence",
        "cellular immortality",
        "demography",
        "diphyodont",
        "diploid",
        "disposable soma theory",
        "dyskeratosis congenita",
        "ectotherm",
        "endocrine system",
        "endotherm",
        "epigenetics",
        "eutherian",
        "exonuclease",
        "genetics",
        "genomics",
        "genome",
        "genotype",
        "geriatrics",
        "germ cells",
        "gerontology",
        "Hayflick limit",
        "helicase",
        "IMR",
        "iteroparous",
        "longevity",
        "maximum lifespan",
        "mechanical senescence",
        "mitochondrion",
        "MRDT",
        "negligible senescence",
        "oocyte",
        "oogenesis",
        "oxidative stress",
        "phenotype",
        "phylogeny",
        "polyphenism",
        "polyphyodont",
        "polyploid",
        "progeria",
        "progeroid",
        "quiescent",
        "reactive oxygen species",
        "replicative senescence",
        "semelparous",
        "senescence",
        "senescent cell",
        "soma",
        "SENS",
        "SIPS",
        "supercentenarian",
        "topoisomerase",
        "taxon",
        "telomeres",
        "telomerase",
        "Werner syndrome",
        "MMSE ",
        "Mini Mental State Examination",
        "GDS",
        "Geriatric Depression Scale",
        "CBC",
        "complete blood count",
        "agnosia",
        "Alzheimer's",
        "anomia",
        "apraxia",
        "CJD",
        "Creutzfeldt-Jakob disease",
        "cerebellum",
        "cognition",
        "dementia",
        "dysarthria",
        "dysphagia",
        "dysphasia",
        "frontal lobe",
        "frontotemporal dementia",
        "hippocampus",
        "Korsakoff's syndrome",
        "mild cognitive impairment",
        "MCI",
        "occipital lobe",
        "neurodegenrative",
        "balance ",
        "orientated ",
        "orientation",
        "parietal lobes",
        "person-centric care",
        "temporal lobes",
        "vascular dementia",
        "anosognosia",
        "aphasia",
        "amnesia",
        "apahty",
        "altered perception",
        "delirium",
        "ACE-R ",
        "HADS hospital anxiety",
        "rivastigmine",
        "donepezil",
        "memantine",
        "Bristol Activity Score",
        "BADLS",
    ],
    immunology: [
        "absolute CD4",
        "active site",
        "adaptive immunity",
        "adjuvant",
        "affinity",
        "affinity maturation",
        "allergens",
        "allergic reaction",
        "allogenic",
        "allotype",
        "amino acid",
        "anaphylaxis",
        "anergy",
        "anti-HBe",
        "anti-hepatitis B core antigen",
        "antibody",
        "antigen",
        "antigen presenting cell",
        "APC",
        "apoptosis",
        "association constant",
        "dissociation constant",
        "ATP",
        "autocrine",
        "autologous",
        "B cell receptor",
        "BCG vaccine",
        "base analogue",
        "base-pairing",
        "basophil",
        "binding affinity",
        "Bursa of Fabricius",
        "CCR5",
        "CD antigen",
        "CD nomenclature",
        "CD4",
        "CD4 percentage",
        "CD8",
        "cellular enzymes",
        "centrosome",
        "chain-terminators",
        "chemokine",
        "clusters of differentiation",
        "congenic",
        "CXCR4",
        "cytokine",
        "cytotoxic t lymphocyte",
        "degranulation",
        "dendritic cells",
        "DNA polymerization",
        "drug-resistant tuberculosis",
        "effector cell",
        "ELISA",
        "endocrine",
        "endotoxin",
        "eosinophil",
        "epitope",
        "extensively drug-resistant tuberculosis",
        "fab fragment",
        "Fc fragment",
        "Fc receptor",
        "germinal centre",
        "GM-CSF",
        "granulocyte",
        "granuloma",
        "haplotype",
        "hapten",
        "HBV DNA",
        "HAV antibody test",
        "Hepatitis B surface antibody",
        "Hepatitis B surface antigen",
        "heterologous",
        "histocompatibility",
        "HLA",
        "HLA complex",
        "homologous",
        "human leukocyte antigen class I",
        "human leukocyte antigen class II",
        "humoral immunity",
        "hybridoma",
        "hydrophobic",
        "hydroxyl group",
        "hepatitis",
        "immune complex",
        "immunodeficiency diseases",
        "immunogen",
        "immunoglobulin",
        "INF-γ",
        "innate immunity",
        "interferon",
        "interleukin",
        "isotype",
        "knock out mouse",
        "kupffer cell",
        "langerhans cell",
        "langerhans giant cells",
        "leukocytes",
        "leukotriens",
        "ligand",
        "lymphadenopathy",
        "lymphocyte",
        "lymphoma",
        "lysosome",
        "M-CSF",
        "macrophage",
        "MALT",
        "mast cell",
        "memory cell",
        "MHC",
        "mitotic spindle",
        "monoclonal antibody",
        "monocyte",
        "multidrug-resistant tuberculosis",
        "mutagen",
        "mutant reverse transcriptase",
        "naïve lymphocyte",
        "naïve lymphocytes",
        "natural killer cell",
        "neonate",
        "neutrophil",
        "NK T cell",
        "NRTI-triphosphate",
        "nucleotide",
        "nucleotide base analogues",
        "opsonin",
        "opsonization",
        "osteoclast",
        "P24 antigen",
        "paracrine",
        "peptide chains",
        "peripartum transmission",
        "Peyer’s patch",
        "phagocyte",
        "phagocytosis",
        "phagolysosome",
        "phagosome",
        "phosphate group",
        "phosphorylation",
        "plasma",
        "plasma cell",
        "polyclonal antibody",
        "polymerisation",
        "post translocation site",
        "postnatal transmission",
        "primary immune response",
        "primary immunodeficiencies",
        "primary lymphoid organs",
        "primary structure",
        "proteolytic",
        "replacement feeding",
        "replication forks",
        "resistance mutations",
        "reverse transcriptase",
        "reverse transcription",
        "SCID",
        "secondary immune response",
        "secondary immunodeficiencies",
        "secondary lymphoid organs",
        "somatic hypermutation",
        "spongiosis",
        "structural conformation",
        "substrate",
        "T cell",
        "T cytotoxic cell",
        "T helper cell",
        "thymocyte",
        "thymus",
        "Thymus-dependent antigen",
        "TNF-a",
        "translocation ability",
        "tuberculin",
        "V region",
        "viral load",
        "xenograft",
        "Ziehl-Neelsen stain",
    ],
    epidemiology: [
        "age-adjusted mortality rate",
        "age-specific mortality rate",
        "analytic epidemiology",
        "analytic study",
        "applied epidemiology",
        "association",
        "attack rate",
        "attributable proportion",
        "biologic transmission",
        "carrier",
        "case-control study",
        "case definition",
        "case-fatality rate",
        "cause of disease",
        "cause-specific mortality",
        "census",
        "chain of infection",
        "class interval",
        "cluster",
        "cohort study",
        "common source outbreak",
        "confidence interval",
        "confidence limit",
        "contagious",
        "contingency table",
        "crude mortality rate",
        "cumulative frequency",
        "cumulative frequency curve",
        "Death-to-case ratio",
        "demographic information",
        "denominator",
        "dependent variable",
        "descriptive epidemiology",
        "determinant",
        "direct transmission",
        "dot plot",
        "droplet nuclei",
        "droplet spread",
        "endemic disease",
        "environmental factor",
        "epidemic",
        "epidemic curve",
        "epidemic period",
        "epidemiological triad",
        "epidemiology",
        "frequency distribution",
        "frequency polygon",
        "health indicator",
        "health information system",
        "high-risk group",
        "histogram",
        "host factor",
        "hyperendemic disease",
        "hypothesis",
        "null hypothesis",
        "alternative hypothesis",
        "active immunity",
        "herd immunity",
        "passive immunity",
        "incidence rate",
        "incubation period",
        "independent variable",
        "indirect transmission",
        "individual data",
        "infectivity",
        "statistical inference",
        "interquartile range",
        "latency period",
        "arithmetic mean",
        "geometric mean",
        "measure of association",
        "measure of central location",
        "measure of dispersion",
        "medical surveillance",
        "neonatal mortality rate",
        "postneonatal mortality rate",
        "natural history of disease",
        "necessary cause",
        "nominal scale",
        "normal distribution",
        "numerator",
        "observational study",
        "odds ratio",
        "ordinal scale",
        "outbreak",
        "pandemic",
        "pathogenicity",
        "percentile",
        "period prevalence",
        "person-time rate",
        "point prevalence",
        "predictive value positive",
        "prevalence",
        "prevalence rate",
        "propagated outbreak",
        "proportionate mortality",
        "public health surveillance",
        "race-specific mortality rate",
        "random sample",
        "relative risk",
        "representative sample",
        "reservoir",
        "risk",
        "scatter diagram",
        "seasonality",
        "secondary attack rate",
        "secular trend",
        "sensitivity",
        "sentimental surveillance",
        "sex-specific mortality rate",
        "skewed",
        "specificity",
        "sporadic",
        "standard deviation",
        "standard error",
        "sufficient cause",
        "survival curve",
        "table shell",
        "transmission of infection",
        "universal precautions",
        "validity",
        "variable",
        "variance",
        "vector",
        "virulence",
        "vital statistics",
        "years of potential life lost",
        "zoonoses",
    ],
    oncology: [
        "acute",
        "acute lymphocytic leukemia",
        "ALL",
        "AML",
        "acute myelogenous leukemia",
        "adjuvant therapy",
        "allogeneic bone marrow transplant",
        "alopecia",
        "alternative therapy",
        "anemia",
        "anesthesia",
        "anesthesiologist",
        "angiogram",
        "apheresis",
        "aplastic anemia",
        "autologous bone marrow transplant",
        "benign",
        "bilateral",
        "biologic response modifiers",
        "biopsy",
        "bone marrow aspiration",
        "bone marrow transplant",
        "CAM",
        "carcinogen",
        "chemotherapy",
        "chromosome",
        "chronic myelogenous leukemia",
        "clinical trial",
        "complete blood count",
        "complementary therapy",
        "computed tomography scan",
        "drug resistance",
        "dysplasia",
        "dyspnea",
        "edema",
        "gene",
        "CT",
        "genetic counseling",
        "genetic testing",
        "germ cell",
        "CAT Scan",
        "graft-versus-host disease",
        "granulocytes",
        "growth factors",
        "hematocrit",
        "hematologist",
        "hematology",
        "hematopoiesis",
        "hemoglobin",
        "hepatoblastoma",
        "Hodgkin disease",
        "GVHD",
        "immune system",
        "immunosuppression",
        "immunotherapy",
        "informed consent",
        "intracranial pressure",
        "leukemia",
        "locally invasive",
        "lumbar puncture",
        "ICP",
        "lymph nodes",
        "lymph vessels",
        "lymphangiogram",
        "lymphatic system",
        "lymphocytes",
        "lymphocytic leukemia",
        "magnetic resonance imaging",
        "medical oncologist",
        "metastasis",
        "mucositis",
        "myelogenous leukemia",
        "myelogram",
        "myeloproliferative disorders",
        "nephrologist",
        "neuroblastoma",
        "neurosurgeon",
        "oncogenes",
        "oncology clinical nurse specialist",
        "oncology social worker",
        "ophthalmologist",
        "osteoid tissue",
        "osteosarcoma",
        "P53",
        "palliative treatment",
        "pathologist",
        "pediatric oncologist",
        "pediatrician",
        "physical therapist",
        "plasma",
        "platelet pheresis",
        "platelets",
        "pluripotent stem cell",
        "primary site",
        "prognosis",
        "protocol",
        "pruritus",
        "radiation oncologist",
        "radiation therapist",
        "radiation therapy",
        "radiologist",
        "regimen",
        "relapse",
        "remission",
        "retinoblastoma",
        "rhabdomyosarcoma",
        "sarcoma",
        "secondary tumor",
        "spinal tap",
        "surgical oncologist",
        "syngeneic bone marrow transplant",
        "total parenteral nutrition",
        "tumor suppressor genes",
        "ultrasound",
        "umbilical cord blood transplant",
        "unilateral",
        "urologist",
        "ventricle peritoneal shunt",
        "white blood cells",
        "Wilms tumor",
        "X-ray",
        "azathioprine",
        "ciclosporin",
        "mycophenolate",
        "interferon beta-1a",
        "glatiramer",
        "teriflunomide",
        "atezolizumab",
        "chlorambucil",
    ],
    ophthalmology: [
        "amblyopia",
        "antimetabolite",
        "anti-VEGF drugs",
        "aqueous",
        "astigmatism",
        "biologic therapeutic agent",
        "choroidal neovascular membrane",
        "ciliary body",
        "cornea",
        "cycloplegic",
        "Descemet’s membrane",
        "dioptre",
        "electromyography",
        "endophthalmitis",
        "endothelium",
        "epithelium",
        "excimer laser",
        "fovea",
        "foveal burn",
        "glaucoma",
        "hyperopia",
        "lens capsule",
        "limbus",
        "macular oedema",
        "microkeratome",
        "mydriatic",
        "myopia",
        "neovascular glaucoma",
        "NICE",
        "orthoptist",
        "pars plana",
        "posterior capsule opacification",
        "prostaglandin analogue",
        "refractive error",
        "retina",
        "Schlemm’s canal",
        "sclera",
        "strabismus",
        "stroma",
        "topical",
        "toric",
        "trabecular meshwork",
        "trabeculectomy",
        "uveitis",
        "vasculitis",
        "vascular endothelial growth factor",
        "VEGF",
        "vitrectomy",
        "vitreo-retinal surgery",
        "vitreous",
        "Zonular fibres",
        "antazoline",
        "ketotifen",
        "dexamethasone",
        "betamethasone sodium phosphate",
        "ciclosporin",
        "ciprofloxacin",
        "cefuroxime",
        "trifluorothimidine",
        "tropicamide",
    ],
    neurology: [
        "abducens nerve",
        "abduct",
        "absence seizure",
        "abulia",
        "acalculia",
        "acephalic migraine",
        "acrocephaly action potential",
        "action potential",
        "petit mal seizure",
        "action tremor",
        "activity limitations",
        "ADEM",
        "adduct",
        "Adie’s syndrome",
        "adrenoleukodystrophy",
        "afferent pupillary defect",
        "Marcus-Gunn pupil",
        "ageusia",
        "agnosia",
        "agraphia",
        "akathisia",
        "akinesia",
        "akinetic mutism",
        "alexia",
        "allodynia",
        "alpha motor neurons",
        "Alzheimer’s disease",
        "amaurosis fugax",
        "amblyopia",
        "amygdala",
        "amyotrophic lateral sclerosis",
        "analgesia",
        "anencephaly",
        "aneurysm",
        "angular gyrus",
        "anhidrosis",
        "anisocoria",
        "ankle-foot orthosis",
        "anomia",
        "anosmia",
        "anosognosia",
        "anterior cerebral artery",
        "anterior commissure",
        "anterior communicating artery",
        "anterior cord syndrome",
        "anterior horn",
        "Anton's syndrome",
        "aphasia",
        "aphemia",
        "aphonia",
        "apnea test",
        "apraxia",
        "aprosencephaly",
        "arachnoid",
        "arachnoid granulations",
        "arachnoid villi",
        "arcuate fasciculus",
        "area postrema",
        "Argyll-Robertson pupil",
        "arteriovenous malformation",
        "arthrokinetic nystagmus",
        "ascending tract of Deiters",
        "aseptic meningitis",
        "asomatognosia",
        "associated reactions",
        "association cortex",
        "astasia",
        "astasia-abasia",
        "asterixis",
        "astrocytes",
        "astrocytoma",
        "astrocytosis",
        "ataxia",
        "athetosis",
        "atonia",
        "atonic seizure",
        "autonomic",
        "autonomic nervous system",
        "autoregulation",
        "axial",
        "axon",
        "axonal degeneration",
        "axonal spheroid",
        "Babinski response",
        "balint syndrome",
        "basal ganglia",
        "basal palisading",
        "basilar artery",
        "basilar migraine",
        "behavioral symptom",
        "Bell’s palsy",
        "Bell's phenomenon",
        "Benedikt Syndrome",
        "Berry aneurysm",
        "Binswanger’s disease",
        "blepharospasm",
        "blood-brain barrier",
        "borderzone infarct",
        "brachial",
        "brachium",
        "brachium conjunctivum",
        "brachium pontis",
        "brachycephaly",
        "bradykinesia",
        "bradyphrenia",
        "brainstem",
        "BAEP",
        "Broca’s aphasia",
        "Brodmann's areas",
        "Brown Sequard syndrome",
        "Brudzinski’s sign",
        "bruxism",
        "calcarine cortex",
        "carotid sinus",
        "carphologia",
        "catamenial",
        "cataplexy",
        "cauda equina",
        "caudal",
        "caudate",
        "cavernoma",
        "cavernous sinus",
        "central chromatolysis",
        "central cord syndrome",
        "central herniation",
        "central nervous system",
        "central pain syndrome",
        "central sulcus",
        "centrum semiovale",
        "cephalic",
        "cerebellar peduncle",
        "cerebellar tonsil",
        "cerebellopontine angle",
        "cerebellum",
        "cerebral amyloid angiopathy",
        "cerebral aqueduct",
        "cerebral arteriosclerosis",
        "cerebral atherosclerosis",
        "cerebral atrophy",
        "cerebral cortex",
        "cerebral hemisphere",
        "cerebral peduncle",
        "cerebral perfusion pressure",
        "CPP",
        "cerebritis",
        "cerebrospinal fluid",
        "cerebrum",
        "Charcot-Bouchard aneurysm",
        "charcot joint",
        "cheiro",
        "Chiari malformation",
        "chorea",
        "choroid plexus",
        "choroid plexus papilloma",
        "chronic daily headache",
        "chronic paroxysmal hemicrania",
        "chronic-progressive",
        "cingulate gyrus",
        "cingulate herniation",
        "circadian rhythm",
        "Circle of Willis",
        "Claude Syndrome",
        "claustrum",
        "clay shoveler’s fracture",
        "clonic seizure",
        "clonus",
        "cluster headache",
        "CNS metastasis",
        "cogwheeling",
        "cold calorics",
        "communicating hydrocephalus",
        "complex partial seizure",
        "conduction aphasia",
        "conduction block",
        "contracoup",
        "contracture",
        "contralateral",
        "contusion",
        "conus medullaris",
        "convergence",
        "convergence-retraction nystagmus",
        "coprolalia",
        "coronal",
        "corona radiata",
        "corpus callosum",
        "corpus striatum",
        "cortical",
        "cortical blindness",
        "corticobasal degeneration",
        "corticobulbar tract",
        "pyramidal tract",
        "Cowdry A inclusion bodies",
        "Cowdry B inclusion bodies",
        "craft palsy",
        "craniectomy",
        "craniopharyngioma",
        "cranioplasty",
        "craniorachischisis totalis",
        "craniosynostosis",
        "craniotomy",
        "creatine kinase",
        "cremasteric reflex",
        "Creutzfeldt-Jakob disease",
        "crossed adductor reflex",
        "crural",
        "crus cerebri",
        "cryptogenic",
        "cyclotorsion",
        "Dawson’s fingers",
        "neurological criteria",
        "decerebrate rigidity",
        "decorticate rigidity",
        "decussate",
        "delayed sleep phase syndrome",
        "delirium",
        "demyelination",
        "dendrite",
        "denervation",
        "dermatome",
        "diabetes insipidus",
        "diaphragma sellae",
        "diaschisis",
        "diastematomyelia",
        "diencephalon",
        "diffuse axonal injury",
        "Lewy body dementia",
        "diplegia",
        "diplopia",
        "dissociated sensory deficit",
        "dolichocephaly",
        "doll’s phenomenon",
        "dorsal",
        "dorsal motor nucleus of the vagus",
        "dorsal rhizotomy",
        "dorsal root ganglion",
        "drop metastases",
        "dura mater",
        "dural tail sign",
        "Duret hemorrhages",
        "dysarthria",
        "dysdiadochokinesia",
        "dysesthesia",
        "dysgeusia",
        "dysgraphia",
        "dyskinesia",
        "dyslexia",
        "dysmetria",
        "dysphagia",
        "dysphonia",
        "dysraphism",
        "dyssomnia",
        "dystonia",
        "echolalia",
        "echopraxia",
        "Edinger-Westphal nucleus",
        "efferent",
        "electromyography",
        "eidetic memory",
        "electroencephalogram",
        "eloquent cortex",
        "empty sella",
        "encephalitis",
        "encephalocele",
        "encephalopathy",
        "endomysium",
        "enophthalmos",
        "eosinophilic neuronal necrosis",
        "ependyma",
        "ependymal cells",
        "ependymal true rosette",
        "ependymoma",
        "ephaptic",
        "epithalamus",
        "esotropia",
        "excyclotorsion",
        "executive functions",
        "exophthalmos",
        "exotropia",
        "extensor plantar response",
        "extensor posturing",
        "extensor response in the arm",
        "extensor spasm",
        "extra-axial",
        "extrapyramidal",
        "extrasylvian",
        "F-wave",
        "facial masking",
        "falx cerebri",
        "fascicle",
        "fasciculation",
        "festination",
        "fibrillation",
        "filum terminale",
        "finger agnosia",
        "Fisher scale",
        "description group",
        "febrile seizure",
        "fibrinoid necrosis",
        "flaccidity",
        "flexor plantar response",
        "flexor response in the arm",
        "flexor spasm",
        "floccillation flocculus",
        "fluent aphasia",
        "fontanel foramen magnum",
        "foramen of Monro",
        "forebrain",
        "fornix",
        "fortification spectrum",
        "Foster-Kennedy syndrome",
        "Foville syndrome",
        "fracture-dislocation",
        "Fresnel prisms",
        "frontotemporal dementia",
        "functional",
        "gadolinium-enhancing lesion",
        "gamma motor neurons",
        "ganglia",
        "gegenhalten",
        "gemistocyte",
        "generalized seizure",
        "geniculate ganglion",
        "genu",
        "germinal matrix",
        "Gerstmann syndrome",
        "Glasgow Coma Scale",
        "glia",
        "glioblastoma multiforme",
        "gliosis",
        "global aphasia",
        "globus pallidus",
        "glossopharyngeal nerve",
        "Golgi tendon organs",
        "Gradenigo Syndrome",
        "grand mal seizure",
        "graphesthesia",
        "gray matter",
        "Guillain-Barre syndrome",
        "gyrus",
        "habenula",
        "hangman’s fracture",
        "hemianesthesia",
        "hemianopia",
        "hemiballismus",
        "hemicrania continua",
        "hemifacial spasm",
        "hemifield",
        "hemiparesis",
        "hemiplegia",
        "hemiplegic migraine",
        "heterotopia",
        "hindbrain",
        "hippocampal formation",
        "hippocampus",
        "Hoffman’s sign",
        "holoprosencephaly",
        "Homer-Wright rosette",
        "Horner's syndrome",
        "H-reflex",
        "Hunt and Hess scale",
        "Huntington disease",
        "hydranencephaly",
        "hydrocephalus",
        "hyperacusis",
        "hyperpathia",
        "hypersomnolence",
        "hypertensive hemorrhage",
        "hypertonicity",
        "hypertropia",
        "hypnagogic hallucination",
        "hypnic headache",
        "hypnic",
        "hypnopompic hallucination",
        "hypoglossal nerve",
        "hypomimia",
        "hypophysis",
        "hypothalamus",
        "hypotonicity",
        "hypotropia",
        "hypoxia",
        "hypsarhythmia",
        "ictus",
        "idiopathic",
        "impairment",
        "incisura",
        "incyclotorsion",
        "indomethacin-responsive headache",
        "infantile spasms",
        "infarction",
        "inferior cerebellar peduncle",
        "inferior colliculus",
        "inferior olivary nucleus",
        "infratentorial",
        "infundibulum",
        "insula",
        "intention tremor",
        "internal capsule",
        "internal carotid artery",
        "inter-neuron",
        "internuclear ophthalmoplegia",
        "intracranial pressure",
        "intrathecal space",
        "intraventricular hemorrhage",
        "ipsilateral",
        "ischemia",
        "ischemic penumbra",
        "Jacksonian march",
        "JC virus",
        "jefferson fracture",
        "jitter",
        "juvenile myoclonic epilepsy",
        "JME",
        "Kernig’s sign",
        "Kernohan’s notch",
        "ketogenic diet",
        "kindling",
        "Korsakoff syndrome",
        "Krabbe disease",
        "kuru fatal prion disease",
        "lacunar stroke",
        "Lambert-Eaton Myasthenic syndrome",
        "laminar necrosis",
        "lateral medullary syndrome",
        "Wallenberg's syndrome",
        "Lennox-Gastaut syndrome",
        "lentiform nucleus",
        "leptomeninges",
        "leukodystrophy",
        "Lewy body",
        "light-near dissociation",
        "limbic",
        "lipohyalinosis",
        "Lissauer’s tract",
        "lissencephaly",
        "locus ceruleus",
        "Loftstrand Crutch",
        "long-tract signs",
        "lower motor neuron",
        "lumbar puncture",
        "macrocephaly",
        "macula",
        "mammillary bodies",
        "medial geniculate nucleus",
        "medial lemniscus",
        "medial longitudinal fasciculus",
        "medulla",
        "medulloblastoma",
        "medulla oblongata",
        "megalencephaly",
        "Meissner corpuscles",
        "MELAS",
        "meningeal carcinomatosis",
        "meninges",
        "meningioma",
        "meningitis",
        "meningocele",
        "menstrual migraine",
        "Merkel cells",
        "mesencephalon",
        "mesial temporal sclerosis",
        "Mesulam syndrome",
        "metachromatic leukodystrophy",
        "Meyer’s loop",
        "microcephaly",
        "microglia",
        "microglial nodules",
        "micrographia",
        "midbrain",
        "middle cerebellar peduncle",
        "middle cerebral artery",
        "middle cranial fossa",
        "mild cognitive impairment",
        "Millard-Gubler syndrome",
        "miosis",
        "mitochondrial myopathies",
        "Mollaret meningitis",
        "Moro reflex",
        "motor neglect",
        "motor neurons",
        "moyamoya syndrome multi-infarct dementia",
        "multiple sclerosis",
        "MSLT",
        "MSA",
        "multisystems atrophy",
        "muscle spindle",
        "mutism",
        "myasthenia gravis",
        "mycotic aneurysm",
        "mydriasis",
        "myelencephalon",
        "myelin",
        "myelitis",
        "myelogram",
        "myelomeningocele",
        "myelopathy",
        "myoclonus",
        "myokymia",
        "myopathy",
        "myotonia",
        "near reflex triad",
        "negative symptom",
        "neologism",
        "nerve conduction velocity study",
        "network inhibition hypothesis",
        "neuralgia",
        "neural plate",
        "neural tube",
        "neuraxis",
        "neurite",
        "neuritic plaque",
        "neurofibrillary tangles",
        "neurogenic bladder",
        "neuroglia",
        "neuroleptic",
        "neuroleptic malignant syndrome",
        "neuroma",
        "neuromuscular junction",
        "neuronopathy",
        "neuropathy",
        "neuronophagia",
        "Neuropil",
        "neuroprotection",
        "neurotransmitter",
        "neurulation",
        "nociceptive",
        "non-communicating hydrocephalus",
        "non-fluent aphasia",
        "non-REM sleep",
        "normal pressure hydrocephalus",
        "NPH",
        "Nothnagel Syndrome",
        "notochord",
        "nuchal",
        "nucleus ambiguus",
        "nucleus cuneatus",
        "nucleus gracilis",
        "nystagmus",
        "obstructive sleep apnea",
        "obtundation",
        "ocular migraine",
        "oculocephalic response",
        "oculogyric crisis",
        "oculomotor nerve",
        "oculovestibular response",
        "odontoid fracture",
        "olfactory nerve",
        "oligemia",
        "oligoclonal bands",
        "oligodendrocytes",
        "oligodendroglioma",
        "olivopontocerebellar atrophy",
        "Ondine's curse",
        "one-and-a-half syndrome",
        "on-off phenomenon",
        "opening pressure",
        "ophthalmoplegia",
        "ophthalmoplegic migraine",
        "opisthotonos",
        "optic atrophy",
        "optic chiasm",
        "OPCA",
        "optic neuritis optic radiations",
        "optic tract",
        "optokinetic nystagmus",
        "orthostatic hypotension",
        "orthotic",
        "oscillopsia",
        "osmophobia",
        "oxycephaly",
        "oxygen extraction fraction",
        "Pacinian corpuscle",
        "papilledema",
        "parachute reflex",
        "paramedian pontine reticular formation",
        "PPRF",
        "parameningeal",
        "paraphasia",
        "paraplegia",
        "parasagittal",
        "parasomnia",
        "parasympathetic nervous system",
        "paratonia",
        "parenchyma",
        "paresis",
        "paresthesia",
        "Parinaud’s syndrome",
        "Parkinson disease",
        "parkinsonism",
        "Parkinson plus syndromes",
        "partial seizure",
        "participation restrictions",
        "penumbra",
        "perceptual neglect",
        "periaqueductal gray",
        "perineurium",
        "periodic limb movement disorder",
        "peripheral nervous system",
        "perisylvian plexus",
        "perimysium",
        "phonophobia",
        "physiologic tremor",
        "Pick’s disease",
        "pincer grasp",
        "pineal",
        "pituitary apoplexy",
        "pituitary gland",
        "plagiocephaly",
        "plantar response",
        "plegia",
        "polymyositis",
        "polysomnogram",
        "pons",
        "porencephaly",
        "positive sharp wave",
        "positive symptom",
        "postdrome",
        "posterior cerebral artery",
        "posterior commissure",
        "posterior communicating artery",
        "posterior cord syndrome",
        "posterior fossa",
        "posterior horn",
        "post-herpetic neuralgia",
        "postural instability",
        "postural tremor",
        "praxis",
        "prefrontal cortex",
        "premotor cortex",
        "primary progressive",
        "primary progressive aphasia",
        "PNET",
        "prion",
        "prodrome",
        "PML",
        "progressive supranuclear palsy",
        "Steele-Richardson-Olszewski syndrome",
        "proprioception",
        "proptosis",
        "prosencephalon",
        "prosody",
        "pseudobulbar affect",
        "pseudobulbar palsy",
        "pseudorosette",
        "ptosis",
        "pupillary light response",
        "putamen",
        "quad cane",
        "quadrantanopia",
        "quadrigeminal bodies",
        "quadriplegia",
        "radiculopathy",
        "Ramsy-Hunt syndrome",
        "raphe",
        "raphe nuclei",
        "Raymond syndrome",
        "recurrent",
        "red desaturation",
        "remittent",
        "relapsing",
        "REM behavior disorder",
        "REM sleep",
        "restiform body",
        "RAS",
        "reticular formation",
        "reticulospinal tract",
        "retropulsion",
        "rhombencephalon",
        "ring-enhancement",
        "Romberg test",
        "rooting reflex",
        "Rosenthal fibers",
        "rostrocaudal",
        "rubral tremor",
        "Ruffini corpuscles",
        "saccade",
        "saccular aneurysm",
        "sagittal",
        "sagittal sulcus",
        "salivatory nuclei",
        "scanning speech",
        "scaphocephaly",
        "schizencephaly",
        "Schwann cell",
        "schwannoma",
        "scintillating scotoma",
        "scotoma",
        "secondary progressive",
        "sectoranopia",
        "sella turcica",
        "senile plaque",
        "sensory trick",
        "septal nuclei",
        "septum pellucidum",
        "shadow plaque",
        "sharp wave",
        "Shy-Drager syndrome",
        "sialorrhea",
        "single fiber electromyography",
        "Sjogren's syndrome",
        "skew deviation",
        "solitary nucleus and tract",
        "soma",
        "SSEP",
        "somesthesia",
        "somnambulism",
        "soporific",
        "spasticity",
        "spike-and-wave complex",
        "spina bifida",
        "spinal dysraphism",
        "spinocerebellar ataxia",
        "spinal accessory nerve",
        "spinocerebellar tract",
        "spinothalamic tract",
        "splenium",
        "spondylosis",
        "spongiform encephalopathy",
        "spreading cortical depression",
        "status epilepticus",
        "status migrainosus",
        "stereognosis",
        "stereotypy",
        "strabismus",
        "stria medullaris",
        "stria terminalis",
        "striate cortex",
        "striatonigral degeneration",
        "striatum",
        "stupor",
        "subacute",
        "subarachnoid hemorrhage",
        "subarachnoid space",
        "subclinical seizure",
        "subcortical",
        "subcortical leukoencephalopathy",
        "subdural hemorrhage",
        "subdural space",
        "subhyaloid hemorrhage",
        "subiculum",
        "substantia gelatinosa",
        "substantia nigra",
        "subthalamic nucleus",
        "sulcus",
        "superior cerebellar peduncle",
        "superior colliculus",
        "superior sagittal sinus",
        "supranuclear",
        "suprasellar",
        "supratentorial",
        "Sylvian fissure",
        "sympathetic nervous system",
        "symptomatic epilepsy",
        "syncope",
        "synesthesia",
        "synucleinopathies",
        "syringobulbia",
        "syringomyelia",
        "tardive",
        "tectospinal tract",
        "tectum",
        "tegmentum",
        "teichopsia",
        "telencephalon",
        "temporal lobe epilepsy",
        "tendon release",
        "tension-type headache",
        "tentorium cerebelli",
        "tetany",
        "tetraplegia",
        "thalamus",
        "tenotomy",
        "tilmus",
        "time-intensity profile",
        "tinnitus",
        "titubation",
        "Todd's paralysis",
        "tolerance",
        "Tolosa-Hunt syndrome",
        "tomacula",
        "tonic-clonic seizure",
        "tonic neck reflexes",
        "tonic seizure",
        "tonsilar herniation",
        "torticollis",
        "Tourette syndrome",
        "transcortical motor aphasia",
        "transcortical sensory aphasia",
        "transcutaneous electric nerve stimulation",
        "TENS",
        "transient ischemic attack",
        "transtentoria herniation",
        "cerebral hemispheres",
        "tentorial notch",
        "deterioration",
        "transverse myelitis",
        "trigeminal nerve",
        "trochlear nerve",
        "trigonocephaly",
        "triple flexion",
        "truncal sensory level",
        "two-point discrimination",
        "U fibers",
        "uncal herniation",
        "uncus",
        "vacuolar myelopathy",
        "vagus nerve",
        "vascular dementia",
        "vasospasm",
        "vegetative state",
        "ventral horn",
        "ventricles",
        "vergence",
        "vergence",
        "vermis",
        "version",
        "vertebral artery",
        "vestibulocochlear nerve",
        "vestibulo-ocular response",
        "vestibulospinal tract",
        "vicariation",
        "Virchow-Robin Spaces",
        "visual acuity",
        "visual evoked potentials",
        "VEPs",
        "visual fields Wallenberg syndrome",
        "Wallerian degeneration",
        "watershed infarct",
        "wearing off phenomenon",
        "Weber Syndrome",
        "wet keratin",
        "Wernicke’s aphasia",
        "West’s syndrome",
        "white matter",
        "WHO grade",
        "xanthochromia",
    ],
    nuclear: [
        "affinity",
        "ALARA",
        "alpha radiation",
        "becquerel",
        "Beta-minus",
        "Beta-plus",
        "Biological half-life",
        "brachytherapy",
        "cold kit",
        "computerized tomography",
        "contamination",
        "CT",
        "curie",
        "curietherapy",
        "decay",
        "dosimetry",
        "effective dose",
        "effective half-life",
        "EMEA",
        "equivalent dose",
        "dose equivalent",
        "external radiotherapy",
        "FDA",
        "FDG",
        "free radical",
        "galenic",
        "gamma",
        "generator",
        "GMP",
        "gray",
        "half-life",
        "incidence",
        "IND",
        "internal radiotherapy",
        "intracavity radiation",
        "intraoperative radiation",
        "ionizing",
        "irradiation",
        "isotope",
        "label",
        "labeling",
        "ligand",
        "metabolic radiotherapy",
        "MRI",
        "NDA",
        "neutron",
        "neutron therapy",
        "nuclide",
        "oncology",
        "cancerology",
        "orphan drug",
        "PET",
        "positron",
        "posology",
        "prevalence",
        "proton",
        "proton therapy",
        "rad",
        "radiation",
        "radioactive half-life",
        "radioactivity",
        "radiochemical",
        "radiochemist",
        "radiochemistry",
        "radioelement",
        "radioisotope",
        "radiologist",
        "radionuclide",
        "radiopharmaceutical",
        "radiopharmacist",
        "radiopharmacy",
        "radiophysician",
        "radiotherapist",
        "radiotherapy",
        "rem",
        "scanner",
        "scintigraphy",
        "sealed source",
        "side-effects",
        "sievert",
        "specific activity",
        "specific concentration",
        "SPECT",
        "targeted",
        "vectorized radiotherapy",
        "tomography",
        "X-rays",
    ],
    pharmaceutical: [
        "active immunity",
        "active pharmaceutical ingredient",
        "active site",
        "actual yield",
        "adsorption",
        "allergy",
        "amorphous materials",
        "amorphous solids",
        "anti-interferon",
        "antibiotic",
        "antibody",
        "antibody production",
        "antigen",
        "aseptic packaging",
        "assay development",
        "behavioural health",
        "bioassay",
        "bioavailability",
        "biobanking",
        "bioequivalency",
        "biohazard",
        "bioinformatics",
        "biological impurities",
        "biological indicators",
        "biomanufacturing",
        "biomarker",
        "biomaterials",
        "biometrics",
        "biopatents",
        "biopharmaceuticals",
        "bioprocess engineering",
        "bioprocessing",
        "bioreactor",
        "biosimilars",
        "biosynthesis",
        "biotech",
        "biotech blog",
        "biotech companies",
        "biotech conference",
        "biotech resources",
        "biotechnology",
        "biotechnology articles",
        "biotechnology books",
        "biotechnology video",
        "bulk handling",
        "CRISPR",
        "calorimetry",
        "capsule banding",
        "carcinogenicity",
        "cardiology",
        "cell culture",
        "cell lines",
        "CNS",
        "centrifugation",
        "centrifuge",
        "CMO",
        "chromatography",
        "clinical development",
        "clinical outsourcing",
        "clinical research",
        "clinical research coordinator",
        "clinical supply",
        "clinical supply chain",
        "clinical trial",
        "co-crystals",
        "coating system",
        "CRC",
        "compound libraries",
        "compound management",
        "compound screening",
        "containment",
        "contamination",
        "contract packaging",
        "crystallisation",
        "cytotoxic",
        "cytotoxicity",
        "DNA",
        "DNA array",
        "DNA sequence",
        "DNA sequencing",
        "data integrity",
        "data migration",
        "dealthcare",
        "dentistry",
        "dermatology",
        "disposable medical supplies",
        "dissolution testing",
        "distillation",
        "dosage form",
        "dosage group",
        "downstream processing",
        "drug delivery",
        "DF",
        "dry heat sterilisation",
        "ELISA",
        "efficacy",
        "elastomer",
        "electronic record",
        "electrophoresis",
        "emergency medicine",
        "enamine",
        "endocrinology",
        "endotoxin",
        "endpoint",
        "enzyme",
        "excipient",
        "FDA approval",
        "FDA compliance",
        "filtration",
        "final bulk product",
        "flow cytometry",
        "flowmeter",
        "fluorination",
        "Formulary G",
        "formulation",
        "GMP facility",
        "GMP training",
        "gastrointestinal",
        "gene",
        "gene editing",
        "gene expression",
        "gene family",
        "gene mapping",
        "gene marker",
        "gene sequencing",
        "gene silencing",
        "gene splicing",
        "gene therapy",
        "generic drugs",
        "generic pharmaceutical",
        "genetic disease",
        "genetic engineering",
        "genome project",
        "genomic library",
        "genomics",
        "genotoxicity",
        "genotype",
        "gerontology",
        "gynecology",
        "haematology",
        "halogen",
        "halogenation",
        "hazardous chemical reaction",
        "HCR",
        "hydrogenation",
        "immunoglobulin",
        "immunology",
        "in-process control",
        "in-vitro",
        "in-vivo",
        "infectious disease",
        "investigational new drug application",
        "IND",
        "kinase",
        "laboratory informatics",
        "laboratory information management system",
        "liposome",
        "localise",
        "lyophilisation",
        "lyophiliser",
        "monoclonal antibody",
        "MAB",
        "medical device",
        "metabolic diseases",
        "methods development",
        "microarrays",
        "microbial barrier",
        "molecular diagnostics",
        "musculoskeletal system",
        "mutagenesis",
        "nanotechnology",
        "national pharmacovigilance centers",
        "neonatology",
        "nephrology",
        "neurology",
        "nucleic acid",
        "OMICS",
        "obstetrics",
        "oligonucleotide",
        "oncology",
        "ophthalmology",
        "oral",
        "OSD drug",
        "otolaryngology",
        "oxidiser",
        "PAT",
        "paediatric",
        "pandemic disease",
        "parenteral",
        "patent",
        "patient safety",
        "peptides",
        "peripheral neuropathy",
        "pharmaceutical article",
        "pharmaceutical congress",
        "pharmaceutical development",
        "pharmaceutical manufacturing",
        "pharmaceutical regulatory",
        "pharmaceutical regulatory affairs",
        "pharmaceutical research",
        "pharmaceutical research and manufacturers",
        "pharmaceutical sales",
        "pharmaceutical video",
        "pharmaceuticals",
        "pharmaceutics",
        "pharmacovigilance",
        "Phase I Clinical Trials",
        "Phase II Clinical Trials",
        "Phase III Clinical Trials",
        "Phase IV Clinical Trials",
        "polymerase chain reaction",
        "polymorph",
        "polypropylene",
        "PCR",
        "preclinical studies",
        "process analytical technology",
        "process automation",
        "process monitoring",
        "process validation",
        "process validation protocol",
        "protein expression and production",
        "proteomics",
        "psychiatry",
        "pulmonary",
        "purification",
        "QA",
        "QC",
        "qualification",
        "quality assurance",
        "quality unit",
        "RNA",
        "radiology",
        "reagent",
        "recombinant antibody",
        "Recombinant DNA Techniques",
        "Recombinant DNA Technology",
        "reverse osmosis",
        "rheumatology",
        "scale-up",
        "sequencing",
        "serialisation",
        "spectrophotometry",
        "spectroscopy",
        "stability testing",
        "statistical process control",
        "sterile engineering design",
        "sterile fluid-path packaging",
        "sterilisation compatibility",
        "t-cell",
        "tamper-evident closure",
        "temperature controlled transportation",
        "therapeutics",
        "tissue culture",
        "titration",
        "toxicity",
        "toxicology",
        "trace analysis",
        "traceability",
        "track and trace",
        "upc",
        "ultrafiltration",
        "upstream processing",
        "validation protocol",
        "validation protocol",
        "vascular disease",
        "viral clearance",
        "x chromosome",
        "x-ray systems",
        "y chromosome",
        "yeast artificial chromosome",
        "yac",
        "zeta potential",
        "zoonosis",
    ],
    rehabilitation: [
        "abstract thinking",
        "activities of daily living",
        "adaptive equipment",
        "assistive equipment",
        "affect",
        "agnosia",
        "agraphia",
        "alexia",
        "ambulate",
        "amnesia",
        "anomia",
        "anosmia",
        "anoxia",
        "anterograde amnesia",
        "anticonvulsant",
        "aphasia",
        "apraxia",
        "arousal",
        "astereognosia",
        "ataxia",
        "attention",
        "atrophy",
        "alternating",
        "divided",
        "selective",
        "augmentative communication",
        "alternative communication",
        "awareness deficit",
        "balance",
        "brain injury",
        "brain stem",
        "cerebellum",
        "cerebral-spinal fluid",
        "cognition",
        "cognitive impairment",
        "cognitive rehabilitation",
        "coma",
        "community skills",
        "concentration",
        "concussion",
        "confabulation",
        "contracture",
        "convergence",
        "core therapies for brain injury",
        "diffuse axonal injury",
        "diffuse brain injury",
        "diplopia",
        "discrimination",
        "disinhibition",
        "disorientation",
        "distal",
        "dysarthria",
        "dysphagia",
        "emotional lability",
        "executive functions",
        "figure-ground",
        "visual fixation",
        "flaccid",
        "flexion",
        "frontal lobe",
        "functional outcome",
        "gait training",
        "glasgow coma scale",
        "hematoma",
        "hemiplegia",
        "hemiparesis",
        "hemorrhage",
        "ICP",
        "impairment",
        "impulse control",
        "incoordination",
        "initiative",
        "insight regarding impairment",
        "interdisciplinary approach",
        "intracranial pressure",
        "ipsilateral",
        "ischemia",
        "job analysis",
        "judgement",
        "kinesthesia",
        "lability",
        "latency of response",
        "lethargic",
        "log book",
        "memory",
        "mobility",
        "motivation",
        "motor control",
        "motor planning",
        "muscle tone",
        "neglect",
        "neurologist",
        "neuropsychologist",
        "non-ambulatory",
        "nystagmus",
        "occipital lobe",
        "OT",
        "occupational therapy",
        "orientation",
        "paraplegia",
        "parietal lobes",
        "perception",
        "perceptual- motor",
        "perseveration",
        "physiatrist",
        "physical therapist",
        "plateau",
        "position sense",
        "post traumatic amnesia",
        "PTA",
        "productive activity",
        "proprioception",
        "prosody",
        "proximal",
        "psychologist",
        "psychosocial skills",
        "ptosis",
        "quadriplegia",
        "range of motion",
        "recreation therapist",
        "rehabilitation",
        "rehabilitation team",
        "ROM",
        "scotoma",
        "secondary gain",
        "secondary insult",
        "seizure",
        "sensation",
        "sensorimotor",
        "sensory integration",
        "sequencing",
        "skull fracture",
        "spasm",
        "spasticity",
        "spatial ability",
        "speech-language pathology services",
        "spontaneous recovery",
        "strabismus",
        "tactile defensiveness",
        "tactile discrimination",
        "temporal lobes",
        "tracking",
        "tremor",
        "unilateral neglect",
        "vegetative state",
        "ventricles",
        "vestibular",
        "visual field defect",
        "visual perception",
        "vocational counseling",
        "vocational evaluation",
    ],
    renal: [
        "abdomen",
        "access",
        "acute",
        "adequacy",
        "albumin",
        "ALG",
        "altruistic donor",
        "alkali",
        "alphacalcidol",
        "amino acids",
        "anaemia",
        "ANCA",
        "angioplasty",
        "ankle oedema",
        "antibiotic drugs",
        "antibodies",
        "antigen",
        "anti-inflammatory",
        "APD",
        "arteries",
        "arteriogram",
        "artificial kidney",
        "ATG",
        "atheroma",
        "azathioprine",
        "bacteria",
        "beta-blockers",
        "bicarbonate",
        "biochemistry blood test",
        "biopsy",
        "BK virus",
        "bladder",
        "blood cells",
        "blood group",
        "blood level",
        "blood pressure",
        "blood vessels",
        "bone marrow",
        "BP",
        "brain death",
        "Bright's disease",
        "cadaveric transplant",
        "calcimimetic.",
        "calcium",
        "candida albicans",
        "CAPD",
        "cardiologist",
        "catheter",
        "CCPD",
        "cells",
        "cholesterol",
        "chronic",
        "CKD",
        "clearance",
        "CMV",
        "creatinine",
        "cross-match",
        "CT scan",
        "cycler",
        "cyclosporin",
        "cystitis",
        "cytomegalovirus",
        "DBD",
        "DCD",
        "deceased donor transplant",
        "dehydration",
        "diabetes mellitus",
        "dialyser",
        "dialysis",
        "dialysis fluid",
        "dialysis machine",
        "dialysis membrane",
        "diastolic blood pressure",
        "diffusion",
        "diuretic drugs",
        "donor kidney",
        "doppler scan",
        "EBV",
        "ECG",
        "ECHO",
        "eGFR",
        "endocrinologist",
        "end-stage renal failure",
        "end-stage renal disease",
        "established renal failure",
        "ESRF",
        "ESRD",
        "ERF",
        "EPO",
        "erythropoeitin",
        "ESA",
        "exit site",
        "exchange transplant",
        "fistula",
        "FK506",
        "fluid overload",
        "glomerulus",
        "glomerulonephritis",
        "glucose",
        "graft",
        "haemodialysis",
        "haemodialysis catheter",
        "haemodialysis unit",
        "haemoglobin",
        "Hb",
        "HDL",
        "heart-beating donor",
        "hepatitis",
        "home haemodialysis",
        "hormones",
        "HTA",
        "hyperparathyroidism",
        "hypertension",
        "PET",
        "intravenous pyelogram",
        "immune system",
        "immuno-suppressant drugs",
        "IVP",
        "kidney failure",
        "kidney machine",
        "kidney transplant",
        "KT/V",
        "LDL",
        "LFTs",
        "line infection",
        "lipids",
        "liver function tests",
        "LRT",
        "living related transplant",
        "living unrelated transplant",
        "lymphocytes",
        "malnutrition",
        "MDRD",
        "methylprednisolone",
        "microscopic polyarteritis",
        "Mmol/l",
        "molecule",
        "MRA scan",
        "MRI scan",
        "nephr",
        "nephron",
        "nephrectomy",
        "nephritis",
        "nephrology",
        "obstructive uropathy",
        "oedema",
        "OKT3",
        "organ",
        "osmosis",
        "paired transplant",
        "parathyroidectomy",
        "parathyroid glands",
        "parathyroid hormone",
        "PCKD",
        "PD",
        "PD catheter",
        "peritoneal cavity",
        "peritoneal dialysis",
        "peritoneal equilibration test",
        "peritoneum",
        "peritonitis",
        "phosphate",
        "phosphate binders",
        "plasma",
        "platelets",
        "polyarteritis",
        "polycystic kidney disease",
        "potassium",
        "prednisolone",
        "proteins",
        "PTH",
        "pulmonary oedema",
        "pyelonephritis",
        "radio-isotope scan",
        "radio-nuclide scan",
        "recipient",
        "red blood cells",
        "reflux",
        "rejection",
        "renal",
        "renal artery",
        "renal bone disease",
        "renal unit",
        "renovascular disease",
        "residual renal function",
        "rigors",
        "satellite haemodialysis unit",
        "scan",
        "semi-permeable",
        "sodium",
        "sphygmomanometer",
        "staphylococcus",
        "stent",
        "stroke",
        "subclavian vein",
        "supportive care",
        "systolic blood pressure",
        "tacrolimus",
        "tissue",
        "tissue type",
        "tissue typing",
        "toxins",
        "transplant",
        "transplantation",
        "transplant kidney",
        "transplant operation",
        "transplant waiting list",
        "tunnel infection",
        "UKM",
        "UKTSSA",
        "ULTRA",
        "ultrafiltration",
        "ultrasound scan",
        "under-dialysis",
        "urea",
        "uraemia",
        "ureters",
        "urethra",
        "urinary catheter",
        "urination",
        "urine",
        "URR",
        "vancomycin",
        "vasodilator",
        "veins",
        "virus",
        "vitamin D",
        "water tablets",
        "wegener's granulomatosis",
        "white blood cells",
        "xenotransplantation",
    ],
    respiratory: [
        "pulmonology",
        "pulmonologist",
        "alveolar",
        "bronchus",
        "bronchioles",
        "diaphragm",
        "epiglottis",
        "larynx",
        "lobe",
        "mediastinum",
        "nasal",
        "oxygen",
        "pleura",
        "pulmonary",
        "pharyngeal",
        "surfactant",
        "thorax",
        "trachea",
        "arterial blood gases",
        "bronchoscopy",
        "computed tomography",
        "MRI",
        "CT",
        "laryngoscopy",
        "lung biopsy",
        "lung scan",
        "magnetic resonance imaging",
        "pulmonary angiography",
        "oximetry",
        "PFT",
        "pulmonary function test",
        "spirometer",
        "spirometry",
        "V/Q",
        "ventilation-perfusion ratio",
        "endotracheal intubation",
        "thoracentesis",
        "thracotomy",
        "tracheostomy",
        "aspiration pneumonia",
        "asthma",
        "atelectasis",
        "bronchitis",
        "COPD",
        "chronic obstructive pulmonary disease",
        "crackles",
        "emphysema",
        "hemoptysis",
        "hemothorax",
        "hyperventilation",
        "hypoventilation",
        "hypoxia",
        "pleural effusion",
        "pleurisy",
        "pneumothorax",
        "pulmonary embolus",
        "rhonchi",
        "wheezes",
        "aclidinium bromide",
        "glycopyrronium",
        "tiotropium",
        "ipratropium bromide",
        "salbutamol",
        "terbutaline",
        "formoterol",
        "salmeterol",
        "olodaterol",
    ],
    rheumatology: [
        "abatacept",
        "adalimumab",
        "adhesion molecules",
        "adhesive capsulitis",
        "adult-onset Still’s disease",
        "aggrecanase",
        "amyloidosis",
        "anakinra",
        "angiogenesis",
        "ankylosing spondylitis",
        "ANCA",
        "antineutrophil cytoplasmic antibody",
        "antinuclear antibodies",
        "antiphospholipid antibodies",
        "antiphospholipid antibody syndrome",
        "apoptosis",
        "arteriosclerosis",
        "arthroscopy",
        "atherosclerosis",
        "autoimmune diseases",
        "autoinflammatory diseases",
        "avascular osteonecrosis",
        "axial spondyloarthropathy",
        "azathioprine",
        "Behcet’s syndrome",
        "biologic response modifier",
        "blood vessel inflammation",
        "BMI",
        "BMD",
        "bone mineral density",
        "BMPs",
        "bone morphogenetic proteins",
        "bone necrosis",
        "bone resorption",
        "bursitis",
        "calcitonin",
        "calcium pyrophosphate deposition disease",
        "CPPD",
        "canakinumab",
        "cardiovascular disease",
        "carpal tunnel syndrome",
        "cartilage matrix",
        "case-control study",
        "cell adhesion molecule",
        "certolizumab",
        "chemokines",
        "chondrocalcinosis",
        "chondrocyte",
        "chondrogenesis",
        "chronic kidney disease",
        "Churg-Strauss syndrome",
        "Clinical Disease Activity Index",
        "clinical practice guidelines",
        "cognitive dysfunction",
        "colchicine",
        "collagen",
        "comorbidity",
        "complementary and alternative medicine",
        "computed tomography",
        "CT",
        "corticosteroids",
        "COX inhibitors",
        "CRP",
        "C-reactive protein",
        "cryoglobulinemia",
        "CAPS",
        "cryopyrin-associated periodic syndromes",
        "cutaneous vasculitis",
        "cyclic citrullinated peptide antibody test",
        "cyclooxygenase",
        "cyclophosphamide",
        "cyclosporine",
        "cytokines",
        "decision analysis",
        "dendritic cells",
        "dermatomyositis",
        "diabetes mellitus",
        "digital ulcers",
        "disease-modifying antirheumatic drugs",
        "dual energy x-ray absorptiometry",
        "dual x-ray absorptiometry",
        "ehlers-danlos syndrome",
        "electromyography",
        "encephalitis",
        "end-stage renal disease",
        "epidemiology",
        "erosions",
        "erythrocyte sedimentation rate",
        "etanercept",
        "familial mediterranean fever",
        "Fc receptor",
        "fibromyalgia",
        "FRAX",
        "fracture risk assessment tool",
        "fractures",
        "gastrointestinal",
        "gene expression",
        "genomics",
        "geriatrics",
        "GCA",
        "giant cell arteritis",
        "GFR",
        "glomerular filtration rate",
        "glomerulonephritis",
        "glucocorticoid",
        "glucocorticoid-induced osteoporosis",
        "glucosamine",
        "golimumab",
        "granulomatosis with polyangiitis",
        "Wegener’s",
        "growth plate",
        "hallux valgus",
        "health risk assessment",
        "health-related quality of life",
        "hemodialysis",
        "hepatotoxicity",
        "heritability",
        "hip arthroplasty/replacement",
        "hormone replacement therapy",
        "HLA",
        "human leukocyte antigen",
        "hyaluronic acid",
        "hydroxychloroquine",
        "hypertension",
        "hyperuricemia",
        "hypocalcemia",
        "hypoxia",
        "ibandronate",
        "immunogenicity",
        "immunology",
        "inflamed blood vessels",
        "inflammatory myopathies",
        "infliximab",
        "insulin growth factor",
        "interferon",
        "interleukin",
        "interstitial lung disease",
        "joint arthroplasty/replacement",
        "joint hypermobility",
        "juvenile idiopathic arthritis",
        "juvenile rheumatoid arthritis",
        "kinase",
        "leflunomide",
        "longitudinal study",
        "low back pain",
        "lupus nephritis",
        "lyme disease",
        "macrophage",
        "macrophage activation syndrome",
        "magnetic resonance imaging",
        "mesenchymal cells",
        "meta-analysis",
        "metabolic syndrome",
        "metalloproteinase",
        "morning stiffness",
        "myopathy",
        "myositis",
        "nephritis",
        "neuroimaging",
        "neutrophils",
        "occupational therapy",
        "orthosis",
        "orthotics",
        "osteoarthritis",
        "osteoblast",
        "osteoclast",
        "osteoclastogenesis",
        "osteonecrosis",
        "osteonecrosis of the jaw",
        "osteoporosis",
        "outcome measures in rheumatology",
        "Paget’s disease",
        "parathyroid hormone",
        "patient-reported outcomes",
        "physical examination",
        "positron emission tomography",
        "prognostic factors",
        "proprioception",
        "proteoglycans",
        "proteomics",
        "psoriasis",
        "psoriatic arthritis",
        "psychometrics",
        "pulmonary arterial hypertension",
        "qualitative research",
        "radiography",
        "randomized controlled trial",
        "range of motion",
        "Raynaud’s phenomenon",
        "reactive arthritis",
        "rehabilitation",
        "rheumatoid arthritis",
        "rheumatoid factor",
        "sarcoidosis",
        "selective serotonin reuptake inhibitor",
        "Short Form 36",
        "Sjögren’s syndrome",
        "spinal stenosis",
        "spondyloarthritis",
        "Still’s disease",
        "sulfasalazine",
        "synovitis",
        "systemic lupus erythematosus",
        "systemic sclerosis",
        "systemic vasculitis",
        "T cell",
        "Takayasu’s arteritis",
        "temporomandibular joint",
        "tendinitis",
        "therapeutics",
        "tocilizumab",
        "transcription factors",
        "transforming growth factors",
        "tumor necrosis factor",
        "ultrasonography",
        "uveitis",
        "vasculitis",
        "vasculopathy",
        "visual analog scale",
        "vocational rehabilitation",
        "anti-ccp test",
        "inflammatory markers",
        "esr",
        "crp",
        "ana test",
        "antinuclear antibody test",
        "anca test",
        "anti-ena test",
        "c-reactive protein",
        "rituximab",
        "adalimumab",
        "certolizumab pegol",
        "methotrexate",
        "minocycline",
    ],
    sports: [
        "abduct",
        "achilles tendonitis",
        "acromio clavicular joint",
        "ambu bag",
        "ankle arthroscopy",
        "anterior talo-fibular ligament",
        "antioxidants",
        "arthrogram",
        "arthroscopy",
        "aspirate",
        "Bentone 38",
        "biomechanical",
        "bursa",
        "bursitis",
        "carpal tunnel syndrome",
        "cervical spine",
        "chafing",
        "chartered physiotherapist",
        "chondromalacia patella",
        "collateral ligaments",
        "colles fracture",
        "compartment syndrome",
        "concentric contraction",
        "congenital",
        "contracture",
        "contusion",
        "corticosteroid",
        "cryotherapy",
        "debridement",
        "defibrillator",
        "degenerative disc disease",
        "degenerative joint disease",
        "delayed union",
        "dermatologist",
        "diaphysis",
        "dislocation",
        "diuretic",
        "dysfunction",
        "eccentric contraction",
        "eccymosis",
        "echocardiogram",
        "effusion",
        "electrocardiogram",
        "electromygram",
        "electrotherapy",
        "epicondyle",
        "epicondylitis",
        "epiphysis",
        "ethyl chloride",
        "etiology",
        "eversion",
        "eversion ankle sprain",
        "extensor",
        "extensor tendons",
        "external bone stimulator",
        "external rotation",
        "fascia",
        "fasciotomy",
        "fibroblasts",
        "fibrocartilage",
        "fibula",
        "fine motor skills",
        "flat feet",
        "flexion",
        "flexor",
        "fluoroscopy",
        "fracture",
        "gadolinium",
        "glenohumeral",
        "glenoid",
        "glycogen",
        "golfer's elbow",
        "granulation tissue",
        "groin strain",
        "gross motor skills",
        "haemarthrosis",
        "haematoma",
        "haemorrhage",
        "hard callus",
        "heart disease",
        "hernia",
        "hindfoot",
        "hip pointer",
        "hyaline cartilage",
        "hydrotherapy",
        "hyperextension",
        "hypotonic",
        "ice therapy",
        "immobilisation",
        "impingement syndrome",
        "inflammation",
        "internal rotation",
        "interosseous membrane",
        "intramedullary",
        "intramedullary nail",
        "intramedullary screw",
        "inversion",
        "inversion ankle sprain",
        "isokinetic",
        "isometric contraction",
        "isotonic",
        "itis",
        "joint capsule",
        "joint mobilisation",
        "kyphosis",
        "labrum",
        "lanolin",
        "lateral",
        "lateral collateral ligament",
        "lesion",
        "ligament",
        "ligaments",
        "lumbago",
        "lumbar vertebra",
        "lumbo-sacral",
        "lymph",
        "lymphatic system",
        "malleolus",
        "manipulation",
        "medial",
        "meniscectomy",
        "meniscus",
        "metaphysis",
        "metatarsal",
        "metatarsalgia",
        "microtrauma",
        "motor skills",
        "MRI",
        "myositis ossificans",
        "neoprene",
        "neovascularisation",
        "neuralgia",
        "neuritis",
        "neurologist",
        "neurology",
        "neuromuscular",
        "neuropathy",
        "NSAIDs",
        "orthotic",
        "orthotic arch support",
        "orthotist",
        "Osgood Schlatters",
        "osteoarthritis",
        "osteoblasts",
        "osteochondrosis",
        "osteoclasts",
        "osteomyelitis",
        "osteoporosis",
        "overtraining syndrome",
        "overuse injury",
        "pancreas",
        "parathesia",
        "patella",
        "patella tendinopathy",
        "patella tendon",
        "patello-femoral joint",
        "patellofemoral joint",
        "patellofemoral maltracking",
        "petroleum jelly",
        "plantar fascia",
        "plantarflexion",
        "plica",
        "podiatrist",
        "popliteal fossa",
        "posterior",
        "posterior cruciate ligament",
        "postural control",
        "pronation",
        "proprioception",
        "proprioceptive",
        "PSWD",
        "Q-Angle",
        "radiograph",
        "radiographer",
        "radiography",
        "radiologist",
        "radiolucency",
        "referred pain",
        "repetitive strain injury",
        "resection",
        "rheumatoid arthritis",
        "rheumatologist",
        "rotator cuff",
        "rupture",
        "sacro-iliac joint",
        "sacrum",
        "scapula",
        "sciatica",
        "sensori-motor system",
        "shin splints",
        "side flexion",
        "soft callus",
        "sonographer",
        "spondylitis",
        "spondylolysis",
        "spondylolysthesis",
        "sterno clavicular joint",
        "stress fracture",
        "subluxation",
        "supination",
        "syndesmosis",
        "synovial fluid",
        "synovial joint",
        "synovitis",
        "tendinopathy",
        "tendonitis",
        "tennis elbow",
        "tenosynovitis",
        "TENS",
        "thigh strain",
        "tibia",
        "tibial tuberosity",
        "trachea",
        "triaxial hinge",
        "ultrasonography",
        "valgus",
        "varus",
        "vasocontriction",
        "meniscus repair",
        "microfracture",
        "cartilage repair",
        "rotator cuff repair",
        "tendon repair",
        "biceps tenodesis",
        "acl reconstruction",
        "analgesics",
        "acetaminophen",
        "exercise-induced bronchoconstriction",
        "antihypertensives",
    ],
    stroke: [
        "activities of daily living",
        "acute stroke living",
        "advocate",
        "advocacy",
        "aneurysm",
        "angiography",
        "anticoagulant",
        "antiplatelet drugs",
        "aphasia",
        "arrhythmia",
        "aspiration",
        "assistive technology",
        "atrial fibrillation",
        "barthel index",
        "bilateral",
        "cardioembolic stroke",
        "carer",
        "cardiovascular system",
        "carer’s assessment",
        "carotid arteries",
        "carotid endarterectomy",
        "catheter",
        "central post-stroke pain",
        "cerebellum",
        "cerebral cortex",
        "cerebral hemorrhage",
        "cerebrum",
        "cholesterol",
        "constraint-induced movement therapy",
        "clinical psychologist",
        "cognitive functions",
        "continence",
        "co-production",
        "CVA",
        "cerebro-vascular accident",
        "DVT",
        "deep vein thrombosis",
        "dementia",
        "diastolic pressure",
        "dietitian",
        "direct payments",
        "doppler scan",
        "drop foot",
        "dysarthria",
        "dysphasia",
        "early supported discharge",
        "echocardiogram",
        "ECG",
        "EKG",
        "electrocardiogram",
        "EEG",
        "electroencephalogram",
        "embolism",
        "emotionalism",
        "enteral feeding",
        "extracranial-intracranial bypass",
        "FAST test",
        "goal setting",
        "haematoma",
        "haemorrhage",
        "haemorrhagic stroke",
        "hemianopia",
        "hemiparesis",
        "hemiplegia",
        "Hughes syndrome",
        "hydrocephalus",
        "hyper-acute stroke unit",
        "hypercholesterolemia",
        "hypertension",
        "hypotension",
        "inattention",
        "incontinence",
        "independent advocacy organisation",
        "independent living",
        "individual budget",
        "individual service fund",
        "infarct/infarction",
        "intracerebral haemorrhage",
        "ischaemia",
        "ischaemic penumbra",
        "ischaemic stroke",
        "lacunar stroke/lacunar infarct",
        "left hemisphere",
        "MRI scan",
        "NG tube",
        "neurology",
        "neuroplasticity",
        "non-instructed advocacy",
        "nystagmus",
        "ombudsman",
        "ophthalmologist",
        "orthoptist",
        "percutaneous endoscopic gastrostomy",
        "PET scan",
        "recombinant tissue plasminogen activator",
        "rehabilitation",
        "rehab stroke unit",
        "self-directed support",
        "sickle cell anaemia",
        "statin",
        "stenosis",
        "subarachnoid haemorrhage",
        "systolic pressure",
        "thalamus",
        "thrombolysis",
        "thrombosis",
        "transcranial magnetic stimulation",
        "transient ischaemic attack",
        "vertebral artery dissection",
        "video fluoroscopy",
        "visual field loss",
    ],
    obgyn: [
        "adenomyosis",
        "adhesions",
        "amniocentesis",
        "amniotic fluid",
        "amniotic sac",
        "anaemia",
        "anaesthesia",
        "anaesthetist",
        "anal sphincter",
        "anaphylaxis",
        "antenatal",
        "anthracyclines",
        "anticoagulant medication",
        "anti-inflammatory drugs",
        "antiretroviral therapy",
        "antispasmodic drugs",
        "bacterial vaginosis",
        "womb",
        "birth asphyxia",
        "brachial plexus injury",
        "BRCA",
        "CA125",
        "caesarean delivery",
        "candida albicans",
        "CTG",
        "catheter",
        "cephalhaematoma",
        "cervical screening",
        "chignon",
        "chlamydia trachomatis",
        "cholesterol",
        "chocolate cysts",
        "chorioamnionitis",
        "chromosomal abnormality",
        "clear margins",
        "clinical guidelines",
        "colostrum",
        "colposcope",
        "colposcopistt",
        "colposcopy",
        "complementary therapy",
        "complete miscarriage",
        "complications",
        "continence",
        "contraception",
        "corticosteroids",
        "counsellor",
        "cystocele",
        "deep vein thrombosis",
        "delayed cord clamping",
        "diathermy",
        "donor insemination",
        "dysmenorrhoea",
        "dyspareunia",
        "ectopic pregnancy",
        "early miscarriage",
        "early pregnancy assessment unit",
        "eclampsia",
        "electrocoagulation",
        "embryo",
        "emergency caesarean delivery",
        "endometriosis",
        "endometritis",
        "endometrium",
        "epidural",
        "episiotomy",
        "Erb’s palsy",
        "evidence-based medicine",
        "extended or frank breech",
        "external cephalic version",
        "fallopian tubes",
        "fecundity",
        "fertilisation",
        "fertility problem",
        "fetal medicine specialist",
        "fibroids",
        "fimbriae",
        "first-degree tear",
        "flexed breech position",
        "folic acid",
        "follicle",
        "follicle-stimulating hormone",
        "FSH",
        "footling breech",
        "forceps delivery",
        "fourth-degree tear",
        "gamma globulin",
        "IgG",
        "gastroenteritis",
        "genital herpes",
        "gestation",
        "gestational age",
        "gestational diabetes",
        "GTN",
        "GTD",
        "gestational trophoblastic disease",
        "gestational trophoblastic neoplasia",
        "gonadotrophin-releasing hormone agonist",
        "gonadotrophins",
        "graduated elastic compression stocking",
        "Group B streptococcus",
        "gynaecologist",
        "haematologist",
        "haemolysis",
        "haemorrhage",
        "HELLP syndrome",
        "high-dependency unit",
        "hormone treatment",
        "HRT",
        "HIV",
        "human immunodeficiency virus",
        "HPV",
        "hyperprolactinaemia",
        "hypertension",
        "hypotension",
        "hypothalamus",
        "hysterectomy",
        "hysterosalpingo-contrast-sonography",
        "hysterosalpingogram",
        "hysteroscopy and endometrial biopsy",
        "immunotherapy",
        "implantation",
        "IVF",
        "incontinence",
        "induction of labour",
        "infectious",
        "informed decision/choice",
        "ICU",
        "intensive care unit",
        "interstitial cystitis",
        "intrapartum",
        "IUCD",
        "intrauterine insemination",
        "intrauterine system",
        "intravenous drip",
        "IV drip",
        "karyotype",
        "karyotyping",
        "ketones",
        "Klumpe’s paralysis",
        "laparoscopic ovarian drilling",
        "laxatives",
        "libido",
        "luteinising hormone",
        "Lynch Syndrome",
        "praevia",
        "medical abortion",
        "membranes",
        "meningitis",
        "menopause",
        "menstrual cycle",
        "meta-analysis",
        "midtrimester",
        "multiple pregnancy",
        "musculoskeletal",
        "neonatal unit",
        "neonatalologist",
        "neural tube defects",
        "obstetrician",
        "oedema",
        "oestrogen",
        "oligohydramnios",
        "oocyte donation",
        "ovarian hyperstimulation syndrome",
        "oxytocics",
        "pelvic congestion",
        "pelvic floor muscles",
        "pelvic inflammatory disease",
        "pelvic pain",
        "perineal tear",
        "perineum",
        "peritoneum",
        "pessaries",
        "pituitary gland",
        "placenta",
        "placenta praevia",
        "polycystic ovaries",
        "PCOS",
        "polyhydramnios",
        "postpartum",
        "postpartum haemorrhage",
        "pre-eclampsia",
        "preterm birth",
        "preterm labour",
        "preterm premature rupture of membranes",
        "progesterone",
        "progestogen",
        "prolactin",
        "prolapse",
        "prostaglandin",
        "proteinuria",
        "pudendal block",
        "pulmonary embolus",
        "rectocele",
        "recurrent miscarriage",
        "reproductive years",
        "retrovirus",
        "RhD antigen",
        "rupture of membranes",
        "sanitary pad",
        "second-degree tear",
        "severe pre-eclampsia",
        "sexually transmitted infection",
        "shoulder dystocia",
        "skin to skin contact",
        "special care baby unit",
        "speculum",
        "spina bifida",
        "spinal anaesthesia",
        "spontaneous vaginal birth",
        "sporadic",
        "sterilisation",
        "stress incontinence",
        "succenturial lobe",
        "sutures",
        "symphysis fundal height",
        "systematic review",
        "thrombophilia",
        "tachycardia",
        "testosterone",
        "third-degree tear",
        "threatened miscarriage",
        "thrombosis",
        "thrush",
        "tocolysis",
        "toxaemia",
        "transabdominal scan",
        "transvaginal scan",
        "transverse position",
        "trimester",
        "tubal occlusion",
        "ultrasound",
        "umbilical cord",
        "urethra",
        "urethracele",
        "urodynamics",
        "uterine sarcoma",
        "uterus",
        "vaginal discharge",
        "vaginal examination",
        "vaginal swab",
        "vaginal thrush",
        "varicella",
        "vas deferens",
        "vasectomy",
        "vein",
        "velamentous cord insertion",
        "venous thrombosis",
        "ventouse delivery",
        "vulva",
        "weak cervix",
        "white cell",
        "adhesiolysis",
        "cervical biopsy",
        "colporrhaphy",
        "dilation and curettage",
        "endometrial ablation",
        "uterine biopsy",
        "fluid-contrast ultrasound",
        "hysterosalpingography",
        "hysteroscopy",
        "myomectomy",
        "oophorectomy",
        "pelvic ultrasound",
        "pelviscopy",
        "salpingography",
        "toluidine blue dye test",
        "trachelectomy",
        "tubal ligation",
        "uterine fibroid embolization",
        "vulvectomy",
    ],
    paediatrics: [
        "acne",
        "air bags",
        "allergen",
        "allergic conjunctivitis",
        "allergy",
        "anorexia  ",
        "anorexia nervosa",
        "antibiotic",
        "asthma",
        "atopic dermatitis",
        "attention-deficit/hyperactivity disorder",
        "birthmark",
        "blister",
        "booster seat",
        "bruise",
        "bulimia nervosa",
        "cardiopulmonary resuscitation",
        "cephalohematoma",
        "chemical burns",
        "chickenpox",
        "child safety seat",
        "circumcision",
        "colic",
        "congenital",
        "conjunctivitis",
        "contact dermatitis",
        "cystitis",
        "deciduous teeth",
        "diaper rash",
        "diarrhea",
        "diphtheria",
        "E. coli",
        "escherichia coli",
        "electrical burns",
        "encopresis",
        "enuresis",
        "epilepsy",
        "fever",
        "pyrexia",
        "fluoride",
        "food allergy",
        "food intolerance",
        "fractures",
        "haemophilus influenzae",
        "influenzae",
        "headache",
        "heat exhaustion",
        "hepatitis",
        "immunizations",
        "vaccinations",
        "impetigo",
        "influenza",
        "flu",
        "inguinal hernia",
        "intravenous pyelogram",
        "jaundice",
        "kidney ultrasound",
        "bladder ultrasound",
        "learning disability",
        "lice",
        "locking clip",
        "measles",
        "meconium",
        "meningitis",
        "milia",
        "mongolian spots",
        "Moro reflex",
        "molding",
        "mumps",
        "myringotomy",
        "orthodontics",
        "otitis externa",
        "otitis media",
        "otoscope",
        "outer ear",
        "overuse conditions",
        "peak flow monitoring",
        "pediatrics",
        "personal flotation device",
        "pertussis",
        "whooping cough",
        "pneumatic otoscope",
        "poliomyelitis",
        "puberty",
        "pyelonephritis",
        "reye syndrome",
        "rhinitis",
        "rubella",
        "German measles",
        "sinusitis",
        "stork bite",
        "salmon patch",
        "strawberry hemangioma",
        "sudden infant death syndrome",
        "cot death",
        "sunburn",
        "sunscreen",
        "syrup of ipecac",
        "tetanus",
        "thermal burns",
        "tourette's syndrome",
        "tuberculosis",
        "TB",
        "tympanometry",
        "umbilical hernia",
        "urethritis",
        "urticaria",
        "hives",
        "vomiting",
        "X-ray",
        "nursemaid's elbow",
        "bladder catheterization",
        "incision and drainage of abscess",
        "lumbar puncture",
        "simple laceration repair",
        "cover test",
        "heart mumur exam",
        "hip exam",
        "bag-mask ventiliation",
        "herniotomy",
        "correction of undescended",
        "amoxicillin",
        "azithromycin",
        "albuterol",
        "cefdinir",
        "cephalexin",
        "fluticasone",
        "prednisolone sodium phosphate",
        "singulair",
        "trimethoprim",
        "codeine phosphate",
        "hydrocodone bitartrate",
    ],
    haematology: [
        "acute",
        "allogeneic",
        "anemia",
        "antibody",
        "anticoagulant",
        "antigen",
        "artery",
        "arterial thromboembolism",
        "autologous",
        "B lymphocyte",
        "basophil",
        "benign",
        "bleeding disorder",
        "blood",
        "blood banking",
        "blood cancer",
        "blood smear",
        "film",
        "blood transfusion",
        "bone marrow",
        "bone marrow transplantation",
        "cancer",
        "capillaries",
        "chimeric antigen receptor t-cell therapy",
        "chemotherapy",
        "chronic",
        "circulatory system",
        "clinical trial",
        "clot",
        "coagulation",
        "complete blood count",
        "congenital",
        "cord blood stem cells",
        "deep vein thrombosis",
        "erythrocyte",
        "erythropoietin",
        "factor",
        "ferritin",
        "fibrin",
        "granulocyte",
        "hematocrit",
        "hematologic malignancy",
        "hematologist",
        "hematology",
        "hematopoiesis",
        "hematopoietic stem cell",
        "hemoglobin",
        "hemoglobinopathy",
        "hemophilia",
        "idiopathic",
        "immune system",
        "intravascular hemolysis",
        "iron",
        "leukemia",
        "leukocyte",
        "lymph node",
        "lymphocyte",
        "lymphocytic",
        "lymphoma",
        "malignant",
        "monocyte",
        "myelogenous",
        "myeloma",
        "neutropenia",
        "neutrophil",
        "oncology",
        "peripheral blood",
        "peripheral blood stem cells",
        "plasma",
        "plasma cell",
        "platelet",
        "platelet count",
        "pulmonary embolism",
        "radiation therapy",
        "red blood cell",
        "sickle cell anaemia",
        "stem cell",
        "T lymphocyte",
        "targeted therapy",
        "thalassemia",
        "thrombectomy",
        "thrombocyte",
        "thrombosis",
        "thrombus",
        "transferrin",
        "tumor",
        "vein",
        "venous thromboembolism",
        "von Willebrand disease",
        "white blood cell",
        "white blood cell count",
    ],
    microbiology: [
        "animal virus",
        "antigenic drift",
        "antigenic shift",
        "antiviral drug",
        "assembly",
        "attachment",
        "bacteriophage",
        "baltimore classification",
        "base pair",
        "cap snatching",
        "capsid",
        "capsomere",
        "co-option",
        "coinfection",
        "complex",
        "cytopathic effect",
        "dalton",
        "DNA virus",
        "dsDNA virus",
        "dsDNA-RT virus",
        "dsRNA virus",
        "ecovirology",
        "emergent virus",
        "endogenous viral element",
        "EVE",
        "enveloped",
        "giant virus",
        "global virus network",
        "group-specific antigen",
        "helical",
        "helper dependent virus",
        "helper virus",
        "host",
        "host tropism",
        "human immunodeficiency virus",
        "HIV",
        "icosahedral",
        "inclusion body",
        "integrase",
        "intrinsic immunity",
        "kilobase",
        "Kb",
        "latency",
        "live virus reference strain",
        "LVRS",
        "lysogenic cycle",
        "lytic cycle",
        "maturation",
        "molecular virology",
        "multiplicity of infection",
        "MOI",
        "mycovirus",
        "nanometer",
        "nm",
        "negative-sense",
        "neurotropic virus",
        "neurovirology",
        "novel virus",
        "nucleocapsid",
        "oncovirus",
        "original antigenic sin",
        "orphan virus",
        "paleovirology",
        "parasite",
        "passenger virus",
        "penetration",
        "phenotype mixing",
        "plant virus",
        "positive-sense",
        "prolate",
        "prophage",
        "provirus",
        "pseudotyping",
        "Q-number",
        "reassortment",
        "recombinant virus",
        "release",
        "rep",
        "replication",
        "retrovirus",
        "reverse transcriptase",
        "RNA interference",
        "RNA virus",
        "rolling circle replication",
        "satellite",
        "sense",
        "serial passage",
        "slow virus",
        "ssDNA virus",
        "ssRNA-RT virus",
        "strain",
        "subviral agent",
        "superinfection",
        "synthetic virology",
        "t-number",
        "temperate",
        "tissue tropism",
        "transduction",
        "triangulation number",
        "uncoating",
        "virological failure",
        "viral culture",
        "viral disease",
        "viral dynamics",
        "viral envelope",
        "viral load",
        "viral matrix",
        "viral particle",
        "viral plaque",
        "viral protein",
        "viral shedding",
        "viral transformation",
        "viral vector",
        "viremia",
        "virion",
        "viroid",
        "viroinformatics",
        "virokine",
        "virology",
        "virome",
        "viropexis",
        "virophage",
        "viroplasm",
        "virotherapy",
        "virucide",
        "virulence",
        "virus",
        "virus attachment protein",
        "virus counter",
        "virus-like particle",
        "virusoid",
        "zygotic induction",
    ],
    psychiatry: [
        "abreaction",
        "abulia",
        "achromatopsia",
        "affect illusion",
        "akataphasia",
        "akathisia",
        "alexithymia",
        "alice in wonderland experience",
        "alliteration",
        "alogia",
        "amok",
        "anhedonia",
        "anosognosia",
        "Anton's syndrome",
        "Anwesenheit",
        "apophanous perception",
        "aphemia",
        "apperception",
        "astasia-abasia",
        "asyndesis",
        "autism",
        "autistic thinking",
        "autochthonous delusion",
        "autokabalesis",
        "automatic obedience",
        "automatism",
        "autoscopy",
        "avolition",
        "belle indifference",
        "Bouffée délirante",
        "brain fag syndrome",
        "brain fog",
        "Bruxism",
        "Capgras' syndrome",
        "catalepsy",
        "cataplexy",
        "catatonia",
        "cerea flexibilitas",
        "chorea",
        "circumstantial speech",
        "Clang association",
        "Claparede's paradox",
        "clouding of consciousness",
        "coenestopathic state",
        "confabulation",
        "conversion disorder",
        "coprolalia",
        "cotard delusion",
        "defenestration",
        "Déjà vu",
        "Déjà pensé",
        "dementia praecox",
        "dementia pugilistica",
        "derailment",
        "dereistic thinking",
        "Dermatozoenwahn",
        "dhat",
        "Doppelgänger",
        "écho de la pensée",
        "entgleisen",
        "extracampine",
        "fantasy",
        "fatuous affect",
        "flight of ideas",
        "folie à deux",
        "folie communiquée",
        "folie imposée",
        "folie induite",
        "folie simultanée",
        "Fregoli delusion",
        "Fregoli syndrome",
        "Gedankenlautwerden",
        "Gegenhalten",
        "hemiasomatognosia",
        "hyposchemazia",
        "aschemazia",
        "idée fixe",
        "ideas of alienation",
        "ideas of influence",
        "ideas of reference",
        "Jargon aphasia",
        "Klüver–Bucy syndrome",
        "Knight's Move thinking",
        "koro",
        "kuru",
        "latah",
        "l'homme qui rit",
        "logoclonia",
        "logorrhoea",
        "mania",
        "mania a potu",
        "metonymy",
        "mitgehen",
        "mitmachen",
        "moria",
        "negativism",
        "neologism",
        "omega sign",
        "oneiroid state",
        "oneirophrenia",
        "overvalued idea",
        "palilalia",
        "palinacousis",
        "palinopsia",
        "parapraxis",
        "paraprosopia",
        "paraschemazia",
        "pareidolia",
        "perseveration",
        "pfropfschizophrenie",
        "piblokto",
        "poverty of ideas",
        "pseudologia fantastica",
        "psychological pillow",
        "psychopathology",
        "Rabbit syndrome",
        "reduplicative hallucination",
        "reduplicative paramnesia",
        "reflex hallucination",
        "restlessness",
        "retardation",
        "left–right disorientation",
        "scanning speech",
        "schizophasia",
        "Schnauzkrampf",
        "sensitiver beziehungswahn",
        "Stockholm syndrome",
        "synaesthesiae",
        "telegrammatic or telegraphic speech",
        "thought blocking",
        "thought sonorization",
        "torpor",
        "tourette syndrome",
        "traumatic bonding",
        "trichotillomania",
        "TTM",
        "verbigeration",
        "verstimmung",
        "vorbeigehen",
        "vorbeireden",
        "Wahneinfall",
        "waxy flexibility",
        "waxy rigidity",
        "Windigo psychosis",
        "Witzelsucht",
        "word approximation",
        "word-salad",
        "Würgstimme",
        "zoophilia",
    ],
    neurosurgery: [
        "acoustic neuroma",
        "adenoma",
        "allograft",
        "aneurysm",
        "angiography",
        "arteriography",
        "angioplasty",
        "annulus",
        "anticoagulant",
        "aphasia",
        "arachnoid",
        "arachnoiditis",
        "arteriosclerosis",
        "arteriovenous",
        "arteriovenous malformation",
        "AVM",
        "arthrodesis",
        "astrocytoma",
        "ataxia",
        "atherosclerosis",
        "atrophy",
        "autograft",
        "benign essential blepharospasm",
        "benign essential tremor",
        "biopsy",
        "bone graft",
        "brachial plexus",
        "bradykinesia",
        "brainstem",
        "Brown-Sequard's syndrome",
        "carcinoma",
        "carotid artery",
        "carotid cavernous fistula",
        "CCF",
        "carotid sinus",
        "catheter",
        "cauda equina",
        "CES",
        "cavernous malformation",
        "cav mal",
        "central nervous system",
        "CNS",
        "cerebellum",
        "cerebrospinal fluid",
        "CSF",
        "cerebrum",
        "cervical spine",
        "Chiari malformation",
        "chorea",
        "choroid plexus",
        "coccyx",
        "coma",
        "computerized tomography",
        "concussion",
        "contrast medium",
        "coronal suture",
        "corpectomy",
        "corpus callosum",
        "cortex",
        "craniectomy",
        "craniopharyngioma",
        "cranioplasty",
        "craniosynostosis",
        "craniotomy",
        "cranium",
        "CT scan",
        "diphenylhydantoin",
        "diplopia",
        "disc",
        "disc degeneration",
        "dome",
        "doppler",
        "dura mater",
        "dysesthesia",
        "dystonias",
        "dysphasia",
        "edema",
        "electroencephalography",
        "electromyography",
        "EEG",
        "ECG",
        "EKG",
        "EMG",
        "encephalocele",
        "endarterectomy",
        "endoscope",
        "endovascular",
        "ependyma",
        "ependymoma",
        "epidural",
        "epidural hematoma",
        "epilepsy",
        "facet",
        "facet joint",
        "facetectomy",
        "falx cerebri",
        "fontanelle",
        "foramen",
        "foraminotomy",
        "fractionated therapy",
        "fusiform aneurysm",
        "gamma knife",
        "Glasgow coma scale",
        "GCS",
        "Glasgow outcome scale",
        "glia",
        "glioma",
        "glioblastoma",
        "globus pallidus",
        "greater occipital nerve",
        "hemangioma",
        "hematoma",
        "hemianopia",
        "hemiplegia",
        "hemorrhage",
        "herniated nucleus pulposus",
        "HNP",
        "hydrocephalus",
        "hydromyelia",
        "hyperesthesia",
        "hypertension",
        "hypophysectomy",
        "hypothalamus",
        "ictal",
        "instrumentation",
        "intra-arterial",
        "intra-arterial catheterization angiography",
        "intracerebral hematoma",
        "intracranial pressure",
        "ICP",
        "intravenous",
        "intrathecal",
        "internal fixation",
        "ischemia",
        "juvenile pilocytic astrocytoma",
        "jugular veins",
        "kyphoplasty",
        "lamina",
        "laminectomy",
        "laminotomy",
        "leptomeninges",
        "leptomeningitis",
        "lipoma",
        "lordosis",
        "lumbar drain",
        "lumbar puncture",
        "lumbar spine",
        "magnetic resonance angiography",
        "MRA",
        "MRI",
        "magnetic resonance imaging",
        "median nerve",
        "medulloblastoma",
        "meninges",
        "meningioma",
        "meningitis",
        "meningocele",
        "meningoencephalitis",
        "meningoencephalocele",
        "minimally invasive",
        "myelogram",
        "myelopathy",
        "myelomeningocele",
        "myopathy",
        "nerve root",
        "neuralgia",
        "neurectomy",
        "neuritis",
        "neuroblastoma",
        "neurofibroma",
        "neurofibromatosis",
        "neurolysis",
        "neuroma",
        "neuropathy",
        "nucleus",
        "nystagmus",
        "oocciput",
        "oligodendroglia",
        "oligodendroglioma",
        "orbital",
        "osteoma",
        "osteomyelitis",
        "osteophytectomy",
        "papilledema",
        "paraplegia",
        "paresthesia",
        "pars interarticularis",
        "pedicle",
        "peripheral nervous system",
        "pituitary",
        "polyneuritis",
        "posterior fossa",
        "pre-ictal",
        "proprioception",
        "pseudarthrosis",
        "pseudomeningocele",
        "pupil",
        "quadriplegia",
        "radiation oncologist",
        "radiation physicist",
        "radiculitis",
        "radiculopathy",
        "radiologist",
        "radiotherapy",
        "reflex sympathetic dystrophy",
        "revascularization",
        "saccular aneurysm",
        "sciatic nerve",
        "seizure",
        "shunt",
        "spasmodic torticollis",
        "spina bifida",
        "spinal fusion",
        "spinal instability",
        "spinal tap",
        "spinous process",
        "spondylolisthesis",
        "stenosis",
        "stent",
        "stereotactic surgery",
        "subarachnoid",
        "subarachnoid hemorrhage",
        "subdural",
        "subdural hematoma",
        "suprascapular nerve",
        "syringomyelia",
        "teratoma",
        "thalamus",
        "thrombolytic agent",
        "thrombus",
        "tic douloureux",
        "torticollis",
        "transsphenoidal approach",
        "transverse process",
        "tremor",
        "trigeminal nerve",
        "trigeminal neuralgia",
        "ultrasound",
        "upper motor cortex",
        "vascular",
        "vasodilatation",
        "vasopressin",
        "vasopressor",
        "vasospasm",
        "venous",
        "ventricle",
        "ventricular drainage",
        "ventriculitis",
        "ventriculostomy",
        "ventriculo-atrial",
        "ventriculogram",
        "ventriculomegaly",
        "ventriculo-peritoneal",
        "vermis",
        "vertebra",
        "vertebroplasty",
        "vertigo",
        "white matter",
        "WHO tumor grading",
        "Grade I",
        "Grade II",
        "Grade III",
        "Grade IV",
        "X-ray",
    ],
    maxfax: [
        "alveolar bone",
        "anaesthesia",
        "anaesthesia of the skin",
        "apex",
        "attenuation",
        "blepharoplasty",
        "canthus",
        "cervical spine",
        "crepitus",
        "crown",
        "dysaesthesia",
        "enophthalmos",
        "epiphora",
        "facial nerve",
        "gingiva",
        "globe",
        "haematoma",
        "herniation",
        "hyperaesthesia",
        "impaction",
        "infra-orbital",
        "interincisal opening",
        "intermaxillary fixation",
        "interproximal",
        "labial",
        "length of stay",
        "malar",
        "mandible",
        "maxilla",
        "nasal septum",
        "occlusion",
        "orbit",
        "paraesthesia",
        "pericoronitis",
        "pinna",
        "prognosis",
        "pulp",
        "reduction",
        "retractor",
        "sublux",
        "supra-orbital",
        "temporomandibular joint",
        "traumatic anthropathy",
        "trigeminal neuralgia",
        "trismus",
        "wisdom teeth",
        "X-ray",
        "zygoma",
    ],
    otorhinolaryngology: [
        "assistive devices",
        "audiologist",
        "audiology",
        "auditory brainstem response test",
        "ABR test",
        "auditory nerve",
        "autism",
        "autoimmune deafness",
        "benign",
        "biopsy",
        "blasts",
        "bone marrow",
        "bone marrow aspiration and biopsy",
        "cancer care team",
        "cancer cell",
        "carcinogen",
        "chemotherapy",
        "chromosome",
        "chronic myelogenous leukemia",
        "CML",
        "clinical trial",
        "cochlea",
        "cochlear implant",
        "complete blood count",
        "CBC",
        "complementary therapy",
        "computed tomography scan",
        "CAT scan",
        "conductive hearing impairment",
        "congenital",
        "constrict",
        "cytomegalovirus",
        "decibel",
        "dizziness",
        "ear infection",
        "earwax",
        "edema",
        "endolymph",
        "Eustachian tube",
        "grade",
        "hair cells",
        "hearing",
        "hearing aid",
        "hearing disorder",
        "hemoglobin",
        "Herpes virus",
        "hoarseness",
        "Hodgkin lymphoma",
        "inflammation",
        "inner ear",
        "labyrinth",
        "language disorders",
        "laryngitis",
        "larynx",
        "lymph",
        "lymph nodes",
        "lymph vessels",
        "lymphangiogram",
        "LAG",
        "lymphatic system",
        "lymphocytes",
        "malignant",
        "mastoid",
        "medical oncologist",
        "meningitis",
        "metastasis",
        "middle ear",
        "myringotomy",
        "nasal polyp",
        "noise-induced hearing loss",
        "Non-Hodgkin lymphoma",
        "oncologist",
        "oncology",
        "oncology clinical nurse specialist",
        "oncology social worker",
        "otitis externa",
        "otitis media",
        "otoacoustic emissions",
        "otolaryngologist",
        "otologist",
        "outer ear",
        "pain specialist",
        "pathologist",
        "pediatric oncologist",
        "pediatrician",
        "pharynx",
        "phonology",
        "posterior",
        "primary site",
        "prognosis",
        "protocol",
        "purulent",
        "radiation oncologist",
        "radiation therapist",
        "radiation therapy",
        "radiologist",
        "round window",
        "sensorineural hearing loss",
        "sign language",
        "sound vocalization",
        "speech disorder",
        "speech-language pathologist",
        "staging",
        "stuttering",
        "sudden deafness",
        "suppurative",
        "syphilis",
        "throat culture",
        "throat disorders",
        "toxoplasmosis",
        "transillumination",
        "tumor",
        "tympanic membrane",
        "eardrum",
        "tympanoplasty",
        "ultrasound",
        "sonography",
        "vestibule",
        "vocal cords",
        "X-ray",
        "tympanostomy",
        "myringoplasty",
        "ossicular chain reconstruction",
        "stapedectomy",
        "laryngoscopy",
        "microlaryngoscopy",
        "esophagoscopy",
        "laryngoplasty",
        "septoplasty",
        "nasal valve reconstruction",
        "functional endoscopic sinus surgery",
        "FESS",
        "rhinoplasty",
        "neck mass biopsy",
        "parotidectomy",
        "submandibular gland excision",
        "ear tubes",
        "tonsillectomy",
        "adenoidectomy",
        "parathyroidectomy",
        "thyroidectomy",
        "lymph node excision",
        "beclometasone",
        "benzydamine",
        "betahistine",
        "betamethasone",
        "budesonide",
        "cinnarizine",
        "clotrimazole",
        "cromolyn",
        "dexamethasone",
        "ephedrine",
        "flumetasone",
        "flurbiprofen lozenges",
        "fluticasone",
        "gentamicin",
        "hydrocortisone",
        "ipratropium",
        "miconazole",
        "mometasone",
        "mupirocin",
        "nystatin",
        "pilocarpine",
        "prednisolone",
        "sodium bicarbonate",
        "triamcinolone",
        "xylometazoline",
    ],
    traumaortho: [
        "abduction",
        "acetabular",
        "acetabular cup",
        "acetabulum",
        "ACL",
        "ACI",
        "ACT",
        "acrylic",
        "acupuncture",
        "adduction",
        "allodynia",
        "algodystrophy",
        "allograft",
        "anaerobic",
        "anaesthesia",
        "anastomosis",
        "anatomical position",
        "anatomical reduction",
        "angular stability",
        "angulation",
        "ankylosis",
        "antalgic",
        "anterior",
        "antibiotic",
        "antigen",
        "anti-inflammatories",
        "arthritis",
        "arthrodesis",
        "arthroplasty",
        "arthroscopy",
        "pure split",
        "pure depression",
        "split-depression",
        "multifragmentary depression",
        "articular fracture",
        "atrophic non-union",
        "autograft",
        "autologous",
        "avascular",
        "avascular necrosis",
        "avulsion",
        "bactericidal",
        "baker’s cyst",
        "biocompatibility",
        "biological internal fixation",
        "biopsy",
        "blood supply to cortical bone",
        "bone graft",
        "bone resorption",
        "broad spectrum",
        "bursa",
        "bursitis",
        "buttress",
        "callus",
        "cancellous bone",
        "capillaries",
        "cartilage",
        "catheter",
        "caudad",
        "caudal",
        "causalgia",
        "cephalad",
        "chemotherapy",
        "chondrocytes",
        "chondral",
        "cis–cortex",
        "comminution",
        "compartment syndrome",
        "complex fracture",
        "Complex Regional Pain Syndrome",
        "CRPS",
        "compound fracture",
        "compression screw",
        "compression",
        "condyle",
        "contact healing",
        "Continuous Passive Motion",
        "contra-indications",
        "coronal",
        "cortex",
        "cortical bone",
        "corticotomy",
        "CPM",
        "creeping substitution",
        "cytoplasm",
        "débridement",
        "deep vein thrombosis",
        "deformity",
        "degenerative joint disease",
        "delayed union",
        "Dial test",
        "diaphysis",
        "direct healing",
        "dislocation",
        "distal",
        "dorsal",
        "ductility",
        "dynamisation",
        "dysplastic",
        "ECM",
        "effusion",
        "elastic deformation",
        "embolism",
        "endosteal",
        "energy transfer",
        "epidural anaesthetic",
        "epiphysis",
        "epoxy",
        "erosion",
        "escherichia coli",
        "excision arthoplasty",
        "exostosis",
        "extensor",
        "extension",
        "extensor mechanism",
        "extra-articular fracture",
        "far cortex",
        "trans cortex",
        "fascio–cutaneous",
        "fasciotomy",
        "femoral",
        "fibrocartilage",
        "fixation",
        "flexion",
        "flexor",
        "floating knee",
        "fracture",
        "fracture disease",
        "extra-articular",
        "fracture locus",
        "multifragmentary",
        "complex",
        "fracture, simple",
        "frontal",
        "femur",
        "fusion",
        "Galeazzi injury",
        "gap healing",
        "general anaesthesia",
        "gliding hole",
        "gliding splint",
        "goal of fracture treatment",
        "greater trochanter",
        "haemarthrosis",
        "haematogenous",
        "haversian system",
        "Hawkin’s test",
        "heparin",
        "heterograft",
        "homeopathy",
        "homograft",
        "horizontal",
        "hybrid hip replacement",
        "hydroxy-apatite",
        "hypertrophic non-union",
        "hypnotherapy",
        "hypovolaemia",
        "hypoxia",
        "idiopathic",
        "impacted fracture",
        "impaction bone grafting",
        "indirect healing",
        "inferior",
        "inoculation",
        "interfragmentary compression",
        "interposition arthroplasty",
        "intra-articular",
        "intramedullary nail",
        "intra-osseous",
        "intravenous",
        "ischaemia",
        "joint",
        "kinetic energy",
        "kyphosis",
        "lag screw technique",
        "Lachman test",
        "lateral",
        "laxity",
        "LCL",
        "ligamentous tissue",
        "ligaments",
        "local anaesthesia",
        "locking plate",
        "locking head screw",
        "low friction arthroplasty",
        "low molecular heparin",
        "lymphoedema",
        "malunion",
        "matrix",
        "MCL",
        "McMurray's test",
        "medial",
        "meniscus",
        "metaphysis",
        "methylmethacrylate",
        "microfracture",
        "microvascular",
        "midline",
        "minimally invasive plate osteosynthesis",
        "MIPO",
        "monteggia injury",
        "mosaicplasty",
        "MRI",
        "muscle compartment",
        "multifragmentary fracture",
        "muscle compartment syndrome",
        "near cortex",
        "neurovascular",
        "neutralization",
        "non-steroidal inflammatory drugs",
        "nonunion",
        "non-steroidal anti-inflammatory drugs",
        "NSAID",
        "open fracture",
        "opposition",
        "ORIF",
        "orthopaedic",
        "orthopaedic surgery",
        "osteoarthritis",
        "osteoarthrosis",
        "osteoblast",
        "osteoblastic",
        "osteochondral",
        "OAT",
        "OATS",
        "osteochondritis dissecans",
        "OCD",
        "osteoclast",
        "osteolysis",
        "osteolytic",
        "osteomyelitis",
        "osteone",
        "osteopaenia",
        "osteopathy",
        "osteoporosis",
        "osteosynthesis",
        "osteotomy",
        "palmar",
        "paresis",
        "paraesthesia",
        "passive movement",
        "patella",
        "patella alta",
        "patella infera",
        "patellofemoral joint",
        "PFJ",
        "pathological fracture",
        "PCL",
        "periosteal",
        "periosteum",
        "pilon",
        "pilot hole",
        "pin loosening",
        "PKR",
        "plafond",
        "plantar",
        "plastic deformation",
        "PLC",
        "polytrauma",
        "popliteal fossa",
        "porous ingrowth",
        "posterior",
        "prebending of plate",
        "precise reduction",
        "preload",
        "primary arthroplasty",
        "pronation",
        "prophylactic",
        "prophylaxis",
        "proprioception",
        "prostheses",
        "prosthesis",
        "protection",
        "proximal",
        "pseudarthrosis",
        "pseudocapsule",
        "pseudomonas aeruginosa",
        "psoriasis",
        "pulmonary embolus",
        "quadriceps muscles",
        "radial preload",
        "radiological examination",
        "radiotherapy",
        "recurvatum",
        "reduction",
        "reflex sympathetic dystrophy",
        "refracture",
        "relative stability",
        "remodelling",
        "resorption",
        "rheumatoid arthritis",
        "retropatellar",
        "rigid fixation",
        "rigid implants",
        "rigidity",
        "ROM",
        "rotator cuff",
        "sagittal",
        "scarf test",
        "scoliosis",
        "second look",
        "segmental",
        "self-curing",
        "sequestrum",
        "sigmoidoscopy",
        "simple fracture",
        "SLR",
        "spinal anaesthetic",
        "split depression",
        "spondylolisthesis",
        "spondylolysis",
        "spontaneous fracture",
        "spontaneous healing",
        "stability of fixation",
        "stable fixation",
        "staphylococcus",
        "staphylococcus aureus",
        "staphyloccus epidermis",
        "strain",
        "stress protection",
        "stress riser",
        "stress shielding",
        "subchondral",
        "subcutaneous",
        "subcutaneous injections",
        "subluxation",
        "subsidence",
        "Sudeck’s atrophy",
        "superior",
        "supination",
        "synovectomy",
        "synovial membrane",
        "synovitis",
        "synovial fluid",
        "systemic",
        "tendinitis",
        "tendinosis",
        "tension band",
        "threaded hole",
        "tibia",
        "tibial intercondylar eminence",
        "tibial spine",
        "Thessaly test",
        "TKR",
        "torus",
        "total hip arthroplasty",
        "toxins",
        "trabecula",
        "tracheostomy",
        "tract",
        "trans-cortex",
        "transverse",
        "trochanteric bursitis",
        "trochlea",
        "UKR",
        "unicondylar knee replacement",
        "union",
        "valgus",
        "varus",
        "vascular injuries",
        "vascularity",
        "venogram",
        "vertical",
        "VMO",
        "warfarin",
        "wave plate",
        "wedge fracture",
        "working length",
        "xenograft",
        "Yergunson’s test",
        "zone of injury",
        "meniscectomy",
        "acl reconstruction surgery",
        "knee replacement surgery",
        "shoulder replacement surgery",
        "rotator cuff repair",
        "hip replacement surgery",
        "trigger finger release",
        "closed fracture",
        "diaphyseal",
        "metaphyseal",
        "epiphyseal",
        "shaft",
        "comminuted",
        "butterfly fragment",
        "reduction",
        "internal fixation",
        "percutaneous fixation",
        "intramedullary fixation",
        "intramedullary nailing",
        "circular frame ",
        "radiograph",
        "nerve conduction",
        "Jobe test",
        "Impingment test",
        "Speeds test",
        "O'briens test",
        "snuffbox",
        "radiocapitellar ",
        "Watson test",
        "scaphoid shift test",
        "Ballottment test",
        "Elson's test",
        "Tinel's test",
        "Phalen's test",
        "Trendelenburg test",
        "anterior draw test",
        "posterior draw test",
        "passive midfoot test",
        "dorsiflexion",
        "plantarflexion",
        "palmarflexion",
    ],
    urology: [
        "abacterial cystitis",
        "active monitoring",
        "adenocarcinoma",
        "alpha-blocker",
        "amino-aciduria",
        "analgesic",
        "angiomyolipoma",
        "antiandrogen",
        "antibiotic",
        "anti-inflammatory",
        "NSAID",
        "assisted conception",
        "autologous",
        "azoospermia",
        "balanitis",
        "balanitis xerotica obliterans",
        "BXO",
        "BCG",
        "benign prostatic hyperplasia",
        "BPH",
        "biopsy",
        "TCC",
        "bladder cancer",
        "bladder instability",
        "bladder training",
        "brachytherapy",
        "calcium oxalate",
        "calculus",
        "calyx",
        "castration",
        "catheter",
        "chemotherapy",
        "chordee",
        "chronic retention of urine",
        "circumcision",
        "Clavien classification",
        "colposuspension",
        "co-morbidity",
        "computerised tomography",
        "CT",
        "corpora cavernosa",
        "creatinine",
        "cryotherapy",
        "cystectomy",
        "cystinuria",
        "cystitis",
        "cystocele",
        "cystogram",
        "cystometrogram",
        "CMG",
        "cystoscopy",
        "cytology",
        "cytoreductive surgery",
        "debulking",
        "detrusor muscle",
        "dialysis",
        "digital rectal examination",
        "DRE",
        "dilatation",
        "dimethylsulfoxide",
        "DMSO",
        "diverticulum",
        "dribbling",
        "dysuria",
        "ectopic",
        "embolisation",
        "endourology",
        "enterocele",
        "enuresis",
        "epididymis",
        "epididymitis",
        "erectile deformity",
        "erectile dysfunction",
        "MED",
        "erection",
        "exstrophy",
        "ectopia vesicae",
        "extracorporeal shockwave lithotripsy",
        "ESWL",
        "extravasation",
        "Fournier's gangrene",
        "frenulum",
        "frequency",
        "Gleason grade",
        "green light laser",
        "PVP",
        "haematospermia",
        "haematuria",
        "haemodialysis",
        "haemoglobin",
        "hesitancy",
        "high intensity focussed ultrasound",
        "HIFU",
        "high-dependency unit",
        "HDU",
        "histology",
        "histopathology",
        "horseshoe kidney",
        "hydrocele",
        "hydronephrosis",
        "hypospadias",
        "immunotherapy",
        "impotence",
        "in-vitro fertilisation",
        "IVF",
        "incontinence",
        "inferior vena cava",
        "infertility",
        "intensive therapy unit",
        "ITU",
        "ICU",
        "international prostate symptom score",
        "I-PSS",
        "interstitial cystitis",
        "intravenous urogram",
        "IVU",
        "irrigation",
        "juxta-glomerular apparatus",
        "JGA",
        "kidney transplant",
        "laparoscope",
        "litholapaxy",
        "lithotripsy",
        "lymphadenectomy",
        "magnetic resonance imaging",
        "MRI",
        "mesh",
        "metastasis",
        "micturition",
        "morbidity",
        "mortality rate",
        "multi-disciplinary team",
        "MDT",
        "Nd-YAG",
        "necrotising fasciitis",
        "nephrectomy",
        "nephroblastoma",
        "nephron",
        "nephro-ureterectomy",
        "nocturia",
        "non-seminomatous germ cell tumour",
        "NSGCT",
        "nuclear magentic resonance",
        "NMR",
        "NMR spectroscopy",
        "orchidectomy",
        "orchitis",
        "overactive bladder",
        "OAB",
        "overflow incontinence",
        "paraphimosis",
        "partial nephrectomy",
        "percutaneous nephrolithotomy",
        "PCNL",
        "peritoneal dialysis",
        "PD",
        "Peyronie's disease",
        "phimosis",
        "photoselective vaporisation of prostate",
        "plasma cell balanitis",
        "polydipsia",
        "polyuria",
        "positron emission tomography",
        "PET",
        "priapism",
        "prostate",
        "prostatectomy",
        "prostate-specific antigen",
        "PSA",
        "prostatitis",
        "prosthesis",
        "pyelitis",
        "quality of life score",
        "QoL score",
        "radio frequency ablation",
        "RFA",
        "radio-isotope scanning",
        "rectocele",
        "renal colic",
        "scintigraphy",
        "seminal vesicle",
        "seminoma",
        "shunt",
        "sphincter",
        "spina bifida",
        "stent",
        "sterility",
        "stress urinary incontinence",
        "SUI",
        "stricture",
        "struvite",
        "triple phosphate",
        "symptom score",
        "teratoma",
        "testicular cancer",
        "testosterone",
        "thermotherapy",
        "TNM",
        "transfusion",
        "transfusion rate",
        "transitional cell carcinoma",
        "transplantation",
        "transurethral resection",
        "TUR",
        "trial without catheter",
        "TWOC",
        "tumescence",
        "ultrasound",
        "urachus",
        "ureter",
        "ureteric colic",
        "ureteroscopy",
        "URS",
        "urethra",
        "urgency",
        "uric acid",
        "urodynamics",
        "varicocele",
        "vas deferens",
        "vascular access",
        "vasography",
        "voiding",
        "VHL",
        "washouts",
        "Von Hippel Lindau disease",
        "watchful waiting",
        "Wilm's tumour",
        "X-ray",
        "YAG",
        "Yttrium Aluminium Garnet",
        "Zoon's balanitis",
        "pyeloplasty",
        "ureteral reimplants",
        "ureteral stent placement",
        "excision hydrocele",
        "inguinal hernia repair",
        "meatoplasty",
        "orchiopexy",
        "scrotoplasty",
        "detrusitol",
        "finasteride",
        "oxybutanin",
        "regurin",
        "fesoterodine",
        "solifenacin",
        "tamsulosin",
        "avodart",
        "alfusosin",
        "dutasteride",
        "vasectomy",
        "hydrosoele repair",
        "laser stone fragmentation",
        "epididymal cyst excision",
        "renogram",
        "Mag 3",
        "DMSA",
        "haemostasis",
    ],
    vascular: [
        "abdominal aortic aneurysm",
        "AAA",
        "amputation",
        "amurosis fugax",
        "aneurysm",
        "angiogram",
        "angioplasty",
        "ankle brachial index",
        "ABI",
        "antiplatelet",
        "aorta",
        "aortic bypass",
        "aortic occlusive disease",
        "aortic stent grafting",
        "aortic surgery",
        "arteriogram",
        "arteriovenous fistula",
        "AV fistula",
        "arteriovenous graft",
        "aspirin",
        "atherosclerosis",
        "blue toe syndrome",
        "Buerger's disease",
        "brachial artery",
        "bypass",
        "calcified vessels",
        "carotid artery",
        "catheter",
        "celiac artery",
        "cellulitis",
        "cerebral vascular accidents",
        "CVI",
        "CVA",
        "chronic venous insufficiency",
        "claudication",
        "collateral circulation",
        "compression therapy",
        "contrast dye",
        "coumadin",
        "deep vein thrombosis",
        "DVT",
        "diabetes mellitus",
        "diabetic foot ulcer",
        "diabetic neuropathy",
        "dialysis",
        "dialysis catheters",
        "digital gangrene",
        "doppler",
        "edema",
        "endarterectomy",
        "endograft",
        "endovascular",
        "false aneurysm",
        "femoral artery",
        "fibromuscular dysplasia",
        "FMD",
        "graft",
        "gangrene",
        "hemodialysis",
        "homocysteine",
        "hypertension",
        "iliac artery",
        "Insulin",
        "intermittent claudication",
        "ischemia",
        "jugular vein",
        "kidney failure",
        "laser",
        "leg bypass",
        "leg ulcers",
        "lipids",
        "lipoproteins",
        "lymphedema",
        "mesenteric artery",
        "mesenteric ischemia",
        "mini-stroke",
        "noninvasive",
        "percutaneous access",
        "peripheral arterial disease",
        "PAD",
        "peritoneal dialysis",
        "phlebitis",
        "plaque",
        "popliteal artery",
        "post phlebitic syndrome",
        "pulmonary embolism",
        "Raynaud's syndrome",
        "renal artery",
        "renal artery stenosis",
        "renal failure",
        "renal vascular disease",
        "rest pain",
        "revascularization",
        "ruptured aneurysm",
        "saphenous ablation",
        "sclerotherapy",
        "signs of infection",
        "spider vein",
        "stasis dermatitis",
        "stasis ulcer",
        "stenosis",
        "stents",
        "stroke",
        "subclavian artery",
        "superficial phlebitis",
        "swelling",
        "thoracic aortic aneurysm",
        "thoracic outlet syndrome",
        "thrombolysis",
        "thrombosis",
        "transient ischemic attack",
        "TIA",
        "triglycerides",
        "ultrasonic duplex scanning",
        "ultrasound test",
        "Unna boot",
        "varicose veins",
        "vascular medicine",
        "vein stripping",
        "veins",
        "venous insufficiency",
        "vertebral artery",
        "vessels",
        "VNUS closure",
        "endovascular aneurysm repair",
        "thrombectomy",
    ],
    breast: [
        "abdomen",
        "AC chemotherapy",
        "abemaciclib",
        "ablation",
        "abraxane",
        "adriamycin",
        "doxorubicin",
        "adjuvant treatment",
        "advanced breast cancer",
        "adverse effect",
        "alternative therapy",
        "anaemia",
        "anastrozole",
        "anthracyclines",
        "anti-emetics",
        "areola",
        "arimidex",
        "aromasin",
        "aromatase inhibitors",
        "ascites",
        "axilla",
        "axillary clearance",
        "axillary sampling",
        "benign",
        "bevacizumab",
        "bilateral",
        "biological therapies",
        "biopsy",
        "biosimilars",
        "bisphosphonates",
        "blood cells",
        "blood count",
        "bone marrow",
        "bone metastases",
        "bone scan",
        "brain metastases",
        "BRCA1",
        "BRCA2",
        "breast calcification",
        "breast care nurse",
        "breast-conserving surgery",
        "breast density",
        "breast reconstruction",
        "breasts",
        "cannula",
        "capecitabine",
        "carboplatin",
        "carcinoma",
        "cardiotoxicity",
        "CDK inhibitors",
        "cell proliferation",
        "cells",
        "cellulitis",
        "chemoprevention",
        "chemotherapy",
        "chest wall",
        "chronic",
        "CISH",
        "chromogenic in situ hybridization",
        "cisplatin",
        "clinical trials research",
        "CMF",
        "cognitive impairment",
        "complementary therapies",
        "contralateral",
        "cording",
        "axillary web syndrome",
        "core biopsy",
        "CT scan",
        "computerised tomography scan",
        "cyberknife",
        "cyclophosphomide",
        "DCIS",
        "ductal carcinoma in situ",
        "D-DISH",
        "denosumab",
        "prolia",
        "Xgeva",
        "DEXA scan",
        "dual energy x-ray absorptiometry scan",
        "diagnostic radiographer",
        "DIEP flap",
        "deep inferior epigastic perforator flap",
        "differentiation",
        "docetaxel",
        "drug resistance",
        "ECHO",
        "echocardiogram",
        "EGFR",
        "epidermal growth factor receptor",
        "embolism",
        "encapsulated",
        "endocrine therapy see",
        "endometrial cancer",
        "epirubicin",
        "eribulin",
        "ER status",
        "everolimus",
        "excision",
        "exemestane",
        "expander implant",
        "faslodex",
        "FEC",
        "FEC-T",
        "femara",
        "fibrocystic",
        "filgrastim",
        "fine needle aspiration",
        "FNA",
        "FISH",
        "fluorescence in situ hybridisation",
        "fluorouracil",
        "fraction",
        "fulvestrant",
        "gamma knife",
        "GCSF",
        "granulocyte-colony stimulating factor",
        "gemcitabine",
        "gemzar",
        "gene",
        "goserelin",
        "grade",
        "HER2",
        "human epidermal growth factor receptor 2",
        "herceptin",
        "hereditary",
        "Hickman line",
        "skin-tunnelled catheter",
        "hormone receptor",
        "hormone therapy",
        "endocrine therapy",
        "hormones",
        "HRT",
        "hormone replacement therapy",
        "hypercalcaemia",
        "hyperplasia",
        "hypocalcaemia",
        "IHC",
        "immunohistochemistry",
        "imaging",
        "immunosuppression",
        "immunotherapy",
        "incidence",
        "in situ",
        "breast cancer",
        "infertility",
        "inflammation",
        "infusion",
        "intraductal",
        "intramuscular",
        "IM",
        "intravenous",
        "IV",
        "invasive cancer",
        "ipsilateral",
        "kadcyla",
        "Ki67",
        "lapatinib",
        "LD flap",
        "letrozole",
        "local recurrence",
        "local treatment",
        "locally advanced breast cancer",
        "LABC",
        "lumpectomy",
        "lymph nodes",
        "lymphatic system",
        "lympho-vascular invasion",
        "lymphoedema",
        "malignant cancer",
        "mammogram",
        "mastectomy",
        "metastases",
        "methotrexate",
        "microcalcifications",
        "MRI scan",
        "magnetic resonance imaging scan",
        "MUGA",
        "multiple-gated acquisition",
        "multi-centric",
        "multi-focal",
        "navelbine",
        "neo-adjuvant",
        "neupogen",
        "neutropenia",
        "occult breast cancer",
        "oestrogen receptors",
        "oligometastatic disease",
        "oncologist",
        "oncoplastic surgeon",
        "OSNA",
        "one step nucleic acid amplification",
        "osteopenia",
        "osteoporosis",
        "ovarian suppression",
        "paclitaxel",
        "palbociclib",
        "palliative care",
        "palliative care consultant",
        "palliative care nurse",
        "palliative treatment",
        "PARP inhibitors",
        "poly-ADP ribose polymerase inhibitors",
        "pathology",
        "peripherally inserted central catheter",
        "PICC",
        "perjeta",
        "pertuzumab",
        "PET scan",
        "positron emission tomography scan",
        "plastic surgeon",
        "portacath",
        "primary breast cancer",
        "progression",
        "progesterone",
        "progesterone receptors",
        "prognosis",
        "prosthesis",
        "quality of life",
        "radiologist",
        "radiotherapy",
        "reconstruction",
        "recurrence",
        "remission",
        "ribociclib",
        "risk factor",
        "saline implant",
        "secondary breast cancer",
        "selective internal radiation therapy",
        "SIRT",
        "sentinel lymph node biopsy",
        "SLNB",
        "seroma",
        "SGAP flap",
        "super gluteal artery perforator flap",
        "IGAP flap",
        "inferior gluteal artery perforator flap",
        "side effect",
        "silicone implant",
        "spinal cord compression",
        "stable disease",
        "stage",
        "stereotactic radiotherapy",
        "steroids",
        "subcutaneous injection",
        "supportive care",
        "surgical margin",
        "systemic treatment",
        "tamoxifen",
        "taxol",
        "taxotere",
        "TENS machine",
        "terminal A",
        "thrombosis",
        "tissue bank",
        "TP53 gene",
        "TRAM flap",
        "transverse rectus abdominis muscle flap",
        "trastuzumab emtansine",
        "triple assessment",
        "triple negative breast cancer",
        "oestrogen receptor negative",
        "progesterone receptor negative",
        "TUG flap",
        "transverse upper gracilis flap",
        "TMG flap",
        "transverse myocutaneus gracilis flap",
        "tumour",
        "tumour markers",
        "tyverb",
        "ultrasound scan",
        "vacuum assisted biopsy",
        "verzenio",
        "vinorelbine",
        "wide local excision",
        "WLE",
        "X-ray",
        "xeloda",
        "zoladex",
        "anastrazole",
        "cyclophosphamide",
        "doxorubicin hydrochloride",
        "epirubicin hydrochloride",
        "eribulin mesylate",
        "trastuzumab",
        "neratinib",
        "toremifene",
    ],
    endocrine: [
        "endocrinology",
        "endocrinologist",
        "adrenal glands",
        "aldosterone",
        "antidiuretic hormone",
        "catecholamines",
        "corticosteroid",
        "cortisol",
        "endocrine system",
        "gland",
        "glucagon",
        "growth hormone",
        "homeostasis",
        "hormones",
        "insulin",
        "islets of langerhans",
        "negative feedback",
        "ovaries",
        "pancreas",
        "parathyroid glands",
        "pineal gland",
        "pituitary gland",
        "target organs",
        "testes",
        "thyroid gland",
        "tropic hormone",
        "serum cortisol",
        "serum aldosterone",
        "serum adrenocorticotropic hormone",
        "ACTH",
        "serum adrenocorticotropic hormone stimulation with cosyntropin",
        "serum adrenocorticotropic hormone suppression",
        "urine vanillylmandelic acid",
        "computed tomography",
        "CT scan",
        "fasting blood glucose level",
        "oral glucose tolerance",
        "capillary glucose monitoring",
        "glycosylated hemoglobin",
        "HbA1C",
        "urine glucose",
        "urine ketones",
        "somatostatin C",
        "growth hormone stimulation test",
        "gonadotropin levels",
        "prolactin levels",
        "magnetic resonance imaging",
        "MRI",
        "thyroid-stimulating hormone",
        "TSH",
        "thyroxine",
        "T4",
        "triiodothyronine",
        "T3",
        "free t4",
        "ultrasound",
        "radioactive iodine uptake",
        "thyroid scan",
        "parathyroid hormone",
        "PTH",
        "total serum calcium",
        "ionized calcium",
        "serum phosphate",
        "acromegaly",
        "cushing syndrome",
        "diabetes insipidus",
        "diabetic ketoacidosis",
        "diabetes mellitus",
        "diabetic nephropathy",
        "diabetic neuropathy",
        "exophthalmos",
        "goiter",
        "Grave’s disease",
        "Hashimoto's thyroiditis",
        "hyperparathyroidism",
        "hyperthyroidism",
        "hypoparathyroidism",
        "hypopituitarism",
        "hypothyroidism",
        "myxedema",
        "pheochromocytoma",
        "Somogyi effect",
        "syndrome of inappropriate antidiuretic hormone",
        "SAIDH",
        "thyroiditis",
        "thyrotoxicosis",
        "thyrotoxic crisis",
        "metformin",
        "biguinide",
        "anabolic steroids",
        "chlorpropamide",
        "tolazamide",
        "tolbutamide",
        "glipizide",
        "glyburide",
        "acarbose",
        "miglitol",
        "pioglitazone",
        "troglitazone",
        "rosiglitazone",
        "repaglinide",
        "polyestradiol",
        "diethylstilbestrol",
        "megestrol acetate",
        "leuprorelin",
        "flutamide",
        "tamoxifen",
        "insulin aspart",
        "insulin lispro",
        "isophane insulin",
        "biphasic",
        "gliclazide",
        "nateglinide",
        "vildagliptin",
        "liothyronine",
        "levothyroxine",
        "sodium",
        "carbimazole",
        "propylthiouracil",
        "fludrocortisone",
        "betametasone",
        "cortisone",
        "dexamethasone",
        "hydrocortisone",
        "methylprednisolone",
        "prednisolone",
        "estradiol",
        "ethinylestradiol",
        "dydrogesterone",
        "hydroxyprogesterone",
        "medroxyprogesterone",
        "norethisterone",
        "progesterone",
        "cyproterone acetate",
        "gonadotrophin",
        "clomiphene citrate",
        "gonadorelin",
        "menotrophin",
        "somatropin",
        "tetracosactide",
        "urofollitropin",
        "demeclocycline",
        "desmopressin",
        "terlipressin",
        "vasopressin",
        "calcitonin",
        "disodium",
        "etidronate",
        "pamidronate",
        "risedronate",
        "clodronate",
        "strontium ranelate",
        "tiludronic acid",
        "bromocriptine",
        "cabergoline",
        "danazol",
        "buserelin",
        "goserelin",
    ],
    colorectal: [
        "abdominoperineal resection",
        "accidental bowel leakage",
        "acetaminophen",
        "acute",
        "adenoma",
        "adhesion",
        "adjuvant therapy",
        "adverse effect",
        "analgesic",
        "anemia",
        "antibiotic",
        "antibodies",
        "antigens",
        "anti-inflammatory",
        "air contrast barium enema",
        "anal fissure",
        "anastomosis",
        "aneurysm",
        "angiogram",
        "angiography",
        "anoscopy",
        "anus",
        "APC",
        "adenomatous polyposis coli",
        "asymptomatic",
        "banding",
        "barium",
        "barium enema",
        "benign tumor",
        "biofeedback",
        "biological therapy",
        "biopsy",
        "brachytherapy",
        "cancer",
        "cannulas",
        "carcinoma",
        "carcinoma in situ",
        "catheter",
        "CAT scan",
        "CT Scan",
        "chemotherapy",
        "chronic",
        "clear margins",
        "clinical trial",
        "partial colectomy",
        "segmental colectomy",
        "total colectomy",
        "colitis",
        "colon",
        "colon cancer",
        "colon and rectal surgeon",
        "colonoscopy",
        "colostomy",
        "constipation",
        "contraindication",
        "Crohn's disease",
        "desmoid tumors",
        "diarrhea",
        "digestive diseases",
        "digital rectal exam",
        "DRE",
        "diverticulosis",
        "DNA",
        "double contrast barium enema",
        "duodenum",
        "endoscopy",
        "enema",
        "epidural catheter",
        "erythrocytes",
        "esophagogastroduodenoscopy",
        "EGD",
        "familial adenomatous polyposis",
        "FAP",
        "fecal diversion",
        "fecal incontinence",
        "fecal occult blood test",
        "fistula",
        "flexible sigmoidoscopy",
        "fluoroscopy",
        "gas",
        "gastroenterologist",
        "gene",
        "genetic counseling",
        "genetic testing",
        "grade",
        "hemorrhoids",
        "hepatitis",
        "hereditary non-polyposis colorectal cancer",
        "HNPCC",
        "hormonal therapy",
        "hormones",
        "ileal pouch",
        "J Pouch",
        "ileocecal valve",
        "ileocolectomy",
        "ileorectal anastomosis",
        "ileostomy",
        "ileum",
        "immune system",
        "immunotherapy",
        "incontinence",
        "bowel",
        "inflammation",
        "inflammatory bowel disease",
        "IBD",
        "IV",
        "intravenous",
        "irritable bowel syndrome",
        "IBS",
        "irritable bowel disease",
        "jejunum",
        "laparoscopy or laparoscopic surgery",
        "large intestine",
        "laser surgery",
        "laxative",
        "local therapy",
        "localized cancer",
        "lymph",
        "lymphatic system",
        "MRI",
        "malignant",
        "mesentery",
        "metastasize",
        "microsatellite instability",
        "mismatch repair genes",
        "mismatch repair",
        "muscle transposition",
        "mutation",
        "nausea",
        "nitrates",
        "non-steroidal anti-inflammatory drugs",
        "NSAIDs",
        "occult blood",
        "medical oncologist",
        "radiation oncologist",
        "surgical oncologist",
        "ostomy",
        "pathology",
        "pathologist",
        "PCA",
        "patient controlled analgesia",
        "peristalsis",
        "platelets",
        "polyps",
        "proctocolectomy",
        "proctoscopy",
        "proctosigmoidectomy",
        "prognosis",
        "pulse oximetry",
        "radiation",
        "internal radiation",
        "external radiation",
        "radiation technologist",
        "radiology",
        "radiologist",
        "rectal bleeding",
        "rectal prolapse",
        "rectopexy",
        "rectum",
        "recurrence",
        "remission",
        "risk factor",
        "sentinel lymph node",
        "sigmoidoscopy",
        "small intestine",
        "sphincteroplasty",
        "stage",
        "stoma",
        "systemic therapy",
        "thrombosis",
        "total abdominal colectomy",
        "trocar",
        "tumor",
        "ulcerative colitis",
        "ultrasound",
        "vomiting",
        "X-ray",
        "laproscopic",
        "radiofrquency ablation",
        "RFA",
        "stereotactic",
        "intraoperative radiation",
        "total mesenteric excision",
        "stoma surgery",
        "fluorouracil",
        "capecitabine",
        "oxaliplatin",
        "irinotecan",
        "trifluridine",
        "folfox",
        "folfiri",
        "capox",
    ],
    gastrointestinal: [
        "abdominal",
        "gastroenterologist",
        "gastroenterology",
        "anus",
        "appendix",
        "biliary",
        "colon",
        "cecum",
        "duodenum",
        "epigastric",
        "esophagus",
        "gallbladder",
        "hypochondrium",
        "hypogastric",
        "ileum",
        "inguinal",
        "jejunum",
        "liver",
        "pancreas",
        "rectum",
        "sigmoid colon",
        "stomach",
        "suprapubic",
        "umbilical",
        "CT",
        "TERM",
        "computed tomography",
        "magnetic resonance imaging",
        "MRI",
        "ultrasound",
        "barium test",
        "cholangiography",
        "colonoscopy",
        "endoscopy",
        "endoscopic retrograde cholangiopancreatography",
        "ERCP",
        "EGD",
        "esophagogastroduodenoscopy",
        "LFT",
        "liver function test",
        "liver biopsy",
        "sigmoidoscopy",
        "appendectomy",
        "cecostomy",
        "cholecystectomy",
        "cholecystostomy",
        "choledochojejunostomy",
        "choledocholithotomy",
        "colectomy",
        "colostomy",
        "esophagogastrostomy",
        "gastrectomy",
        "gastrostomy",
        "ileostomy",
        "pyloroplasty",
        "achalasia",
        "appendicitis",
        "ascites",
        "cholelithiasis",
        "cirrhosis",
        "dysphagia",
        "gastritis",
        "hematemesis",
        "hematachezia",
        "hepatitis",
        "hepatomegaly",
        "jaundice",
        "melena",
        "odynophagia",
        "pyrosis",
        "spleenomegaly",
        "steatorrhea",
        "proctectomy",
        "antacids",
        "omeprazole",
        "lansoprazole",
        "esomeprazole",
        "pantoprazole",
        "abeprazole",
        "ranitidine",
        "mebeverine",
        "loperamide",
        "mesalazine",
        "infliximab",
    ],
    hepatobilliary: [
        "acute liver failure",
        "ALF",
        "alagille syndrome",
        "alcoholic liver disease",
        "ampullary cancer",
        "autoimmune hepatitis",
        "benign liver tumors",
        "bile duct cancer",
        "cholangiocarcinoma",
        "borderline resectable pancreatic cancer",
        "BRPC",
        "choledochal cysts",
        "pediatric liver center",
        "chronic pancreatitis",
        "cirrhosis",
        "colon cancer",
        "cryosurgery",
        "cyberknife",
        "distal pancreatectomy",
        "end-stage liver disease",
        "ESLD",
        "ERCP",
        "fatty liver disease",
        "nonalcoholic steatohepatitis",
        "gallbladder cancer",
        "gastrointestinal stromal tumor",
        "GIST",
        "hemochromatosis",
        "hepatic artery embolization",
        "hepatitis A",
        "hepatitis B",
        "hepatitis C",
        "hepatitis D",
        "hepatitis E",
        "hepatitis G",
        "islet autotransplantation for chronic pancreatitis",
        "liver biopsy",
        "liver cancer",
        "hepatocellular carcinoma",
        "liver cysts",
        "liver metastases",
        "liver resection",
        "melanoma",
        "microwave ablation",
        "pancreatectomy",
        "pancreatic cancer",
        "pancreatic neuroendocrine tumors",
        "islet cell",
        "pancreatic pseudocysts",
        "percutaneous ethanol injection",
        "porphyria",
        "portal vein embolization",
        "PVE",
        "primary biliary cirrhosis",
        "primary sclerosing cholangitis",
        "radiofrequency ablation",
        "RFA",
        "Rex shunt",
        "meso-portal shunt",
        "soft tissue sarcoma",
        "toxic hepatitis",
        "whipple procedure",
        "pancreaticoduodenectomy",
        "Wilson disease",
        "Yttrium-90 radioembolization",
        "cholic acid",
        "neomycin",
        "paromomycin",
        "humatin",
        "sorafenib",
        "nexavar",
        "lenvatinib",
        "nivolumab",
        "atezolizumab",
        "pembrolizumab",
        "regorafenib",
        "cabozantinib",
        "ramucirumab",
        "phenobarbital",
        "ursodiol",
        "azathioprine",
        "colchicine",
        "obeticholic",
        "floxuridine",
        "pioglitazone",
        "orlistat",
        "rosiglitazone",
        "betaine",
        "pancrelipase",
        "propranolol",
        "nadolol",
    ],
    transplant: [
        "ablation",
        "acute",
        "allograft",
        "analgesic",
        "anastomosis",
        "anemia",
        "anesthesia",
        "anesthesiologist",
        "aneurysm",
        "angiography",
        "angioplasty",
        "antibiotic",
        "anticoagulant",
        "antigen",
        "antihypertensive",
        "aorta",
        "aortic stenosis",
        "aortic valve",
        "apheresis",
        "arrhythmia",
        "dysrhythmia",
        "artery",
        "ascites",
        "atrial fibrillation",
        "atrioventricular block",
        "balloon angioplasty",
        "barium",
        "benign",
        "bilateral",
        "bile",
        "bile ducts",
        "biliary atresia",
        "bilirubin",
        "biopsy",
        "bladder",
        "blood",
        "blood banking",
        "blood clot",
        "blood plasma",
        "blood pressure",
        "blood pressure cuff",
        "bowel",
        "bradycardia",
        "bronchoscopy",
        "bundle-branch block",
        "calcium channel blocker",
        "cancer cell",
        "capillaries",
        "carbohydrates",
        "cardiac arrest",
        "cardiac catheterization",
        "cardiologist",
        "cardiology",
        "cardiomyopathy",
        "cardiovascular",
        "CV",
        "cardioversion",
        "carotid artery",
        "catheter",
        "chemotherapy",
        "cholangiography",
        "cholesterol",
        "chronic",
        "circulatory system",
        "cirrhosis",
        "clotting",
        "colon",
        "colonoscopy",
        "common bile duct",
        "complete blood count",
        "CBC",
        "compliance",
        "computed tomography scan",
        "CT",
        "CAT",
        "congenital",
        "congestive heart failure",
        "coronary arteries",
        "corticosteroids",
        "culture",
        "cyanosis",
        "cyanotic",
        "cystoscopy",
        "cystourethroscopy",
        "cystourethrogram",
        "voiding cystogram",
        "dehydration",
        "dialysis",
        "diastolic blood pressure",
        "digestion",
        "digestive tract",
        "dilate",
        "distention",
        "diuretic",
        "Doppler ultrasound",
        "dyspnea",
        "echocardiogram",
        "echo",
        "edema",
        "effusion",
        "ejection fraction",
        "electrocardiogram",
        "ECG",
        "EKG",
        "electroencephalogram",
        "EEG",
        "electrolytes",
        "electrophysiological study",
        "EPS",
        "endocarditis",
        "endoscopy",
        "end-stage organ disease",
        "end-to-end anastomosis",
        "enema",
        "enlarged heart",
        "esophagus",
        "exercise electrocardiogram",
        "fats",
        "fluoroscopy",
        "flutter",
        "gallbladder",
        "gas",
        "gastric",
        "gastrointestinal",
        "gastrointestinal tract",
        "glomerulonephritis",
        "glomerulosclerosis",
        "glucose",
        "graft",
        "graft-versus-host disease",
        "GVHD",
        "granulocytes",
        "heart attack",
        "myocardial infarction",
        "heart block",
        "hematocrit",
        "hematuria",
        "hemoglobin",
        "hemolytic anemia",
        "hemophilia",
        "coagulation disorder",
        "hepatic",
        "hepatitis",
        "hepatitis A",
        "hepatitis B",
        "hepatitis C",
        "hepatitis D",
        "hepatitis E",
        "hepatitis G",
        "hepatoblastoma",
        "high density lipoprotein",
        "HDL",
        "holter monitor",
        "hyperbilirubinemia",
        "hypotension",
        "hypoxia",
        "IgE antibody",
        "ileum",
        "imaging studies",
        "immune system",
        "immunosuppression",
        "immunosuppressive medications",
        "immunotherapy",
        "incision",
        "indigestion",
        "infection",
        "informed consent",
        "insufficiency",
        "insulin",
        "intestine",
        "intravenous gamma globulin",
        "IVGG",
        "intravenous line",
        "IV",
        "intravenous pyelogram",
        "IVP",
        "iron deficiency anemia",
        "ischemia",
        "ischemic heart disease",
        "jaundice",
        "kidney transplantation",
        "kidneys",
        "laparoscope",
        "laparoscopy",
        "large intestine",
        "left atrium",
        "left ventricle",
        "leukemia",
        "lipid",
        "liver",
        "liver function tests",
        "lower GI series",
        "lymph nodes",
        "magnetic resonance imaging",
        "MRI",
        "malignant",
        "Marfan syndrome",
        "metastasis",
        "murmur",
        "myelogram",
        "myocarditis",
        "myocardium",
        "nephrectomy",
        "nephrologist",
        "nephrology",
        "nephrotic syndrome",
        "neuroblastoma",
        "neurosurgeon",
        "occluded artery",
        "oncologist",
        "oncology",
        "open heart surgery",
        "oxygen desaturation",
        "oxygen saturation",
        "pacemaker",
        "pain specialist",
        "palpitation",
        "pancreas",
        "patent",
        "pathologist",
        "percutaneous",
        "perforation",
        "perfusion",
        "pericardial effusion",
        "pericardium",
        "peritonitis",
        "pharynx",
        "physical therapist",
        "plaque",
        "plasma",
        "platelets",
        "pleura",
        "polycystic kidney disease",
        "PKD",
        "polyunsaturated fat",
        "portal hypertension",
        "portal vein",
        "premature atrial contraction",
        "PAC",
        "premature ventricular contraction",
        "PVC",
        "prognosis",
        "prophylaxis",
        "protocol",
        "pulmonary",
        "pulmonary artery",
        "pulmonary edema",
        "pulmonary hypertension",
        "pulmonary vein",
        "pulse oximeter",
        "pylorus",
        "radiation therapist",
        "radiologist",
        "rectum",
        "red blood cells",
        "RBCs",
        "erythrocytes",
        "regimen",
        "rejection",
        "renal",
        "renal ultrasound",
        "respiration",
        "risk factor",
        "rubella",
        "saline solution",
        "sarcoma",
        "septal defect",
        "septum",
        "shunt",
        "side effects",
        "sinus tachycardia",
        "small intestine",
        "sphygmomanometer",
        "spinal tap",
        "lumbar puncture",
        "spleen",
        "stenosis",
        "stent",
        "sternotomy",
        "sternum",
        "stethoscope",
        "stress",
        "stroke",
        "subclavian",
        "superior vena cava",
        "supraventricular tachycardia",
        "syncope",
        "systole",
        "tachycardia",
        "tachypnea",
        "tamponade",
        "telemetry unit",
        "transplantation",
        "tricuspid valve",
        "ultrasound",
        "sonography",
        "unilateral",
        "upper GI series",
        "urea",
        "ureteroscope",
        "ureters",
        "urethra",
        "urethriti",
        "urinalysis",
        "urinary incontinence",
        "urinary tract infection",
        "urogenital",
        "urologist",
        "urology",
        "valves",
        "vascular",
        "vasodilator",
        "vasopressor",
        "vein",
        "ventilation",
        "ventricle",
        "ventricular fibrillation",
        "ventricular septal defect",
        "ventricular tachycardia",
        "vomiting",
        "white blood cells",
        "leukocytes",
        "WBCs",
        "Wilms tumor",
        "tacrolimus",
        "cyclosporine",
        "prednisone",
        "mycophenolate",
        "azathioprine",
        "sirolimus",
        "everolimus",
    ],
};
