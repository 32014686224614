import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import {
    AngularFireAuthGuard,
    redirectUnauthorizedTo,
    redirectLoggedInTo,
} from "@angular/fire/auth-guard";

import { LoginComponent } from "./components/login/login.component";
import { LogoutComponent } from "./components/logout/logout.component";
import { LogoutGuard } from "./services/logout-guard.service";
import { RegisterComponent } from "./components/register/register.component";
import { DictateComponent } from "./components/dictate/dictate.component";
import { PreferencesComponent } from "./components/preferences/preferences.component";

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(["login"]);
const redirectLoggedInToDictate = () => redirectLoggedInTo(["dictate"]);

const routes: Routes = [
    { path: "", redirectTo: "/login", pathMatch: "full" },
    {
        path: "login",
        component: LoginComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToDictate },
    },
    {
        path: "register",
        component: RegisterComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectLoggedInToDictate },
    },
    {
        path: "dictate",
        component: DictateComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
    },
    {
        path: "preferences",
        component: PreferencesComponent,
        canActivate: [AngularFireAuthGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
    },
    {
        path: "logout",
        component: LogoutComponent,
        canActivate: [AngularFireAuthGuard, LogoutGuard],
        data: { authGuardPipe: redirectUnauthorizedToLogin },
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
