export const environment = {
    production: false,
    backend: "https://hepian-link-staging.appspot.com/api",
    firebase: {
        apiKey: "AIzaSyCDAIJJAFhMKh0tmmmvSf0lHaWSkb5uVzc",
        authDomain: "hepian-link-staging.firebaseapp.com",
        databaseURL: "https://hepian-link-staging.firebaseio.com",
        projectId: "hepian-link-staging",
        storageBucket: "hepian-link-staging.appspot.com",
        messagingSenderId: "320954360098",
        appId: "1:320954360098:web:14fa78ed9892569cba457b",
        measurementId: "G-J5YNTT8EVR"
    }
};
