import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { AuthService } from "src/app/services/auth.service";
import { InviteService } from "src/app/services/invite.service";
import { Invite } from "src/app/models/invite";
import { PreferencesService } from "src/app/services/preferences.service";

@Component({
    selector: "app-register",
    templateUrl: "./register.component.html",
    styleUrls: ["./register.component.scss"],
})
export class RegisterComponent implements OnInit {
    emailText: string = "";
    passwordText: string = "";
    nameText: string = "";
    serverErrorMessage: string = null;
    loadingEmail: boolean = false;
    loadingGoogle: boolean = false;
    loadingFacebook: boolean = false;
    inviteId: string = null;
    specialty: string = null;

    specialtyList = [
        ["allergy", "Allergy"],
        ["audiovestibular", "Audiovestibular Medicine"],
        ["cardiology", "Cardiology"],
        ["genetics", "Clinical Genetics"],
        ["neurophysiology", "Clinical Neurophysiology"],
        ["pharmacology", "Clinical Pharmacology"],
        ["dermatology", "Dermatology"],
        ["endocrinology", "Endocrinology"],
        ["gastroenterology", "Gastroenterology"],
        ["internal", "General Internal Medicine"],
        ["genitourinary", "Genitourinary Medicine"],
        ["geriatric", "Geriatric Medicine"],
        ["immunology", "Immunology"],
        ["epidemiology", "Epidemiology"],
        ["oncology", "Oncology"],
        ["ophthalmology", "Ophthalmology"],
        ["neurology", "Neurology"],
        ["nuclear", "Nuclear Medicine"],
        ["pharmaceutical", "Pharmaceutical Medicine"],
        ["rehabilitation", "Rehabilitation Medicine"],
        ["renal", "Renal Medicine"],
        ["respiratory", "Respiratory Medicine"],
        ["rheumatology", "Rheumatology"],
        ["sports", "Sports Medicine"],
        ["stroke", "Stroke Medicine"],
        ["obgyn", "Obstetrics & Gynaecology"],
        ["paediatrics", "Paediatrics"],
        ["haematology", "Haematology"],
        ["microbiology", "Micriobiology & Virology"],
        ["psychiatry", "Psychiatry"],
        ["neurosurgery", "Neurosurgery"],
        ["maxfax", "Oral & Maxillofacial Surgery"],
        ["otorhinolaryngology", "Otorhinolaryngology"],
        ["traumaortho", "Trauma & Orthopaedics"],
        ["urology", "Urology"],
        ["vascular", "Vascular Surgery"],
        ["breast", "General Surgery - Breast"],
        ["endocrine", "General Surgery - Endocrine"],
        ["colorectal", "General Surgery - Colorectal"],
        ["gastrointestinal", "General Surgery - GI"],
        ["hepatobilliary", "General Surgery - Hepato-Billiary"],
        ["transplant", "General Surgery - Transplant"],
    ];

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthService,
        private inviteService: InviteService,
        private preferencesService: PreferencesService
    ) {
        if (this.route.snapshot.queryParamMap.has("invite")) {
            this.handleInvite(this.route.snapshot.queryParamMap.get("invite"));
        }
    }

    async handleInvite(inviteId: string) {
        this.loadingEmail = true;

        try {
            const invite = await this.inviteService.getInviteById(inviteId);

            if (invite.accepted) {
                throw {
                    message: "Invite already accepted. Invite will be ignored.",
                };
            }

            this.inviteId = invite._id;
            this.emailText = invite.invitee.email;
            this.nameText = invite.invitee.name;
        } catch (err) {
            console.warn(err.message);
        }

        this.loadingEmail = false;
    }

    ngOnInit() {}

    async onSubmit(registerForm) {
        if (this.loadingEmail) return;
        this.loadingEmail = true;
        this.serverErrorMessage = null;

        if (!registerForm.valid) {
            this.loadingEmail = false;
            return;
        }

        try {
            await this.authService.doRegisterEmailPassword(
                this.emailText,
                this.passwordText,
                this.nameText
            );

            if (this.inviteId)
                await this.inviteService.acceptInviteById(this.inviteId);

            await this.preferencesService.updatePreferences({
                dictation: { language: "en-GB", specialty: this.specialty },
            });

            this.loadingEmail = false;
            this.redirect();
        } catch (err) {
            this.serverErrorMessage = err.message;
            this.loadingEmail = false;
        }
    }

    facebookLogin() {
        if (this.loadingEmail || this.loadingFacebook || this.loadingGoogle)
            return;
        this.loadingFacebook = true;
        this.authService
            .doFacebookLogin()
            .then(() => {
                this.loadingFacebook = false;
                this.redirect();
            })
            .catch(() => {
                this.loadingFacebook = false;
            });
    }

    googleLogin() {
        if (this.loadingEmail || this.loadingFacebook || this.loadingGoogle)
            return;
        this.loadingGoogle = true;
        this.authService
            .doGoogleLogin()
            .then(() => {
                this.loadingGoogle = false;
                this.redirect();
            })
            .catch(() => {
                this.loadingGoogle = false;
            });
    }

    redirect() {
        console.log("redirect");
        this.router.navigateByUrl("/dictate");
    }
}
