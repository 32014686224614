import { Component, Input, OnDestroy } from "@angular/core";

@Component({
    selector: "spinner",
    styles: [
        `
            .three-bounce-spinner {
                margin: 0 auto;
                margin-top: 1px;
                height: 36px;
                width: 60px;
            }

            .three-bounce-spinner > div {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin: 0 2px;

                border-radius: 100%;
                -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
                animation: sk-bouncedelay 1.4s infinite ease-in-out both;
            }

            .three-bounce-spinner .bounce1 {
                -webkit-animation-delay: -0.32s;
                animation-delay: -0.32s;
            }

            .three-bounce-spinner .bounce2 {
                -webkit-animation-delay: -0.16s;
                animation-delay: -0.16s;
            }

            @-webkit-keyframes sk-bouncedelay {
                0%,
                80%,
                100% {
                    -webkit-transform: scale(0);
                }
                40% {
                    -webkit-transform: scale(1);
                }
            }

            @keyframes sk-bouncedelay {
                0%,
                80%,
                100% {
                    -webkit-transform: scale(0);
                    transform: scale(0);
                }
                40% {
                    -webkit-transform: scale(1);
                    transform: scale(1);
                }
            }
        `
    ],
    template: `
        <div [hidden]="!visible" [ngClass]="baseClass">
            <div
                *ngFor="let item of items; let i = index"
                [ngClass]="childClass + (i + 1)"
                [style.backgroundColor]="color"
            ></div>
        </div>
    `
})
export class SpinnerComponent implements OnDestroy {
    public visible: boolean = true;
    public timeout: any;
    public baseClass: string = "three-bounce-spinner";
    public childClass: string = "bounce";
    public numItems: number = 3;

    @Input()
    public delay: number = 0;

    @Input()
    public color: string = "#03BE9D";

    @Input()
    public set isRunning(value: boolean) {
        if (!value) {
            this.cancel();
            this.visible = false;
            return;
        }

        if (this.timeout) {
            return;
        }

        this.timeout = setTimeout(() => {
            this.visible = true;
            this.cancel();
        }, this.delay);
    }

    private cancel(): void {
        clearTimeout(this.timeout);
        this.timeout = undefined;
    }

    public get items() {
        return Array(this.numItems);
    }

    ngOnDestroy(): any {
        this.cancel();
    }
}
