import { Component, OnInit } from "@angular/core";
import { PreferencesService } from "src/app/services/preferences.service";

@Component({
    selector: "app-preferences",
    templateUrl: "./preferences.component.html",
    styleUrls: ["./preferences.component.scss"],
})
export class PreferencesComponent implements OnInit {
    preferences = null;
    _loading = false;
    specialtyList = [
        ["allergy", "Allergy"],
        ["audiovestibular", "Audiovestibular Medicine"],
        ["cardiology", "Cardiology"],
        ["genetics", "Clinical Genetics"],
        ["neurophysiology", "Clinical Neurophysiology"],
        ["pharmacology", "Clinical Pharmacology"],
        ["dermatology", "Dermatology"],
        ["endocrinology", "Endocrinology"],
        ["gastroenterology", "Gastroenterology"],
        ["internal", "General Internal Medicine"],
        ["genitourinary", "Genitourinary Medicine"],
        ["geriatric", "Geriatric Medicine"],
        ["immunology", "Immunology"],
        ["epidemiology", "Epidemiology"],
        ["oncology", "Oncology"],
        ["ophthalmology", "Ophthalmology"],
        ["neurology", "Neurology"],
        ["nuclear", "Nuclear Medicine"],
        ["pharmaceutical", "Pharmaceutical Medicine"],
        ["rehabilitation", "Rehabilitation Medicine"],
        ["renal", "Renal Medicine"],
        ["respiratory", "Respiratory Medicine"],
        ["rheumatology", "Rheumatology"],
        ["sports", "Sports Medicine"],
        ["stroke", "Stroke Medicine"],
        ["obgyn", "Obstetrics & Gynaecology"],
        ["paediatrics", "Paediatrics"],
        ["haematology", "Haematology"],
        ["microbiology", "Micriobiology & Virology"],
        ["psychiatry", "Psychiatry"],
        ["neurosurgery", "Neurosurgery"],
        ["maxfax", "Oral & Maxillofacial Surgery"],
        ["otorhinolaryngology", "Otorhinolaryngology"],
        ["traumaortho", "Trauma & Orthopaedics"],
        ["urology", "Urology"],
        ["vascular", "Vascular Surgery"],
        ["breast", "General Surgery - Breast"],
        ["endocrine", "General Surgery - Endocrine"],
        ["colorectal", "General Surgery - Colorectal"],
        ["gastrointestinal", "General Surgery - GI"],
        ["hepatobilliary", "General Surgery - Hepato-Billiary"],
        ["transplant", "General Surgery - Transplant"],
    ];

    constructor(private preferencesService: PreferencesService) {
        this.preferencesService.preferences.subscribe(
            (preferences) => (this.preferences = preferences)
        );
    }

    get loading() {
        return this._loading || this.preferences === null;
    }

    ngOnInit(): void {}

    async onSubmit(preferencesForm) {
        this._loading = true;
        await this.preferencesService.updatePreferences(this.preferences);
        this._loading = false;
    }
}
