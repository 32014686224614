import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";
import { BehaviorSubject, Observable } from "rxjs";

const apiUrl = `${environment.backend}/v1/preferences`;

const defaultPreferences = {
    dictation: {
        language: "en-GB",
        specialty: "",
        mailout_email: "",
    },
};

@Injectable({
    providedIn: "root",
})
export class PreferencesService {
    private _preferences: BehaviorSubject<any> = new BehaviorSubject(null);
    public readonly preferences: Observable<
        any
    > = this._preferences.asObservable();

    constructor(private http: HttpClient, private authService: AuthService) {
        this.loadPreferences();
        // this.preferences.subscribe((val) => console.log(val));
    }

    async loadPreferences() {
        const preferences = await this.http
            .get(
                `${environment.backend}/v1/preferences`,
                await this.httpOptions
            )
            .toPromise()
            .then((res) => res["preferences"])
            .catch((err) => {
                console.error(`Couldn't get preferences.`, err);
                return defaultPreferences;
            });
        this._preferences.next(preferences);
    }

    async updatePreferences(preferences) {
        return this.http
            .put(apiUrl, preferences, await this.httpOptions)
            .toPromise()
            .then((res) => {
                this._preferences.next(preferences);
                this.loadPreferences();
            });
    }

    get httpOptions() {
        return new Promise(async (resolve) => {
            const userToken = await this.authService.getUserToken();
            resolve({
                headers: new HttpHeaders({
                    "Content-Type": "application/json",
                    Accept: "application/json",
                    Authorization: `Bearer ${userToken}`,
                }),
            });
        });
    }
}
