import { HttpClient, HttpHeaders } from "@angular/common/http";

import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AuthService } from "./auth.service";

import { Socket } from "ngx-socket-io";
import { Observable, BehaviorSubject } from "rxjs";
import { PreferencesService } from "./preferences.service";
import { specialtyVocab } from "./specialty-vocab";

const apiUrl = `https://hepian-cloud-services.nw.r.appspot.com`;
// const apiUrl = `localhost:8080`;

declare var window: any;

@Injectable({
    providedIn: "root",
})
export class DicateService {
    preferences = null;
    specialty = null;
    specialtyList = [];
    specialtyCaps = [];
    
    constructor(
        private authService: AuthService,
        private preferencesService: PreferencesService
    ) {
        this.preferencesService.preferences.subscribe(
            (preferences) => (this.preferences = preferences)
        );
        this.socket = new Socket({
            url: apiUrl,
            options: {},
        });

        this.socket.on("connect", (data: any) => {
            this.socket.emit("join", "Server connected to client");
        });

        this.socket.connect();
        console.log("Socket ready");
        console.log("Enabling audio recording");
        navigator.mediaDevices
            .getUserMedia({
                audio: {
                    noiseSuppression: false,
                    echoCancellation: false,
                    autoGainControl: false,
                },
            })
            .then((mediaStream: MediaStream) =>
                console.log("Audio recording enabled.")
            );
    }

    socket: Socket;
    context: AudioContext;
    bufferSize: number = 2048;
    processor: ScriptProcessorNode;
    mediaStream: MediaStream;
    input: MediaStreamAudioSourceNode;

    transcribing: boolean = false;

    initTranscriptionContext(): void {
        this.specialty = this.preferences.dictation.specialty;

        if (this.specialty && specialtyVocab[this.specialty]) {
            this.specialtyList = specialtyVocab[this.specialty];
            this.specialtyCaps = this.specialtyList
                .filter(
                    (value: string, index: number, array: any) =>
                        value !== value.toLowerCase()
                )
                .sort((a, b) => a.length - b.length);
        }

        let boostedPhraseHints = [
            {
                phrases: [
                    this.authService.getUserName(),
                    ...this.authService.getUserName().split(" "),
                    "Hepian",
                    "Hepian Link",
                    "Hepian Write",
                    "comma",
                ],
                boost: 20,
            },
            {
                phrases: ["Thessaly test", "Thessaly's test", "Thessaly's"],
                boost: 7,
            },
            {
                phrases: [
                    "Dial test",
                    "Lachman test",
                    "Lachman's test",
                    "Lachman's",
                    "McMurray test",
                    "McMurray's test",
                    "McMurray's",
                    ...this.specialtyList,
                ],
                boost: 5,
            },
            {
                phrases: [
                    "$OPERAND",
                    "arterial venous malformation",
                    "AVM",
                    "vestibular schwannoma",
                    "cavernous malformation",
                    "moyamoya disease",
                    "glioblastoma",
                    "trigeminal schwannoma",
                    "trigeminal neuralgia",
                    "extracranial intercranial bypass",
                ],
                boost: 4,
            },
        ];

        console.log(boostedPhraseHints);

        this.socket.emit("setTranscriptionContext", {
            user: this.authService.getUserId(),
            locale: "en-GB",
            boostedPhrases: boostedPhraseHints,
        });
    }

    startTranscription(): BehaviorSubject<Transcription> {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;

        // this.socket = new Socket({
        //     url: apiUrl,
        //     options: {},
        // });

        const transcription: BehaviorSubject<Transcription> = new BehaviorSubject(
            { text: "", isFinal: true }
        );

        this.socket.on("speechData", (data: any) => {
            this.processTranscription(data, transcription);
        });

        this.socket.on("messages", (data: any) => console.log(data));

        this.socket.emit("startGoogleCloudStream", "");

        this.context = new AudioContext({
            latencyHint: "interactive",
        });

        this.processor = this.context.createScriptProcessor(
            this.bufferSize,
            1,
            1
        );

        this.processor.connect(this.context.destination);

        this.context.resume();

        navigator.mediaDevices
            .getUserMedia({
                audio: {
                    noiseSuppression: false,
                    echoCancellation: false,
                    autoGainControl: false,
                },
            })
            .then((mediaStream: MediaStream) => {
                this.mediaStream = mediaStream;
                this.input = this.context.createMediaStreamSource(
                    this.mediaStream
                );
                this.input.connect(this.processor);
                this.processor.onaudioprocess = (event) => {
                    this.microphoneProcess(event);
                };
                this.transcribing = true;
            });

        return transcription;
    }

    processTranscription(
        data: any,
        transcription: BehaviorSubject<Transcription>
    ) {
        let text: string = data.results[0].alternatives[0].transcript;
        let namePhrases = [
            this.authService.getUserName(),
            ...this.authService.getUserName().split(" "),
            "Hepian",
            "Hepian Link",
            "Hepian Write",
            "Thessaly",
            "Dial test",
            "Lachman",
            "McMurray",
        ];
        namePhrases.reverse().forEach((namePhrase) => {
            text = text.replace(
                new RegExp("\\b" + namePhrase + "\\b", "gi"),
                namePhrase
            );
        });
        this.specialtyCaps.forEach((capsPhrase) => {
            text = text.replace(
                new RegExp("\\b" + capsPhrase + "\\b", "gi"),
                (match) => {
                    return this.toUpperFromLower(match, capsPhrase);
                }
            );
        });
        transcription.next({ text: text, isFinal: data.results[0].isFinal });
    }

    toUpperFromLower(string1: string, string2: string) {
        let result = "";
        for (let i = 0; i < Math.max(string1.length, string2.length); i++) {
            let c1 = string1.charAt(i);
            let c2 = string2.charAt(i);

            if (c1 && c2) {
                if (c1 === c1.toUpperCase()) {
                    result += c1;
                } else {
                    result += c2;
                }
            } else if (c1) {
                result += c1;
            } else if (c2) {
                result += c2;
            }
        }
        return result;
    }

    stopTranscription() {
        this.socket.emit("endGoogleCloudStream", "");
        this.socket.removeListener("speechData");
        this.socket.removeListener("messages");
        this.input.disconnect();
        this.processor.disconnect();
        this.context.close();
        this.context = null;
        this.input = null;
        this.processor = null;
        this.transcribing = false;
    }

    microphoneProcess(event: any) {
        const left = event.inputBuffer.getChannelData(0);
        const left16 = this.downsampleBuffer(
            left,
            this.context.sampleRate,
            16000
        );
        this.socket.emit("binaryData", left16);
    }

    convertFloat32ToInt16(buffer) {
        let l = buffer.length;
        let buf = new Int16Array(l / 3);

        while (l--) {
            if (l % 3 === 0) {
                buf[l / 3] = buffer[l] * 0xffff;
            }
        }
        return buf.buffer;
    }

    downsampleBuffer(buffer, sampleRate, outSampleRate) {
        if (outSampleRate == sampleRate) {
            return buffer;
        }
        if (outSampleRate > sampleRate) {
            throw "downsampling rate show be smaller than original sample rate";
        }
        var sampleRateRatio = sampleRate / outSampleRate;
        var newLength = Math.round(buffer.length / sampleRateRatio);
        var result = new Int16Array(newLength);
        var offsetResult = 0;
        var offsetBuffer = 0;
        while (offsetResult < result.length) {
            var nextOffsetBuffer = Math.round(
                (offsetResult + 1) * sampleRateRatio
            );
            var accum = 0,
                count = 0;
            for (
                var i = offsetBuffer;
                i < nextOffsetBuffer && i < buffer.length;
                i++
            ) {
                accum += buffer[i];
                count++;
            }

            result[offsetResult] = Math.min(1, accum / count) * 0x7fff;
            offsetResult++;
            offsetBuffer = nextOffsetBuffer;
        }
        return result.buffer;
    }

    updateCorpus(text: string) {
        this.socket.emit("addUserRecord", {
            user: this.authService.getUserId(),
            record: text,
        });
    }
}

export interface Transcription {
    text: string;
    isFinal: boolean;
}
