import { Injectable } from "@angular/core";

import { AngularFireAuth } from "@angular/fire/auth";
import * as firebase from "firebase/app";
import { Observable } from "rxjs";
import { Router } from "@angular/router";
import { PreferencesService } from "./preferences.service";

@Injectable({
    providedIn: "root",
})
export class AuthService {
    private userDetails: firebase.User = null;

    constructor(
        private router: Router,
        private afAuth: AngularFireAuth,
    ) {
        this.afAuth.authState.subscribe((user) => {
            if (user) {
                this.userDetails = user;
            } else {
                this.userDetails = null;
            }
        });
    }

    doGoogleLogin() {
        let provider = new firebase.auth.GoogleAuthProvider();
        provider.addScope("profile");
        provider.addScope("email");
        return this.afAuth.auth.signInWithPopup(provider);
    }

    doFacebookLogin() {
        let provider = new firebase.auth.FacebookAuthProvider();
        provider.setCustomParameters({
            display: "popup",
        });
        return this.afAuth.auth.signInWithPopup(provider);
    }

    doEmailPasswordLogin(email: string, password: string) {
        return this.afAuth.auth.signInWithEmailAndPassword(email, password);
    }

    async doRegisterEmailPassword(
        email: string,
        password: string,
        name: string,
    ) {
        let credential = await this.afAuth.auth.createUserWithEmailAndPassword(
            email,
            password
        );
        await credential.user.updateProfile({ displayName: name });
        return credential;
    }

    doLogout() {
        return this.afAuth.auth
            .signOut()
            .then((res) => this.router.navigateByUrl("/"));
    }

    isLoggedIn() {
        return this.userDetails !== null;
    }

    getUserToken(): Promise<string> {
        return this.afAuth.auth.currentUser.getIdToken();
    }

    getUserId(): string {
        return this.userDetails.uid;
    }

    getUserName(): string {
        return this.userDetails.displayName;
    }
}
